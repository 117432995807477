import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import TableWithCheckbox from '@groovehq/internal-design-system/lib/components/TableWithCheckbox/TableWithCheckbox'
import { selectMailboxesIncludingInaccessible } from 'ducks/mailboxes/selectors/selectMailboxesIncludingInaccessible'
import { filter } from 'util/objects'
import {
  RESTRICTION_TYPE_EVERYONE,
  RESTRICTION_TYPE_GROUP,
} from 'ducks/teams/constants'
import useColumns from './useColumns'

const MailboxSelectionTable = ({ onSelectedRowIdsChange }) => {
  const columns = useColumns()

  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)

  const mailboxRowData = useMemo(
    () => {
      return mailboxes
        .map(mailbox => {
          return {
            ...mailbox,
            isDisabled: [
              RESTRICTION_TYPE_EVERYONE,
              RESTRICTION_TYPE_GROUP,
            ].includes(mailbox.restriction_type),
          }
        })
        .sort((a, b) => a.isDisabled - b.isDisabled)
    },
    [mailboxes]
  )

  const defaultSelectedRowIds = useMemo(
    () => {
      return mailboxRowData.reduce((obj, mailbox) => {
        // eslint-disable-next-line no-param-reassign
        obj[mailbox.id] = mailbox.restriction_type === RESTRICTION_TYPE_EVERYONE

        return obj
      }, {})
    },
    [mailboxRowData]
  )

  useEffect(
    () => {
      onSelectedRowIdsChange(filter(v => !!v, defaultSelectedRowIds))
    },
    [defaultSelectedRowIds, onSelectedRowIdsChange]
  )

  return (
    <TableWithCheckbox
      columns={columns}
      onSelectedRowIdsChange={onSelectedRowIdsChange}
      data={mailboxRowData}
      defaultSelectedRowIds={defaultSelectedRowIds}
    />
  )
}

export default MailboxSelectionTable
