import { useMemo } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { styles as tableStyles } from '@groovehq/internal-design-system/lib/components/Table/Table.styles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import {
  RESTRICTION_TYPE_EVERYONE,
  RESTRICTION_TYPE_GROUP,
} from 'ducks/teams/constants'

const useColumns = () => {
  const columns = useMemo(() => {
    const formatMailboxName = ({ row: { original } }) => {
      const {
        id,
        name,
        email,
        isDisabled,
        restriction_type: restrictionType,
        group_ids: groupIds,
      } = original

      let title
      if (isDisabled) {
        if (restrictionType === RESTRICTION_TYPE_EVERYONE) {
          title = `Everyone has access to this ${app.t('mailbox')} by default`
        } else if (restrictionType === RESTRICTION_TYPE_GROUP) {
          const appendText =
            groupIds.length > 1
              ? `specific ${app.t('teams')}`
              : `a specific ${app.t('team')}`
          title = `This inbox is limited to ${appendText}`
        }
      }

      return (
        <Tooltip title={title}>
          <label htmlFor={id} css={isDisabled && tableStyles.disabledCell}>
            <span css={text.styles.fontMedium}>{name}</span>
            {email && <span>&nbsp;({email})</span>}
          </label>
        </Tooltip>
      )
    }

    return [
      {
        Header: app.t('Mailboxes'),
        accessor: 'name',
        disableSortBy: true,
        Cell: formatMailboxName,
      },
    ]
  }, [])

  return columns
}

export default useColumns
