import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentPaidActiveAgentsWithCurrentUserSort } from 'ducks/agents/selectors'
import { reverseHashInt } from 'util/scatterSwap'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import CornerDownRight from '@groovehq/internal-design-system/lib/assets/icons/CornerDownRight'

import DropdownMenu from '../../DropdownMenu'
import { styles as conditionStyles } from '../../Condition/Condition.styles'
import { styles } from './styles'

const ASSIGNED_AGENT_VALUE = '0'

export default function EmailReply({
  onDropdownSelect,
  actionType,
  valueKey: rawValueKey,
  className,
}) {
  const agents = useSelector(selectCurrentPaidActiveAgentsWithCurrentUserSort)

  const data = useMemo(
    () => [
      { name: 'Assigned agent', value: ASSIGNED_AGENT_VALUE },
      ...agents.map(agent => ({
        name: agent.name,
        value: reverseHashInt(agent.id).toString(),
      })),
    ],
    [agents]
  )

  const fallbackAgentsData = useMemo(
    () => [
      ...agents.map(agent => ({
        name: agent.name,
        value: `0,${reverseHashInt(agent.id).toString()}`,
      })),
    ],
    [agents]
  )

  const [valueKey, fallbackAgentValueKey] = useMemo(
    () => {
      if (!rawValueKey) {
        return [ASSIGNED_AGENT_VALUE, undefined]
      }

      return rawValueKey?.split(',')
    },
    [rawValueKey]
  )

  const selectedValue = useMemo(
    () => {
      const foundItem = data.find(item => item.value === valueKey)
      return foundItem?.name
    },
    [data, valueKey]
  )

  const selectedFallbackAgentValue = useMemo(
    () => {
      const foundItem = fallbackAgentsData.find(
        item => item.value === rawValueKey
      )
      return foundItem?.name
    },
    [fallbackAgentsData, rawValueKey]
  )

  const selectHeader = useMemo(
    () => {
      switch (actionType) {
        case 'FORWARD_CONVERSATION':
          return 'Forwarded email will come from'
        case 'ADD_NOTE':
          return 'Notes will come from'
        case 'SEND_EMAIL_REPLY':
          return 'Replies will come from'
        default:
          return ''
      }
    },
    [actionType]
  )

  useEffect(
    () => {
      if (
        valueKey === ASSIGNED_AGENT_VALUE &&
        fallbackAgentValueKey === undefined
      ) {
        const defaultAgent = agents[0]

        if (!defaultAgent) {
          return
        }

        onDropdownSelect(`${valueKey},${reverseHashInt(defaultAgent.id)}`)
      }
    },
    [valueKey, fallbackAgentValueKey, agents, onDropdownSelect]
  )

  return (
    <div
      css={[conditionStyles.dropdownContainer, styles.dropdownContainer]}
      className={className}
    >
      <div css={styles.row}>
        <span css={styles.text}>{selectHeader}</span>
        <Dropdown
          overlay={<DropdownMenu data={data} />}
          onSelect={onDropdownSelect}
          selectedKey={valueKey}
        >
          <Dropdown.Button size="small" css={conditionStyles.dropdownBtn}>
            {selectedValue || <>&nbsp;</>}
          </Dropdown.Button>
        </Dropdown>
      </div>

      {valueKey === ASSIGNED_AGENT_VALUE && (
        <div css={styles.row}>
          <span css={[styles.text, styles.textWithPadding]}>
            <CornerDownRight />
            <span css={styles.textAfterIcon}>If no assigned user, then</span>
          </span>
          <Dropdown
            overlay={<DropdownMenu data={fallbackAgentsData} />}
            onSelect={onDropdownSelect}
            selectedKey={fallbackAgentValueKey}
          >
            <Dropdown.Button size="small" css={conditionStyles.dropdownBtn}>
              {selectedFallbackAgentValue || <>&nbsp;</>}
            </Dropdown.Button>
          </Dropdown>
        </div>
      )}
    </div>
  )
}
