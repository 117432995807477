import React from 'react'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import Avatar from '@groovehq/internal-design-system/lib/components/Avatar/Avatar'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useSelector } from 'react-redux'
import { selectPendingEntityById } from 'ducks/entities/selectors'
import { styles } from './styles'

const ContactCard = ({ contactId }) => {
  const contact = useSelector(state =>
    selectPendingEntityById(state, 'contacts', contactId)
  )

  if (!contact) return null

  return (
    <MessageCard type="white" isIconHidden css={styles.messageCard}>
      <div
        className="grui grid justify-between items-center w-100"
        css={styles.container}
      >
        <div className="grui flex flex-col w-100">
          <span
            className="grui truncate"
            css={text.styles.fontMedium}
            title={contact.name}
          >
            {!!contact.name && <span>{contact.name}</span>}
            {!contact.name && (
              <span css={[text.styles.textMediumDark, text.styles.italic]}>
                Unknown name
              </span>
            )}
          </span>
          <span className="grui truncate" title={contact.email}>
            {!!contact.email && <span>{contact.email}</span>}
            {!contact.email && (
              <span css={[text.styles.textMediumDark, text.styles.italic]}>
                Unknown email
              </span>
            )}
          </span>
        </div>
        <div className="grui flex flex-col justify-between whitespace-nowrap ml-auto text-right">
          <Avatar
            avatar={contact.avatarUrl}
            userName={contact.name}
            size="xl"
          />
        </div>
      </div>
    </MessageCard>
  )
}

export default ContactCard
