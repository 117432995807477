import { connect } from 'react-redux'

import { selectIsTicketSelectionMode } from 'selectors/ticket_list'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { doToggleTicketSelection } from 'actions/app'

import { selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled } from 'selectors/app/selectAccountPreferences'

import { sortByKey } from 'util/arrays'
import ListItemView from 'components/ConversationList/ListItem'
import { snoozeLabel } from 'util/snooze'
import { selectDraftBodyByTicketId } from 'ducks/drafts2/selectors'
import { unsafeStripTags } from 'util/strings'
import { selectConversationAgentCollisions } from 'ducks/collisions/selectors'
import { selectCurrentUserPrefersPreviewOnHover } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPrefersPreviewOnHover'
import {
  lastUnansweredUserMessageDurationLevel,
  lastUnansweredUserMessageLabel,
} from 'util/lastUnansweredUserMessage'
import {
  selectCurrentUserEmail,
  selectCurrentUserIsAdminOrOwnerOrAgent,
} from 'ducks/currentUser/selectors'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectContactById } from 'ducks/crm/contacts/selectors/base'
import { UNREAD } from 'ducks/tickets/constants'
import { timeInHuman } from 'util/date'
import { selectCurrentTagById } from 'ducks/tags/selectors'
import { selectCurrentChannelById } from 'ducks/channels/selectors'
import { isCloseable, isSnoozed } from 'ducks/tickets/utils/state'
import { selectCurrentAgentById } from 'ducks/agents/selectors'
import { selectCurrentTeamById } from 'ducks/teams/selectors'
import { doClose } from 'ducks/tickets/actions/doClose'
import { doOpen } from 'ducks/tickets/actions/doOpen'
import { doUnread } from 'ducks/tickets/actions/doUnread'
import { doMarkAsRead } from 'ducks/tickets/actions/doMarkAsRead'
import { emptyObj } from 'util/objects'
import { selectLinkUrlForTicketId } from 'ducks/tickets/selectors/selectLinkUrlForTicketId'
import { doOpenTicketPage } from 'actions/pages'
import { doPreloadTickets } from 'ducks/tickets/actions/doPreloadTickets'
import { selectIsMailboxIndicatorNeeded } from 'selectors/currentChangesets/selectIsMailboxIndicatorNeeded'

const select = (initialState, initialOwnProps) => {
  const ticketId = initialOwnProps.conversationId
  const {
    location: { prev: { payload: { id: prevTicketId } = {} } = {} } = {},
  } = initialState

  return state => {
    const collision = selectConversationAgentCollisions(state, ticketId)
    const ticket = selectCurrentConversationById(state, ticketId)
    const isAdminOrOwnerOrAgent = selectCurrentUserIsAdminOrOwnerOrAgent(state)
    if (!ticket) {
      return {
        present: false,
      }
    }

    const { updatedAt, lastUnansweredUserMessageAt, summaryMessage } = ticket
    const { isNote, bodyPlainText, author } = summaryMessage || emptyObj

    const contact = selectContactById(state, ticket.contact)
    const currentUserEmail = selectCurrentUserEmail(state)
    const isCurrentUser = contact?.email && contact.email === currentUserEmail

    const conversationPreviewOnHoverEnabled = selectCurrentUserPrefersPreviewOnHover(
      state
    )
    const snippet = {
      type: isNote ? 'internal' : 'enduser',
      text: bodyPlainText,
    }
    let draftType = null
    let doesHaveDraft = false

    const noteBody = selectDraftBodyByTicketId(state, ticketId, 'note')
    if (noteBody) {
      snippet.type = 'draft'
      draftType = 'note'
      snippet.text = unsafeStripTags(noteBody).substr(0, 50)
      doesHaveDraft = true
    }

    const draftBody = selectDraftBodyByTicketId(state, ticketId, 'reply')
    if (draftBody) {
      snippet.type = 'draft'
      draftType = 'reply'
      snippet.text = unsafeStripTags(draftBody).substr(0, 50)
      doesHaveDraft = true
    }

    if (snippet && snippet.text) {
      snippet.text = snippet.text.replace(/&nbsp;/g, ' ')
    }

    let ticketAssignmentLabel = null
    if (ticket.assigned) {
      if (ticket.assigned.agent) {
        const assignedAgent = selectCurrentAgentById(
          state,
          ticket.assigned.agent
        )
        if (assignedAgent) {
          ticketAssignmentLabel =
            assignedAgent.email === currentUserEmail
              ? 'You'
              : assignedAgent.name
        }
      } else if (ticket.assigned.team) {
        const assignedTeam = selectCurrentTeamById(state, ticket.assigned.team)
        if (assignedTeam) {
          ticketAssignmentLabel = assignedTeam.name
        }
      }
    }

    let snippetAuthor = null
    const isLastMessageLeftByAgent = !!(isNote || author?.id?.startsWith('ag_'))
    if (isLastMessageLeftByAgent) {
      const agent = selectCurrentAgentById(state, author.id)
      if (agent) {
        snippetAuthor = agent.email === currentUserEmail ? 'You' : agent.name
      }
    }
    const channel = selectCurrentChannelById(state, ticket.channel)
    const mailboxIndicatorNeeded = selectIsMailboxIndicatorNeeded(state)
    const mailboxColor = mailboxIndicatorNeeded ? channel?.color : null
    const labels = ticket.tags.map(tgid => selectCurrentTagById(state, tgid))
    const isSelectionMode = selectIsTicketSelectionMode(state)
    const currentTicketId = selectCurrentTicketId(state)
    const linkUrl = selectLinkUrlForTicketId(state, ticketId)

    const timestamp = timeInHuman(updatedAt)

    const sortByLastUnansweredUserMessage = selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled(
      state
    )

    const unansweredSinceLabel = sortByLastUnansweredUserMessage
      ? lastUnansweredUserMessageLabel(lastUnansweredUserMessageAt)
      : null

    const unansweredSinceDurationLevel = sortByLastUnansweredUserMessage
      ? lastUnansweredUserMessageDurationLevel(lastUnansweredUserMessageAt)
      : null

    return {
      ...collision,
      present: true,
      customerLabelFull: isCurrentUser ? 'You' : contact?.name || null,
      hasDraft: doesHaveDraft,
      isUnread: ticket.state === UNREAD,
      assignmentLabel: ticketAssignmentLabel,
      timestamp,
      messageCount: ticket.counts?.messages || 0,
      hasAttachments: (ticket.counts?.attachments || 0) > 0,
      isStarred: ticket.starred,
      subject: ticket.subject,
      subjectEmptyPlaceholderText: 'There is no subject for this message',
      snippetType: doesHaveDraft ? 'draft' : snippet && snippet.type,
      snippetAuthor,
      snippetText: snippet && snippet.text,
      draftType,
      mailboxColor,
      labelIds: ticket.tags,
      isCloseable: isCloseable(ticket.state),
      shouldShowLabels: ticket.tags && ticket.tags.length > 0,
      labelsJSON:
        labels.length > 0 ? JSON.stringify(sortByKey(labels, 'name')) : null,
      active: !isSelectionMode && ticketId === currentTicketId,
      linkUrl,
      isLastViewed: prevTicketId && ticketId === prevTicketId,
      isSnoozed: isSnoozed(ticket),
      snoozedUntilLabel: snoozeLabel(ticket.snoozed?.until),
      conversationPreviewOnHoverEnabled,
      unansweredSinceLabel,
      unansweredSinceDurationLevel,
      canBulkSelect: isAdminOrOwnerOrAgent,
      canPerformQuickActions: isAdminOrOwnerOrAgent,
    }
  }
}

const perform = dispatch => {
  return {
    closeConversation: id =>
      dispatch(
        doClose(id, {
          moduleOptions: {
            autoRedirect: {
              enabled: false,
            },
          },
        })
      ),
    openConversation: id =>
      dispatch(
        doOpen(id, {
          moduleOptions: {
            autoRedirect: {
              enabled: false,
            },
          },
        })
      ),
    openConversationPage: id => dispatch(doOpenTicketPage(id)),
    markAsRead: id => dispatch(doMarkAsRead(id)),
    markAsUnread: id => dispatch(doUnread(id)),
    onClick: action => dispatch(action),
    navigateToConversation: id => dispatch(doOpenTicketPage(id)),
    toggleConversationSelection: id => dispatch(doToggleTicketSelection(id)),
    unsoozeConversation: id => dispatch(doUnread(id)),
    onMount: ({ conversationId, present }) => {
      if (!present) {
        dispatch(
          doPreloadTickets({
            ticketIds: [conversationId],
            options: {
              forced: true,
              // Its important to also update the searches when preloading the tickets as there
              // are edge cases  where the realtime will say a ticket needs to be part of a folder
              // but then when the content comes back it turns out the ticket should not be part
              // of the folder. The update searches will then remove it.
              updateSearches: true,
            },
          })
        )
      }
    },
  }
}

export default connect(select, perform)(ListItemView)
