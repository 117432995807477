import React, { useMemo, useCallback } from 'react'
import { string, shape, oneOf, func } from 'prop-types'
import { useSelector } from 'react-redux'

import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import CheckboxGroup from '@groovehq/internal-design-system/lib/components/Checkbox/CheckboxGroup'

import { selectAgentsSortedByName } from 'selectors/agents/base'
import { selectGroupsSortedByName } from 'selectors/app/groups'

import { styles as MailboxSelectionStyles } from '../MailboxSelection/MailboxSelection.styles'
import { userRanges } from './UserSelection.data'
import { USERS_RANGES } from '../UserRestrictionTypeDropdown'

const { Menu, Button } = Dropdown

const menu = data => (
  <Menu>
    {data.map(({ title, key }) => (
      <Menu.Item key={key} itemKey={key}>
        {title}
      </Menu.Item>
    ))}
  </Menu>
)

const selectTitle = (all, key) => {
  const selected = all.find(item => item.key === key) || {
    title: '',
  }
  return selected.title
}

const UserSelection = ({
  className,
  checkboxListStyles,
  agentIds,
  groupIds,
  type,
  onUserListChange,
  onGroupListChange,
  onUserAccessibilityTypeChange,
}) => {
  const agents = useSelector(selectAgentsSortedByName)
  const groups = useSelector(selectGroupsSortedByName)

  const selectedTitle = useMemo(() => selectTitle(userRanges, type), [type])

  // Handle agents selection
  const agentCheckboxList = useMemo(
    () => {
      return agents.map(agent => {
        return {
          id: agent.id,
          label: agent.name,
          value: agent.name,
          checked: agentIds.indexOf(agent.id) > -1,
        }
      })
    },
    [agents, agentIds]
  )

  const handleSelectedAgentsChange = useCallback(
    selectedAgents => {
      onUserListChange(selectedAgents.map(a => a.id))
    },
    [onUserListChange]
  )

  // Handle groups selection
  const groupCheckboxList = useMemo(
    () => {
      return groups.map(item => {
        return {
          id: item.id,
          label: item.name,
          value: item.name,
          checked: groupIds.indexOf(item.id) > -1,
        }
      })
    },
    [groups, groupIds]
  )

  const handleSelectedGroupsChange = useCallback(
    selectedGroups => {
      onGroupListChange(selectedGroups.map(g => g.id))
    },
    [onGroupListChange]
  )

  const handleOnAccessibilityTypeChange = useCallback(
    accessibilityType => {
      if (
        [USERS_RANGES.EVERYONE, USERS_RANGES.ME].includes(accessibilityType)
      ) {
        // reset all agent and teams checkboxes to be empty (default),
        // so the form won't be dirty if switch back to the default type
        onGroupListChange([])
        onUserListChange([])
      } else if (accessibilityType === USERS_RANGES.AGENTS) {
        // reset team checkboxes to be checked
        onGroupListChange(groups.map(g => g.id))
      } else if (accessibilityType === USERS_RANGES.TEAMS) {
        // reset agent checkboxes to checked
        onUserListChange(agents.map(a => a.id))
      }

      onUserAccessibilityTypeChange(accessibilityType)
    },
    [
      onGroupListChange,
      onUserListChange,
      onUserAccessibilityTypeChange,
      agents,
      groups,
    ]
  )

  return (
    <div className={className}>
      <div css={MailboxSelectionStyles.dropdownContainer}>
        <Dropdown
          overlay={menu(userRanges)}
          onSelect={handleOnAccessibilityTypeChange}
          selectedKey={type}
        >
          <Button css={MailboxSelectionStyles.dropdownBtn} size="small">
            {selectedTitle}
          </Button>
        </Dropdown>
      </div>
      {type === USERS_RANGES.AGENTS && (
        <div css={checkboxListStyles}>
          <CheckboxGroup
            options={agentCheckboxList}
            onChange={handleSelectedAgentsChange}
          />
        </div>
      )}
      {type === USERS_RANGES.TEAMS && (
        <div css={checkboxListStyles}>
          <CheckboxGroup
            options={groupCheckboxList}
            onChange={handleSelectedGroupsChange}
          />
        </div>
      )}
    </div>
  )
}

UserSelection.propTypes = {
  className: string,
  checkboxListStyles: shape({
    name: string,
    styles: string,
  }),
  type: oneOf(Object.values(USERS_RANGES)),
  onUserListChange: func,
  onUserAccessibilityTypeChange: func,
}

UserSelection.defaultProps = {
  className: undefined,
  checkboxListStyles: undefined,
  type: userRanges[1].key,
  onUserListChange() {},
  onUserAccessibilityTypeChange() {},
}

export default UserSelection
