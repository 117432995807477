import { useEffect } from 'react'

const useCalendlyWidget = ({ onScheduleSuccess }) => {
  useEffect(() => {
    const existing = document.getElementById('calendly-script')
    if (existing) {
      return
    }
    const styleTag = document.createElement('link')
    styleTag.href = 'https://calendly.com/assets/external/widget.css'
    styleTag.rel = 'stylesheet'
    document.head.appendChild(styleTag)
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://assets.calendly.com/assets/external/widget.js'
    scriptTag.id = 'calendly-script'
    scriptTag.async = 'async'
    document.body.appendChild(scriptTag)
  }, [])

  useEffect(
    () => {
      const handleMessage = e => {
        // https://help.calendly.com/hc/en-us/articles/223147027?tab=advanced#6
        // Handle events during booking flow
        function isCalendlyEvent() {
          return (
            e.origin === 'https://calendly.com' &&
            e.data?.event &&
            e.data?.event?.indexOf('calendly.') === 0
          )
        }
        if (isCalendlyEvent()) {
          // Invitee successfully booked a meeting
          if (
            e.data?.event === 'calendly.event_scheduled' &&
            onScheduleSuccess
          ) {
            onScheduleSuccess(e)
          }
        }
      }
      window.addEventListener('message', handleMessage)
      return () => {
        window.removeEventListener('message', handleMessage)
      }
    },
    [onScheduleSuccess]
  )
}

export default useCalendlyWidget
