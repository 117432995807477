import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { isEmpty } from 'util/objects'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const useOpenContact = ({ left } = {}) => {
  const currentUser = useSelector(selectCurrentUser)
  // isFeatureEnabled won't trigger re-render, so we use useFeatureFlagEnabled
  const isHelplyAgentReady = useFeatureFlagEnabled('helply-agent-ready')

  const openContact = useCallback(
    async e => {
      if (isHelplyAgentReady && window.helplyAgentWidget) {
        if (!isEmpty(currentUser)) {
          window.helplyAgentWidget.setContact({
            email: currentUser.email,
            firstName: currentUser.first_name,
            lastName: currentUser.last_name,
          })
        }
        window.helplyAgentWidget.open()
        if (e?.preventDefault) {
          e.preventDefault()
        }

        return
      }
      if (!window.groove?.widget) return
      if (e?.preventDefault) {
        e.preventDefault()
      }

      if (left) {
        await window.groove.widget.updateSettings({
          iconPosition: 'left',
        })
      }
      window.groove.widget.pushRoute('/contact')
      window.groove.widget.open()
      if (!isEmpty(currentUser)) {
        window.groove.widget.identifyContact('contact_email', currentUser.email)
        window.groove.widget.setContact({
          contact_first_name: currentUser.first_name,
          contact_last_name: currentUser.last_name,
        })
      }
    },
    [currentUser, left, isHelplyAgentReady]
  )

  return openContact
}

export default useOpenContact
