import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import MenuWithSearch from '../MenuWithSearch'
import { TAG_DROPDOWN_VARIANTS } from './constants'

const Content = ({ loading, isEmpty, onCreate, children, variant }) => {
  if (loading) return <MenuWithSearch.Loader />
  if (isEmpty)
    return (
      <MenuWithSearch.Empty>
        No tags found
        {variant === TAG_DROPDOWN_VARIANTS.INBOX && (
          <>
            <br />
            <Button
              size="small"
              type="info"
              css={text.styles.fontMedium}
              className="grui mt-5"
              onClick={onCreate}
            >
              Create a tag
            </Button>
          </>
        )}
      </MenuWithSearch.Empty>
    )
  return children
}

export default Content
