import React from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { TAG_DROPDOWN_VARIANTS } from '../TagsDropdown/constants'

const Footer = ({
  saveBtnTitle,
  onSelect,
  onCancel,
  onCreate,
  onManage,
  variant = TAG_DROPDOWN_VARIANTS.DEFAULT,
  hasChanged,
}) => {
  return (
    <div className="footer">
      {variant === TAG_DROPDOWN_VARIANTS.DEFAULT && (
        <>
          <Button
            size="small"
            type="info"
            css={text.styles.fontMedium}
            className="grui mr-5"
            onClick={onSelect}
          >
            {saveBtnTitle}
          </Button>
          <Button
            type="tertiary"
            size="small"
            css={text.styles.fontMedium}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </>
      )}
      {variant === TAG_DROPDOWN_VARIANTS.INBOX && (
        <>
          {hasChanged && (
            <>
              <Button
                type="tertiary"
                size="small"
                css={text.styles.fontMedium}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                size="small"
                type="info"
                css={text.styles.fontMedium}
                className="grui ml-5"
                onClick={onSelect}
              >
                Apply
              </Button>
            </>
          )}
          {!hasChanged && (
            <>
              <Button
                type="tertiary"
                size="small"
                css={text.styles.fontMedium}
                onClick={onManage}
              >
                Manage tags
              </Button>
              <Button
                size="small"
                type="info"
                css={text.styles.fontMedium}
                className="grui ml-5"
                onClick={onCreate}
              >
                Create a tag
              </Button>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Footer
