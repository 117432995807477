import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const label = css`
  label {
    display: flex;
    align-items: center;
  }
`

const dropdownSearchStyle = css`
  width: 100%;
`

const dropdownSearchMenu = css`
  .searchBox {
    position: absolute;
    top: 0;
    left: 0;
    right: ${rem(12)};
    padding: ${rem(8)} ${rem(20)} 0 ${rem(8)};
    background: #fff;
    padding-bottom: ${rem(8)};
  }

  .searchMenu {
    max-height: 230px;
    overflow: auto;
    padding-top: 45px;
  }
`

const fieldArrayDeleteBtn = css`
  && {
    min-width: ${rem(40)};
  }
`

const fieldEnablePointerEvent = css`
  & .form-control {
    pointer-events: auto;
  }
`

export const styles = {
  dropdownSearchStyle,
  dropdownSearchMenu,
  label,
  fieldArrayDeleteBtn,
  fieldEnablePointerEvent,
}
