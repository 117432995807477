export const SETTINGS_PAGE = 'SETTINGS_PAGE'
export const SETTINGS_BILLING_PAGE = 'SETTINGS_BILLING_PAGE'
export const SETTINGS_CRM_PAGE = 'SETTINGS_CRM_PAGE'
export const SETTINGS_CUSTOM_FIELDS_PAGE = 'SETTINGS_CUSTOM_FIELDS_PAGE'
export const SETTINGS_INTEGRATIONS_PAGE = 'SETTINGS_INTEGRATIONS_PAGE'
export const SETTINGS_INTEGRATIONS_PROVIDER_PAGE =
  'SETTINGS_INTEGRATIONS_PROVIDER_PAGE'
export const SETTINGS_INTEGRATIONS_ADD_NEW_PAGE =
  'SETTINGS_INTEGRATIONS_ADD_NEW_PAGE'
export const SETTINGS_INTEGRATIONS_EDIT_PAGE = 'SETTINGS_INTEGRATIONS_EDIT_PAGE'
export const SETTINGS_REPORTING_PAGE = 'SETTINGS_REPORTING_PAGE'
export const SETTINGS_BUSINESS_HOURS_PAGE = 'SETTINGS_BUSINESS_HOURS_PAGE'
export const SETTINGS_WIDGETS_PAGE = 'SETTINGS_WIDGETS_PAGE'
export const SETTINGS_WIDGETS_CREATE_PAGE = 'SETTINGS_WIDGETS_CREATE_PAGE'
export const SETTINGS_WIDGET_MANAGEMENT_PAGE = 'SETTINGS_WIDGET_MANAGEMENT_PAGE'
export const SETTINGS_CHAT_MANAGEMENT_PAGE = 'SETTINGS_CHAT_MANAGEMENT_PAGE'
export const SETTINGS_WIDGET_CHAT_PAGE = 'SETTINGS_WIDGET_CHAT_PAGE'
export const SETTINGS_CHAT_BOT_SKILL_PAGE = 'SETTINGS_CHAT_BOT_SKILL_PAGE'
export const SETTINGS_WIDGET_CREATE_NEW_PAGE = 'SETTINGS_WIDGET_CREATE_NEW_PAGE'
export const SETTINGS_WIDGET_CREATE_CUSTOMIZE_PAGE =
  'SETTINGS_WIDGET_CREATE_CUSTOMIZE_PAGE'
export const SETTINGS_WIDGET_CREATE_INSTALL_PAGE =
  'SETTINGS_WIDGET_CREATE_INSTALL_PAGE'

export const SETTINGS_CANNEDREPLIES_PAGE = 'settings/CANNEDREPLIES_PAGE'
export const SETTINGS_FOLDERS_PAGE = 'settings/FOLDERS_PAGE'
export const SETTINGS_RULES_PAGE = 'settings/RULES_PAGE'
export const SETTINGS_TAGS_PAGE = 'settings/TAGS_PAGE'
export const SETTINGS_TEAMMATES_PAGE = 'settings/TEAMMATES_PAGE'
export const SETTINGS_TEAMS_PAGE = 'settings/TEAMS_PAGE'
export const SETTINGS_INBOXES_PAGE = 'settings/INBOXES_PAGE'
export const SETTINGS_CHANNELS_PAGE = 'settings/CHANNELS_PAGE'

export const SETTINGS_INBOX_MANAGEMENT_PAGE =
  'settings/SETTINGS_INBOX_MANAGEMENT_PAGE'

export const SETTINGS_DEVELOPER_PAGE = 'settings/DEVELOPER_PAGE'
export const SETTINGS_IMPORT_PAGE = 'settings/IMPORT_PAGE'
export const SETTINGS_EXPORTS_PAGE = 'settings/EXPORTS_PAGE'
export const SETTINGS_COMPANY_PREFERENCES_PAGE =
  'settings/COMPANY_PREFERENCES_PAGE'
export const SETTINGS_BLACKLIST_PAGE = 'settings/BLACKLIST_PAGE'
export const SETTINGS_PROFILE_PAGE = 'settings/PROFILE_PAGE'
export const SETTINGS_AUTHENTICATION_PAGE = 'settings/AUTHENTICATION_PAGE'
export const SETTINGS_PREFERENCES_PAGE = 'settings/PREFERENCES_PAGE'
export const SETTINGS_WORKFLOWS_PAGE = 'settings/WORKFLOWS_PAGE'
export const SETTINGS_NOTIFICATIONS_PAGE = 'settings/NOTIFICATIONS_PAGE'
export const SETTINGS_CUSTOM_PROFILE_PAGE = 'settings/CUSTOM_PROFILE_PAGE'
export const SETTINGS_SATISFACTION_RATINGS_PAGE =
  'settings/SATISFACTION_RATINGS_PAGE'
export const SETTINGS_REDIRECT_INTEGRATION_PAGE =
  'settings/REDIRECT_INTEGRATION_PAGE'
