import React, { useMemo } from 'react'
import cn from 'classnames'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { propFunc } from 'util/functions'
import moment from 'moment'
import { styles } from './styles'
import { USERS_RANGES } from '../UserRestrictionTypeDropdown'

const { CellWithButton, RowControls } = Table

export const FOLDER_VISIBILITY = {
  SHOWN: 'ACTIVE',
  HIDDEN: 'INACTIVE',
}

export const CHANNEL_VISIBILITY_SETTINGS = {
  all: {
    text: `All ${app.t('mailboxes')}`,
    style: 'textMediumDark',
  },
  selected: {
    text: `Selected ${app.t('mailboxes')}`,
    style: 'textMediumDark',
  },
}

export const ACCESS_SETTINGS = {
  [USERS_RANGES.ME]: {
    text: 'Only Me',
    style: 'textBlack',
  },
  [USERS_RANGES.EVERYONE]: {
    text: `Everyone`,
    style: 'textBlack',
  },
  [USERS_RANGES.TEAMS]: {
    text: `Selected ${app.t('teams')}`,
    style: 'textMediumDark',
  },
  [USERS_RANGES.AGENTS]: {
    text: `Selected ${app.t('agents')}`,
    style: 'textMediumDark',
  },
}

const useColumns = ({ compact, disableReorder }) => {
  const columns = useMemo(
    () => {
      const formatName = ({
        row: { original },
        value,
        onEdit,
        selectedFlatRows,
      }) => {
        return (
          <CellWithButton
            css={
              original.state === FOLDER_VISIBILITY.HIDDEN &&
              styles.hiddenFolderNameCell
            }
            onClick={propFunc(onEdit, original.id)}
            disabled={selectedFlatRows.length > 0}
          >
            {value}
          </CellWithButton>
        )
      }

      const createRowControls = ({
        value,
        deleteData,
        onEdit,
        row,
        selectedFlatRows,
        sortDisableRef,
        dragHandle,
        page,
      }) => {
        // Default folders can't be deleted
        const { default: isDefault, locked } = row.original
        const disabled = isDefault || locked

        return (
          <RowControls
            name="folders"
            value={value}
            deleteData={deleteData}
            onEdit={onEdit}
            sortable={!disableReorder && page.length > 1}
            disabledDeleteBtn={disabled}
            disabledDeleteBtnHint={`This is a default ${app.t(
              'folder'
            )} and cannot be deleted.`}
            disabled={selectedFlatRows.length > 0}
            disableReorder={sortDisableRef.current}
            dragHandle={dragHandle}
          />
        )
      }

      const formatDate = ({
        row: { original },
        value,
        deleteData,
        onEdit,
        selectedFlatRows,
        dragHandle,
        sortDisableRef,
      }) => {
        const { default: isDefault, locked } = original
        const disabled = isDefault || locked
        const controlsDisabled = selectedFlatRows.length > 0
        let component = null
        if (!value) {
          component = (
            <div css={[text.styles.textMediumDark, text.styles.italic]}>
              Never used
            </div>
          )
        } else {
          component = (
            <time dateTime={moment(value).format('MM-DD-YYYY')}>
              {moment(value).format('DD MMM YYYY')}
            </time>
          )
        }

        return (
          <>
            {compact && (
              <RowControls
                className="controls"
                name="folders"
                value={original.id}
                deleteData={deleteData}
                onEdit={onEdit}
                sortable={!disableReorder}
                disabledDeleteBtn={disabled}
                disabledDeleteBtnHint={`This is a default ${app.t(
                  'folder'
                )} and cannot be deleted.`}
                disabled={controlsDisabled}
                disableReorder={sortDisableRef.current}
                dragHandle={dragHandle}
              />
            )}
            <div
              className={cn('components', {
                'controls-disabled': controlsDisabled,
              })}
            >
              {component}
            </div>
          </>
        )
      }

      const showContent = (TYPE, { value }) => {
        return <span css={text.styles[TYPE[value]]}>{TYPE[value].text}</span>
      }

      const showChannelContent = data => {
        return showContent(CHANNEL_VISIBILITY_SETTINGS, data)
      }

      const showAccessContent = data => {
        return showContent(ACCESS_SETTINGS, data)
      }

      return [
        {
          Header: 'NAME',
          accessor: 'name',
          Cell: formatName,
          width: compact ? 290 : 340,
          manualSortBy: true,
          disableSortBy: true,
        },
        {
          Header: app.t('mailbox').toUpperCase(),
          accessor: 'channelVisibility',
          Cell: showChannelContent,
          width: compact ? 130 : 171,
          truncate: true,
          manualSortBy: true,
          disableSortBy: true,
        },
        {
          Header: 'ACCESS',
          accessor: 'viewAccess',
          Cell: showAccessContent,
          width: compact ? 150 : 140,
          truncate: true,
          manualSortBy: true,
          disableSortBy: true,
        },
        {
          Header: 'CREATED',
          accessor: 'createdAt',
          Cell: formatDate,
          width: compact ? 'auto' : '15%',
          manualSortBy: true,
          className: 'row-controls',
          disableSortBy: true,
        },
        !compact && {
          Header: '',
          accessor: 'id',
          Cell: createRowControls,
          disableSortBy: true,
        },
      ].filter(Boolean)
    },
    [compact, disableReorder]
  )

  return columns
}

export default useColumns
