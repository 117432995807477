import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ArrowLeft } from '@groovehq/internal-design-system/lib/assets/icons'
import { selectPendingEntityById } from 'ducks/entities/selectors'
import { styles as profileFormStyles } from 'subapps/crm/components/contact/Profile/styles'
import Selection, { getCfvValue } from './Selection'
import { styles } from './styles'

const FieldSelection = ({ sourceId, targetId, customField, control }) => {
  const { key } = customField || {}
  const sourceContact = useSelector(state =>
    selectPendingEntityById(state, 'contacts', sourceId)
  )
  const targetContact = useSelector(state =>
    selectPendingEntityById(state, 'contacts', targetId)
  )

  const sourceValue = useMemo(
    () => getCfvValue(sourceContact, customField, key),
    [sourceContact, customField, key]
  )

  const targetValue = useMemo(
    () => getCfvValue(targetContact, customField, key),
    [targetContact, customField, key]
  )

  if (!sourceValue && !targetValue) return null

  return (
    <div className="grui mb-12 flex items-end" css={styles.container}>
      <div css={profileFormStyles.label}>
        <Selection
          contact={sourceContact}
          customField={customField}
          control={control}
          keep
        />
      </div>
      <div css={styles.midArrows}>
        <ArrowLeft width="2em" height="2em" />
        <ArrowLeft
          width="2em"
          height="2em"
          className="grui transform-rotate180"
        />
      </div>
      <div className="grui ml-auto" css={profileFormStyles.label}>
        <Selection
          contact={targetContact}
          customField={customField}
          control={control}
          keep={false}
        />
      </div>
    </div>
  )
}

export default FieldSelection
