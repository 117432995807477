import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import ChannelMembersForm from 'subapps/settings/components/shared/forms/ChannelMembers'
import {
  selectChannelCreationStepCount,
  selectPendingChannelById,
} from 'ducks/channels/selectors'
import {
  doSaveChannelDraft,
  doClearChannelDraft,
  doUpdateChannel,
} from 'ducks/channels/actions'
import { releaseHold, retainHold } from 'util/dirtyHolds'
import {
  useChannel,
  useRedirectToChannel,
  useRebuildLeftNavMenu,
} from 'ducks/channels/hooks'
import { selectIsInInbox } from 'selectors/location'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { DRAWER_ID_FOLDERS_CHANNELS_MAIL_IMPORT } from 'ducks/drawers/ids'
import { DRAWER_TYPE_CHANNELS_MAIL_IMPORT } from 'ducks/drawers/types'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { snow } from 'util/ui/confetti'
import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'

import { useAdminAccess } from '../../NoAccess/hooks'
import { styles } from './styles'
import { CHANNEL_TYPES } from '../Channels.data'

const EditMembersModal = ({
  drawerId,
  drawerResourceId: channelId,
  drawerChannelType: channelType = CHANNEL_TYPE.FORWARDING,
  onExit,
}) => {
  const dispatch = useDispatch()
  const { importHistory } = CHANNEL_TYPES[channelType]
  const [isSaving, setIsSaving] = useState(null)
  const [hasError, setHasError] = useState(false)
  const isInInbox = useSelector(selectIsInInbox)
  const { rebuildExitAndRedirect } = useRedirectToChannel({ channelId, onExit })
  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({ channelId, onExit })
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const creationStepCount = useSelector(state =>
    selectChannelCreationStepCount(state, CHANNEL_TYPES[channelType])
  )
  const draftChannel = useSelector(state =>
    selectPendingChannelById(state, channelId)
  )
  const { channel, isLoading } = useChannel(channelId, {
    useCachedIfAvailable: true,
  })

  const {
    drawerId: mailImportDrawerId,
    openDrawer: openChannelsMailImportDrawer,
  } = useDrawer({
    id: DRAWER_ID_FOLDERS_CHANNELS_MAIL_IMPORT,
    type: DRAWER_TYPE_CHANNELS_MAIL_IMPORT,
  })

  const handleSaveAndOpenChannelsMailImportDrawer = useCallback(
    async () => {
      try {
        releaseHold(drawerId)
        setIsSaving(true)
        setHasError(false)
        await dispatch(
          doUpdateChannel(channelId, draftChannel, {
            channelType,
            rebuildMenu: false,
          })
        )
        setIsSaving(false)
        openChannelsMailImportDrawer(channel.id, {
          query: {
            ...buildDrawerQueryParam(
              mailImportDrawerId,
              'drawerChannelType',
              channelType
            ),
          },
        })
      } catch (error) {
        retainHold(drawerId)
        setIsSaving(false)
        setHasError(true)
      }
    },
    [
      dispatch,
      drawerId,
      channelId,
      draftChannel,
      channelType,
      channel,
      openChannelsMailImportDrawer,
      mailImportDrawerId,
    ]
  )

  useAdminAccess(onExit)

  const handleOnExit = useCallback(
    () => {
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [isInInbox, rebuildMenuAndExit, rebuildExitAndRedirect]
  )

  const handleNext = useCallback(
    (_, clearDraft = true, shouldRedirect = false) => {
      releaseHold(drawerId)
      if (shouldRedirect) {
        rebuildExitAndRedirect()

        if (currentMailboxId === channelId) {
          snow({ duration: 1 })
        }
      } else {
        rebuildMenuAndExit()
      }

      if (clearDraft) {
        dispatch(doClearChannelDraft(channelId))
      }
    },
    [
      dispatch,
      drawerId,
      channelId,
      rebuildExitAndRedirect,
      rebuildMenuAndExit,
      currentMailboxId,
    ]
  )

  const handleSaveAndOpenMailbox = useCallback(
    async e => {
      try {
        releaseHold(drawerId)
        setIsSaving(true)
        setHasError(false)
        await dispatch(
          doUpdateChannel(channelId, draftChannel, {
            channelType,
            rebuildMenu: false,
          })
        )
        setIsSaving(false)
        handleNext(e, false, true)
      } catch (error) {
        retainHold(drawerId)
        setIsSaving(false)
        setHasError(true)
      }
    },
    [dispatch, drawerId, channelId, draftChannel, channelType, handleNext]
  )

  const handleChange = useCallback(
    fields => {
      retainHold(drawerId)
      dispatch(doSaveChannelDraft(channelId, fields))
    },
    [dispatch, channelId, drawerId]
  )

  useEffect(
    () => {
      // Save channel data to draft in case the channel draft doesn't exist(reloading the page):
      // doSaveChannelDraft will only save the current fields, buildLeftNav will not work.
      if (!draftChannel && !isLoading && channel) {
        dispatch(doSaveChannelDraft(channelId, channel))
      }
    },
    [draftChannel, isLoading, channel, channelId, dispatch]
  )

  return (
    <Modal
      onClose={handleOnExit}
      dataTestId="channels-members-edit"
      portal="#drawer-root"
      isLoading={isLoading}
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={importHistory ? creationStepCount - 1 : creationStepCount}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>Which {app.t('Agents')} need access?</Modal.Title>
      <Modal.Description className="grui px-4">
        Grant access to specific {app.t('agents')} which should have access this{' '}
        {app.t('mailbox')}.
      </Modal.Description>
      <div css={styles.content} className="grui pt-10 pb-6 text-center">
        <ChannelMembersForm
          channel={channel}
          defaultRestrictionType={draftChannel?.restriction_type}
          defaultUserIds={draftChannel?.user_ids}
          defaultGroupIds={draftChannel?.group_ids}
          onChange={handleChange}
          showError={hasError}
          descriptionHide
          separateTable
          tableWrapperStyles={styles.tableStickyHeader}
        />
        {importHistory && (
          <Modal.Button
            type="tertiary"
            onClick={handleSaveAndOpenChannelsMailImportDrawer}
            className="grui mt-20"
          >
            <>
              {isSaving ? (
                <span>
                  Saving<AnimatedEllipsis />
                </span>
              ) : (
                <span>Import existing emails</span>
              )}
            </>
          </Modal.Button>
        )}
        <Modal.Button
          type="info"
          data-test-id="save-and-continue-button"
          disabled={isSaving}
          onClick={handleSaveAndOpenMailbox}
          className="grui mt-8"
        >
          <>
            {isSaving ? (
              <span>
                Saving<AnimatedEllipsis />
              </span>
            ) : (
              `Take me to my ${app.t('mailbox')}`
            )}
          </>
        </Modal.Button>
      </div>
    </Modal>
  )
}

export default EditMembersModal
