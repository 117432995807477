import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'

import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import ChannelMembersForm from 'subapps/settings/components/shared/forms/ChannelMembers'
import { selectPendingChannelById } from 'ducks/channels/selectors'
import {
  doSaveChannelDraft,
  doClearChannelDraft,
  doUpdateChannel,
} from 'ducks/channels/actions'
import { releaseHold, retainHold } from 'util/dirtyHolds'
import {
  useChannel,
  useRebuildLeftNavMenu,
  useRedirectToChannel,
} from 'ducks/channels/hooks'
import { selectIsInInbox } from 'selectors/location'
import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import CONFIG from './config'

const EditMembers = ({
  drawerId,
  drawerResourceId: channelId,
  drawerChannelType: channelType = CHANNEL_TYPE.FORWARDING,
  onExit,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(null)
  const [isSaving, setIsSaving] = useState(null)
  const [hasError, setHasError] = useState(false)
  const { drawer } = CONFIG[channelType]
  const isInInbox = useSelector(selectIsInInbox)
  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    drawer
  )
  const { rebuildExitAndRedirect } = useRedirectToChannel({ channelId, onExit })
  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({ channelId, onExit })

  const draftChannel = useSelector(state =>
    selectPendingChannelById(state, channelId)
  )
  const { channel } = useChannel(channelId, { useCachedIfAvailable: true })

  const handleOpenNextDrawer = useCallback(
    (_, clearDraft = true, shouldRedirect = false) => {
      releaseHold(drawerId)

      if (drawer) {
        openNextDrawer(channelId, {
          query: {
            ...buildDrawerQueryParam(
              nextDrawerId,
              'drawerChannelType',
              channelType
            ),
          },
        })
      } else if (shouldRedirect) {
        rebuildExitAndRedirect()
      } else {
        rebuildMenuAndExit()
      }

      if (clearDraft) {
        dispatch(doClearChannelDraft(channelId))
      }
    },
    [
      dispatch,
      openNextDrawer,
      drawerId,
      channelId,
      nextDrawerId,
      channelType,
      rebuildExitAndRedirect,
      drawer,
      rebuildMenuAndExit,
    ]
  )

  const handleSaveAndOpenNextDrawer = useCallback(
    async e => {
      try {
        releaseHold(drawerId)
        setIsSaving(true)
        setHasError(false)
        await dispatch(
          doUpdateChannel(channelId, draftChannel, {
            channelType,
            rebuildMenu: false,
          })
        )
        setIsSaving(false)
        handleOpenNextDrawer(e, false, true)
      } catch (error) {
        retainHold(drawerId)
        setIsSaving(false)
        setHasError(true)
      }
    },
    [
      dispatch,
      drawerId,
      channelId,
      draftChannel,
      channelType,
      handleOpenNextDrawer,
    ]
  )

  const handleChange = useCallback(
    fields => {
      retainHold(drawerId)
      dispatch(doSaveChannelDraft(channelId, fields))
    },
    [dispatch, channelId, drawerId]
  )

  const handleDoLater = useCallback(
    e => {
      handleOpenNextDrawer(e, true, true)
    },
    [handleOpenNextDrawer]
  )

  const handleOnExit = useCallback(
    () => {
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [rebuildMenuAndExit, rebuildExitAndRedirect, isInInbox]
  )

  const footer = useMemo(
    () => {
      return (
        <ModalBtns
          saveBtnText={
            <>
              {isSaving && (
                <span>
                  Saving<AnimatedEllipsis />
                </span>
              )}
              {!isSaving && drawer && 'Continue'}
              {!isSaving &&
                !drawer && <span>Finish &amp; take me to my channel</span>}
            </>
          }
          onSave={handleSaveAndOpenNextDrawer}
          saveBtnDisabled={isSaving}
          saveBtnTestId="save-and-continue-button"
          tertiaryBtnText="I'll do this later"
          onClickTertiaryBtn={handleDoLater}
          tertiaryBtnDisabled={isSaving}
        />
      )
    },
    [handleSaveAndOpenNextDrawer, handleDoLater, isSaving, drawer]
  )

  const handleOnHide = useCallback(() => {
    setIsLoading(null)
  }, [])

  return (
    <AdminAccessDrawer
      title={`${app.t('Agent')} access`}
      footer={footer}
      onClose={handleOnExit}
      onHide={handleOnHide}
      isLoading={isLoading}
      data-test-id="channels-members-edit"
    >
      <ChannelMembersForm
        channel={channel}
        defaultRestrictionType={draftChannel?.restriction_type}
        defaultUserIds={draftChannel?.user_ids}
        defaultGroupIds={draftChannel?.group_ids}
        onChange={handleChange}
        showError={hasError}
      />
    </AdminAccessDrawer>
  )
}

export default EditMembers
