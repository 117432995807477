import { flatMap, last } from 'util/arrays'
import { emptyObj, isEmpty, keys } from 'util/objects'
import { isString } from 'util/strings'
import { getFirstOrEmptyFromDraftRecipient } from 'util/draft'

const VARIABLES = {
  full_name: 'Full name',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email address',
  agent_firstname: `${app.t('Agent')} first name`,
}

export const getVariableValue = (
  currentUser,
  account,
  ticket,
  eventGroups,
  mailbox,
  draft,
  overrides = {},
  variableKey
) => {
  let to = getFirstOrEmptyFromDraftRecipient('to', draft)
  if (!to || isEmpty(to)) {
    to = ticket?.contact || emptyObj
  }
  switch (variableKey) {
    case 'conversation_subject':
      return overrides.title || draft?.title || ticket?.subject
    case 'conversation_number':
      return draft?.ticketId
    case 'contact_full_name':
    case 'customer_name':
    case 'full_name':
    case 'user_name':
      return to?.name
    case 'contact_first_name':
    case 'customer_first_name':
    case 'first_name':
      return to?.firstName || to?.name?.split(/\s+/)[0]
    case 'contact_last_name':
    case 'customer_last_name':
    case 'last_name':
      return to?.lastName || last(to?.name?.split(/\s+/))
    case 'contact_email':
    case 'customer_email':
    case 'email':
      return to?.email
    case 'company_name':
      return ticket?.contact?.companies?.[0]?.name
    case 'creator_full_name':
      return eventGroups?.events[0]?.actor?.name
    case 'agent_firstname':
    case 'agent_first_name':
      return currentUser?.first_name
    case 'agent_last_name':
      return currentUser?.last_name
    case 'agent_full_name':
    case 'agent_name':
      return currentUser
        ? `${currentUser?.first_name || ''} ${currentUser?.last_name || ''}`
        : undefined
    case 'comment':
      return last(eventGroups)?.summary?.body
    case 'organization_name':
      return account?.name
    case 'mailbox_name':
      return mailbox?.senderName || mailbox?.name
    case 'mailbox_email':
      return mailbox?.email
    default:
      return undefined
  }
}

// .+? means match more than one character, but not greedy
const variables = /%\{(.+?)}/gi

export const interpolateCannedReply = (
  currentUser,
  account,
  ticket,
  eventGroups,
  mailbox,
  draft,
  replyStr,
  overrides = {}
) => {
  if (!replyStr) return replyStr
  return replyStr.replace(variables, (match, variable) => {
    return (
      getVariableValue(
        currentUser,
        account,
        ticket,
        eventGroups,
        mailbox,
        draft,
        overrides,
        variable
      ) || ''
    )
  })
}

export function withoutGreeting(reply) {
  if (!reply) return reply
  return reply
    .replace(/^\w+\s+%\{(full_name|first_name)\},*\n*\s*/, '...')
    .trim()
}

export function withInterpolatedSnippet(
  reply,
  currentUser,
  account,
  ticket,
  changesets,
  mailbox,
  draft,
  overrides = {}
) {
  if (!reply) return reply
  return {
    ...reply,
    interpolatedSnippet: interpolateCannedReply(
      currentUser,
      account,
      ticket,
      changesets,
      mailbox,
      draft,
      withoutGreeting(reply.snippet),
      overrides
    ),
  }
}

// Runs the given fn if the given input is a string
const runIfStr = (fn, fallback = '') => str => {
  if (!str || !isString(str)) return fallback
  return fn(str)
}

function uninterpolatedTagsRegex() {
  return new RegExp(` ?%{(${keys(VARIABLES).join('|')})}`, 'g')
}

const matchUninterpolated = s => s.match(uninterpolatedTagsRegex()) || []

const doMatch = runIfStr(matchUninterpolated, [])

export function uninterpolatedTags(strOrArray) {
  if (!strOrArray) return []
  if (Array.isArray(strOrArray)) return flatMap(strOrArray, uninterpolatedTags)
  if (isString(strOrArray)) return doMatch(strOrArray)
  return []
}

export function stripTemplateTags(str) {
  return runIfStr(s => s.replace(uninterpolatedTagsRegex(), ''))(str)
}

const isPlaceholder = (block = {}) => block.type === 'Placeholder'

export function getWrappedRootNodes(reply, editor) {
  const BLOCK_ELEMENTS = editor.schema.getBlockElements()
  const rootBlockElementName = editor.settings.forced_root_block || 'div'
  const parser = document.createElement('div')
  const result = document.createElement('div')
  parser.insertAdjacentHTML('afterbegin', reply)
  // NOTE (jscheel): Make a copy of the NodeList so we can iterate and munge it
  // with impunity. If we relied on the NodeList, we would have issues while
  // moving nodes around.
  const rootNodes = Array.from(parser.childNodes)
  // END NOTE

  const blocks = removeRedundantBreaks(
    BLOCK_ELEMENTS,
    rootBlockElementName,
    rootNodes
  )

  // We now have a list of the block-level elements, wrapped properly, sans empty
  // breaks. Now insert them into the document.
  blocks.forEach(nodeOrPlaceholder => {
    if (isPlaceholder(nodeOrPlaceholder)) {
      // a non-block element (from rootNodes) that we need to wrap in a block elem.
      const placeholder = nodeOrPlaceholder
      const rootNode = document.createElement(placeholder.elementName)
      result.appendChild(rootNode)
      placeholder.children.forEach(child => rootNode.appendChild(child))
    } else {
      // its just a block node (from rootNodes)
      result.appendChild(nodeOrPlaceholder)
    }
  })

  return result
}

const isBreak = (node = {}) => node.nodeName.toUpperCase() === 'BR'

const isDoubleBreak = (node = {}) =>
  node.nodeName === '#text' && node.data === '\n\n'

// Returns a list of blocks, without redundant line breaks
//
// Internal API, but exported for unit tests.
export function removeRedundantBreaks(
  blockElements,
  rootElemName,
  rootNodes = []
) {
  let blocks = []
  let currentRootBlock = null
  const isBlock = node => blockElements[node.nodeName]

  for (let i = 0; i < rootNodes.length; i += 1) {
    const node = rootNodes[i]

    if (isBreak(node)) {
      // NOTE (jscheel): If we have a BR element, we assume that it is the end
      // of a root block node.
      currentRootBlock = null
    } else if (isDoubleBreak(node)) {
      // skip non-html \n\n double breaks
    } else if (isBlock(node)) {
      // NOTE (jscheel): If we have a block-level element, we insert it as a new
      // root block element in the result.
      blocks = blocks.concat(node)
      currentRootBlock = null
    } else {
      if (!currentRootBlock) {
        // NOTE (jscheel): If we don't have a root block element, make one.
        currentRootBlock = {
          type: 'Placeholder',
          elementName: rootElemName,
          children: [],
        }
        blocks = blocks.concat(currentRootBlock)
      }
      currentRootBlock.children = currentRootBlock.children.concat(node)
    }
  }

  return blocks
}
