import { selectMailboxesByName } from 'ducks/mailboxes/selectors/selectMailboxesByName'
import { selectMailboxesByEmail } from 'ducks/mailboxes/selectors/selectMailboxesByEmail'
import { selectMailbox } from 'ducks/mailboxes/selectors/selectMailbox'
import {
  selectCurrentFolders,
  selectCurrentFoldersById,
} from 'ducks/folders/selectors/folders'

// This file gets used by the performance simulator

export const getMailboxById = mailboxId => {
  const state = app.store.getState()
  return selectMailbox(state, mailboxId)
}

export const getMailboxByName = name => {
  const state = app.store.getState()
  return selectMailboxesByName(state)[name]
}

export const getMailboxByEmail = email => {
  const state = app.store.getState()
  return selectMailboxesByEmail(state)[email]
}

export const getMailboxFolderById = folderId => {
  const state = app.store.getState()
  return selectCurrentFoldersById(state)[folderId]
}

export const getMailboxFolderByName = name => {
  const state = app.store.getState()
  return selectCurrentFolders(state).find(x => x.name === name)
}
