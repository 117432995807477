import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { hasCookiesEnabled } from 'util/browser'
import storage from 'util/storage'
import { getUncachedVersion } from 'util/version'
import { getTabId } from 'util/tabId'
import { pick, omit } from 'util/objects'
import {
  addToJsonToError,
  isIgnoredError,
  getLocalStorageStats,
  isUnauthorized,
  displayErrorMessage,
} from 'util/global-exceptions'

// eslint-disable-next-line func-names
export function buildUserForBugsnag(user) {
  // Grab the user from LocalStorage, if we have it.
  const currentUser = user || storage.get('currentUser')
  if (!currentUser) return undefined

  return pick(['id', 'name'], currentUser)
}

export function buildUserMetadata(user) {
  const currentUser = user || storage.get('currentUser')
  if (!currentUser) return undefined

  const disallowedFields = [
    'id',
    'name',
    'avatar_url',
    'avatarUrl',
    'email',
    'first_name',
    'last_name',
    'username',
    'chatAccessToken',
  ]

  return omit(disallowedFields, currentUser)
}

// eslint-disable-next-line func-names
export function buildAccountForBugsnag(account = {}) {
  const { subdomain = '' } = account
  return {
    subdomain,
  }
}

export const bootstrap = env => {
  // Add toJSONN to allerror objects
  addToJsonToError()
  // ensure the variable is set, we will overwrite it
  // when we get the client
  const version = getUncachedVersion()
  // if (window.location.host.match('.docker')) return
  const isDevelopment = version === '' || version === '{{version}}'
  global.bugsnagClient = Bugsnag.start({
    apiKey: '33d5fbb590d86e3dac76839b7fef7a70',
    appVersion: isDevelopment ? 'development-build' : version,
    releaseStage: env,
    enabledReleaseStages: ['production', 'staging', 'alpha-auto'],
    enabledBreadcrumbTypes: [
      'navigation',
      'request',
      'process',
      'user',
      'state',
      'error',
      'manual',
    ],
    metadata: {
      localStorageEnabled: storage.checkAvailability(),
      localStorageStats: getLocalStorageStats(),
      cookiesEnabled: hasCookiesEnabled(),
      tabId: getTabId(),
      account: buildAccountForBugsnag(),
    },
    user: buildUserForBugsnag(),
    plugins: [new BugsnagPluginReact()],
    onBreadcrumb: breadcrumb => {
      if (breadcrumb.message === 'UI click') {
        try {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.metadata.targetText = null
        } catch (e) {
          // pass
        }
      }
    },
    // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/
    onError: event => {
      try {
        // do not report if we pointing a local dev frontend to prod or staging
        if (window?.location?.hostname?.endsWith('.docker')) return false

        // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#errors
        const bugsnagError = event.errors[0]
        const originalError = event.originalError

        if (isIgnoredError(bugsnagError, originalError)) return false

        const isUnauthorizedError = isUnauthorized(originalError)
        // Added for backward compatibility, but this seems like a pretty useless piece of information
        const type = isUnauthorizedError
          ? 'Logout-triggering authorization failure'
          : 'Non-auth'

        event.addMetadata('user', buildUserMetadata())
        event.addMetadata('metaData', {
          type,
          // https://docs.bugsnag.com/platforms/react-native/expo/customizing-error-reports/#unhandled
          global: event.unhandled,
          fatal: !!originalError.fatal,
        })

        // Kevin R: 2022-02-07
        // Ported this logic from handleError
        // Seems wrong, but we'll keep it for now
        if (!isUnauthorizedError) {
          displayErrorMessage(originalError)
        }
      } catch (fatalError) {
        // eslint-disable-next-line no-console
        console.log(
          'Bugsnag onError threw another error. Suppressing this error to prevent original error from getting reported',
          fatalError
        )
      }
      return true
    },
  })
}
