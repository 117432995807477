import React, { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDataTable } from 'ducks/tables/hooks'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { Trash, Merge } from '@groovehq/internal-design-system/lib/assets/icons'
import { doFetchTagsV2 } from 'ducks/tags/actions'
import { SETTINGS_TAG_TABLE_ID } from 'ducks/tables/ids'
import {
  DRAWER_TYPE_TAGS_DELETE,
  DRAWER_TYPE_TAGS_UPDATE,
  DRAWER_TYPE_TAGS_MERGE,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { invert } from 'util/objects'
import { AGENT_ROLE_ADMIN, AGENT_ROLE_OWNER } from 'ducks/agents/constants'
import useColumns from './useColumns'
import { styles } from './styles'

const sortMapping = {
  name: 'NAME',
  conversationCount: 'COUNT',
  creator: 'CREATOR_ID',
  createdAt: 'CREATED_AT',
  lastUsedAt: 'LAST_USED_AT',
}

const sortMappingInverted = invert(sortMapping)

function transformSortMapping(columnId, desc) {
  return {
    id: sortMappingInverted[columnId],
    desc,
  }
}

const TagDataTable = ({ compact }) => {
  const {
    pagination: { currentDenormalizedEntities: tags, gotoPage, changePageSize },
    pagination,
    filtering: { changeFilterDelayed, changeFilter },
    sorting: { changeSort },
    sorting,
    query: { search },
    queryId,
    isLoading,
    shouldLoad,
    isError,
    selection: {
      ids: selectedIds,
      mode: selectionMode,
      handleOnSelectedRowIdsChange,
      handleOnSelectedModeChange,
    },
    scroll,
  } = useDataTable(SETTINGS_TAG_TABLE_ID, 'tag', doFetchTagsV2, {
    pagination: {
      defaultPageSize: 20,
      defaultCursor: 1,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 30],
      filterDelay: 1000,
      // HACK: The gql api uses cursor based pagination which was implemented to use
      // page number as the "cursors", which is totally wrong!. Taking that our underlying
      // Pagination comnponent on our table doesnt support "real" cursors, I'll just use this
      // for now. If we do change this to use real cursors, then the following code will break
      parseToIntKeys: ['cursor'],
      mode: 'cursor',
      defaultOrderBy: 'NAME_ASC',
    },
    sorting: {
      transformSort: transformSortMapping,
    },
    scroll: {
      persistScrollPosition: true,
    },
  })
  const currentUser = useSelector(selectCurrentUser)
  const currentUserIsManager = [AGENT_ROLE_ADMIN, AGENT_ROLE_OWNER].includes(
    currentUser.role
  )
  const columns = useColumns({ compact, currentUser })
  const tableActionsRef = useRef({})

  // We intentially take the initial value and never change it because we dont actually want
  // the default value to ever change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultGlobalFilter = useMemo(() => search || null)

  const handleOnTableStateChange = useCallback(
    (newState, action = {}) => {
      const { type } = action || {}
      if (type === 'gotoPage') {
        const { pageIndex } = action
        gotoPage(pageIndex + 1)
      } else if (type === 'setPageSize') {
        const { pageSize } = action
        changePageSize(pageSize)
      } else if (type === 'toggleSortBy' || type === 'setSortBy') {
        const { id, desc } = newState.sortBy[0] || {}
        const sortField = sortMapping[id]
        changeSort(sortField ? `${sortField}_${desc ? 'DESC' : 'ASC'}` : null)
      } else if (type === 'setGlobalFilter') {
        const { filterValue } = action
        // When setting filter, debounce for 1 second, but when removing a filter
        // trigger that immediately
        if (filterValue) {
          changeFilterDelayed('search', filterValue, {
            resetPage: true,
            persist: true,
          })
        } else {
          changeFilter('search', filterValue, {
            resetPage: true,
            persist: true,
          })
        }
      }
    },
    [changeSort, changeFilterDelayed, changeFilter, gotoPage, changePageSize]
  )

  const handleClearSelection = useCallback(() => {
    const { current: { toggleAllRowsSelected } = {} } = tableActionsRef
    if (toggleAllRowsSelected) {
      toggleAllRowsSelected(false, 'ids')
    }
  }, [])

  const { openDrawer: openUpdateDrawer } = useDrawer(DRAWER_TYPE_TAGS_UPDATE)

  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_TAGS_DELETE
  )

  const { drawerId: mergeTagDrawerId, openDrawer: openMergeDrawer } = useDrawer(
    DRAWER_TYPE_TAGS_MERGE
  )

  const handleDelete = useCallback(
    id => {
      openDeleteDrawer(id, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
        additionalProps: {
          queryId,
          onRemove: handleClearSelection,
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId, queryId, handleClearSelection]
  )

  const handleDeleteAll = useCallback(
    () => {
      openDeleteDrawer(selectedIds.join(','), {
        query: {
          ...buildDrawerQueryParam(
            deleteDrawerId,
            'drawerDeleteMode',
            selectionMode === 'all' ? 'query' : 'ids'
          ),
        },
        additionalProps: {
          queryId,
          onRemove: handleClearSelection,
        },
      })
    },
    [
      selectedIds,
      selectionMode,
      openDeleteDrawer,
      deleteDrawerId,
      queryId,
      handleClearSelection,
    ]
  )

  const handleEdit = useCallback(
    id => {
      openUpdateDrawer(id, {
        additionalProps: {
          queryId,
        },
      })
    },
    [openUpdateDrawer, queryId]
  )

  const handleMergeTags = useCallback(
    () => {
      openMergeDrawer(selectedIds.join(','), {
        query: {
          ...buildDrawerQueryParam(
            mergeTagDrawerId,
            'drawerMergeTagMode',
            selectionMode === 'all' ? 'query' : 'ids'
          ),
        },
        additionalProps: {
          queryId,
          onChange: handleClearSelection,
        },
      })
    },
    [
      selectedIds,
      selectionMode,
      openMergeDrawer,
      mergeTagDrawerId,
      queryId,
      handleClearSelection,
    ]
  )

  const controls = useCallback(
    (_, getToggleAllPageRowsSelectedProps) => {
      const {
        checked,
        indeterminate,
        onChange,
      } = getToggleAllPageRowsSelectedProps()

      if (!checked && !indeterminate) return null

      return (
        <>
          <Checkbox
            id="tagsSelection"
            onChange={onChange}
            checked={checked}
            indeterminate={indeterminate}
          />
          <span className="grui ml-12">
            <Button
              type="tertiary"
              size="small"
              customIcon={<Merge />}
              onClick={handleMergeTags}
            >
              Merge
            </Button>
          </span>
          <span className="grui ml-5">
            <Button
              type="tertiary"
              size="small"
              customIcon={<Trash />}
              onClick={handleDeleteAll}
            >
              Delete
            </Button>
          </span>
        </>
      )
    },
    [handleDeleteAll, handleMergeTags]
  )

  return (
    <Table
      separate
      controls={currentUserIsManager && controls}
      columns={columns}
      css={styles.table}
      data={tags}
      showPagination
      pagination={pagination}
      onTableStateChange={handleOnTableStateChange}
      sorting={sorting}
      hasSearch
      searchPlaceholder="tags"
      loading={shouldLoad || isLoading}
      defaultGlobalFilter={defaultGlobalFilter}
      emptyHintVisible={false}
      hasError={isError}
      updateData={handleEdit}
      deleteData={handleDelete}
      onEdit={handleEdit}
      dataType="tags"
      onSelectedRowIdsChange={handleOnSelectedRowIdsChange}
      onSelectedModeChange={handleOnSelectedModeChange}
      tableActionsRef={tableActionsRef}
      scroll={scroll}
      disableSortRemove
    />
  )
}

TagDataTable.displayName = 'TagDataTable'

export default TagDataTable
