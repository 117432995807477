import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import FilterBarView from 'components/ConversationList/Header/FilterBar'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import { selectCurrentQueryFolder } from 'ducks/folders/selectors/folders/selectCurrentQueryFolder'
import { FOLDER_KIND_DRAFT } from 'ducks/folders/constants'
import {
  queryIdToQuery,
  queryStringToQueryId,
} from 'ducks/searches/utils/query'
import { compact } from 'util/objects'

const SUB_FILTER_OPTIONS = {
  state: {
    Any: { is: null },
    Open: { is: 'open' },
    Snoozed: { is: 'snoozed' },
    Closed: { is: 'closed' },
  },
  drafts: {
    'Reply Drafts': { draftType: 'reply' },
    'Note Drafts': { draftType: 'note' },
  },
}

const FilterBar = props => {
  const currentQuery = useSelector(selectCurrentQuery)
  const currentFolder = useSelector(selectCurrentQueryFolder)

  const searchIsFolderSearch = !!currentQuery.folder
  const isRightType = !searchIsFolderSearch
  const isDrafts = currentFolder?.kind === FOLDER_KIND_DRAFT

  const shouldShowOptions = isRightType || !!isDrafts

  const options = useMemo(
    () => {
      const filterOptionsSettings = isDrafts
        ? SUB_FILTER_OPTIONS.drafts
        : SUB_FILTER_OPTIONS.state
      const currentQueryId = queryStringToQueryId(currentQuery)

      return Object.keys(filterOptionsSettings).reduce(
        (filterOptions, optionKey) => {
          const settings = filterOptionsSettings[optionKey]
          const settingQuery = Object.keys(settings).reduce(
            (query, queryKey) => {
              // eslint-disable-next-line no-param-reassign
              query[queryKey] = settings[queryKey]
              return query
            },
            { ...currentQuery }
          )

          const settingQueryId = queryStringToQueryId(settingQuery)
          const cleanSettingQueryId = queryStringToQueryId(
            compact(queryIdToQuery(settingQueryId, { parseNull: true }))
          )
          // eslint-disable-next-line no-param-reassign
          filterOptions.push({
            queryId: settingQueryId,
            label: optionKey,
            current: cleanSettingQueryId === currentQueryId,
          })
          return filterOptions
        },
        []
      )
    },
    [isDrafts, currentQuery]
  )

  return (
    <FilterBarView
      shouldShowOptions={shouldShowOptions}
      options={options}
      {...props}
    />
  )
}

export default FilterBar
