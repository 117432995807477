import config from 'config'
import { planIdToComponents } from 'ducks/billing/util'
import Cookies from 'js-cookie'

export const getValidDiscount = discountCode => {
  const validDiscountPercentages = ['60', '50', '30']
  const validDiscountMonths = ['6', '4', '3']
  const splitDiscountCode = discountCode ? discountCode.split('-') : []

  const isValid = splitDiscountCode.every((v, i) => {
    if (i === 1) {
      return validDiscountPercentages.includes(v)
    }
    if (i === 2) {
      return validDiscountMonths.includes(v)
    }
    return true
  })

  return isValid
    ? {
        durationInMonths: splitDiscountCode[2],
        percentOff: splitDiscountCode[1],
      }
    : null
}

// We've started a pricing experiment on 2019-10-10 where customers who see the website
// for the first time after this date will see the new pricing, and customers before this
// will see the old pricing. This means we need to pass the corrosponding billing version
// into the signup so that the correct plans are configured
export function getSignupAccountAttributes({
  // eslint-disable-next-line no-unused-vars
  provider = 'stripe',
  query,
} = {}) {
  const billingPricingIds = []
  let billingCycle = null
  let coupon = null
  if (query?.plan) {
    query.plan.split('|').forEach(planId => {
      const { cycle, pricingId } = planIdToComponents(planId)
      billingPricingIds.push(pricingId)
      if (!billingCycle && cycle) {
        billingCycle = cycle
      }
    })
  }
  if (query?.discount) {
    coupon = getValidDiscount(query.discount) ? query.discount : null
  }

  return {
    preferred_inbox_billing_version: 'v22',
    preferred_kb_billing_version: 'v5',
    preferred_addon_billing_version: 'v2',
    preferred_billing_coupon: coupon,
    preferred_billing_default_pricing_ids: billingPricingIds.join(','),
    preferred_billing_default_cycle: billingCycle,
  }
}

const getTrackingCampaign = () => {
  return Cookies.get('attr_cID', { domain: `.${config.appDomain}` })
}

const getTrackingGroup = () => {
  return Cookies.get('attr_gID', { domain: `.${config.appDomain}` })
}

const getTrackingKeyword = () => {
  return Cookies.get('attr_kID', { domain: `.${config.appDomain}` })
}

const getTrackingUtmz = () => {
  return Cookies.get('__utmzz', { domain: `.${config.appDomain}` })
}

const getTrackingUtmzReplica = () => {
  return Cookies.get('att_utmzz_repl', { domain: `.${config.appDomain}` })
}

export const getMarketingAttribution = () => {
  const value = Cookies.get('grooveNavigation', {
    domain: `.${config.appDomain}`,
  })

  const attrs = {}

  if (value) {
    const data = value.split(':')
    const fields = [
      'blog_first_page_url',
      'blog_first_page_date',
      'blog_last_page_url',
      'blog_last_page_date',
      'blog_articles',
      'website_first_page_url',
      'website_first_page_date',
      'website_last_page_url',
      'website_last_page_date',
      'signup_segment',
      'entrance_segment',
    ]

    fields.forEach((field, index) => {
      if (data[index] !== 'null') attrs[field] = data[index]
    })
  }

  const campaign = getTrackingCampaign()
  const group = getTrackingGroup()
  const keyword = getTrackingKeyword()
  const utmz = getTrackingUtmz()
  const utmzReplica = getTrackingUtmzReplica()

  if (campaign) attrs.campaign = campaign
  if (group) attrs.group_id = group
  if (keyword) attrs.keyword = keyword
  if (utmz) attrs.utmz = utmz
  if (utmzReplica) attrs.utmz_replica = utmzReplica

  return attrs
}

export const canShowError = (focusedFields, name, value) => {
  return !focusedFields[name] && !!value
}
