import { useCallback, useMemo } from 'react'
import { queryStringToQueryId } from 'ducks/searches/utils/query'
import { useDispatch, useSelector } from 'react-redux'
import { selectMergingSearchTerm } from 'ducks/merging/selectors/base'
import {
  doFetchMergeSearchByQueryId,
  doUpdateMergeSearchTerm,
} from 'ducks/merging/operations'
import { debounce } from 'util/functions'

import MenuWithSearch from 'subapps/settings/components/MenuWithSearch'
import List from './List'

import { styles } from '../styles'

const SearchableList = ({ placeholder, onSelect }) => {
  const dispatch = useDispatch()
  const searchTerm = useSelector(selectMergingSearchTerm)

  const handleSearch = useCallback(
    term => {
      dispatch(
        doFetchMergeSearchByQueryId(queryStringToQueryId({ search: term }))
      )
    },
    [dispatch]
  )

  const debouncedSearch = useMemo(
    () => {
      return debounce(handleSearch, 500)
    },
    [handleSearch]
  )

  const onSearch = useCallback(
    async event => {
      const value = event.target.value

      if (value && value.length > 0) {
        debouncedSearch(value)
      }
      dispatch(doUpdateMergeSearchTerm(value))
    },
    [debouncedSearch, dispatch]
  )

  const onLoadMoreSearch = useCallback(
    () => {
      dispatch(doFetchMergeSearchByQueryId())
    },
    [dispatch]
  )

  return (
    <>
      <div className="grui flex justify-center mt-6">
        <MenuWithSearch.Search
          value={searchTerm}
          onChange={onSearch}
          placeholder={placeholder}
          shouldFocus
        />
      </div>

      <div className="grui mt-7" css={styles.divider} />

      <List
        searchTerm={searchTerm}
        onLoadMoreSearch={onLoadMoreSearch}
        onSelect={onSelect}
      />
    </>
  )
}

export default SearchableList
