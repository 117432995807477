import { ruleActionsForUnpaid } from '../drawers/RuleCreateEdit/data'

const parameters = [
  {
    name: 'Set State as',
    value: 'status',
  },
  {
    name: `Assign to ${app.t('Agent')}`,
    value: 'assignee_id',
  },
  {
    name: `Assign to ${app.t('Group')}`,
    value: 'assignee_group_id',
  },
  {
    name: 'Set Snooze',
    value: 'snooze_until',
  },
  { name: 'Remove tag', value: 'label_remove', parameterType: 'id' },
  { name: 'Remove all tags', value: 'label_remove_all' },
]

const values = {
  status: {
    dataType: 'LIST',
    options: [
      {
        name: 'Opened',
        value: 'opened',
        disabled: false,
      },
      {
        name: 'Closed',
        value: 'closed',
        disabled: false,
      },
      {
        name: 'Spam',
        value: 'spam',
        disabled: false,
      },
    ],
  },
  snooze_until: ruleActionsForUnpaid.values.SNOOZE_UNTIL,
  assignee_id: { dataType: 'LIST', valueType: 'Agent' },
  assignee_group_id: { dataType: 'LIST', valueType: 'Group' },
  label_remove: {
    dataType: 'TAGS',
    paramType: 'id',
  },
  label_remove_all: {
    dataType: 'NONE',
  },
}

export const cannedReplyActions = {
  parameters,
  values,
}
