import { initials as agentInitials } from 'util/agents'
import { firstInitial } from 'ducks/customers/utils'

export const avatarURL = actor =>
  actor && actor.avatar_url && !actor.avatar_url.includes('missing')
    ? actor.avatar_url
    : null

export const initials = actor => {
  if (!actor) return ''
  if (actor.type === 'Agent') return agentInitials(actor)
  if (actor.agentFullName) return agentInitials(actor) // its an assignment...
  return firstInitial(actor)
}

export const getInitialsFromName = name => {
  if (!name) return ''
  return name
    .split(' ')
    .map(n => n.charAt(0))
    .join('')
    .slice(0, length)
}
