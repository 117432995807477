import { useCallback } from 'react'
import { uncapitalize } from 'util/strings'
import { timeInHuman } from 'util/date'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import { useSelector } from 'react-redux'
import { selectAuthorById } from 'ducks/tickets/selectors/selectAuthorById'
import { emptyObj } from 'util/objects'
import { getType } from 'util/globalId'

import { styles } from './styles'

const ListItem = ({
  ticket: {
    subject: titleLabel,
    summaryMessage: {
      bodyPlainText: body,
      author: { id: authorId },
    },
    updatedAt: time,
    counts: { messages: messageCount },
  },
  onClick,
  sendingDelayed = false,
}) => {
  const { name: authorName } =
    useSelector(state => selectAuthorById(state, authorId)) || emptyObj
  const humanizedTime = timeInHuman(time)
  const snippet = body ? body.replace(/^(@[\w]+)\s*/, '') : '' // remove leading twitter names
  const bodyHasAgentAuthor = authorId && getType(authorId) === 'Agent'

  const handleClick = useCallback(
    () => {
      if (sendingDelayed) {
        return
      }

      onClick()
    },
    [onClick, sendingDelayed]
  )

  return (
    <div
      className="grui overflow-hidden flex-col pl-10 pr-10 py-4"
      css={styles.wrapper}
      onClick={handleClick}
      data-test-id="ticket-merge-option"
    >
      {sendingDelayed && (
        <span css={styles.stillSending}>
          (Merge unavailable. {app.t('Ticket')} still sending...)
        </span>
      )}
      <div className="grui flex justify-between items-center whitespace-nowrap">
        <div className="grui truncate">
          <span>{titleLabel || <em>No Subject</em>}</span>
          {messageCount > 1 && (
            <span className="grui ml-4" css={styles.messagesCount}>
              ({messageCount})
            </span>
          )}
        </div>
        <span className="grui ml-10" css={styles.date}>
          {humanizedTime}
        </span>
      </div>

      <div className="grui flex items-center truncate" css={styles.bodyText}>
        {bodyHasAgentAuthor && (
          <span className="grui mr-3" css={styles.author}>
            {`${uncapitalize(authorName)}: `}
          </span>
        )}

        <SanitizedHTML html={snippet} />
      </div>
    </div>
  )
}

export default ListItem
