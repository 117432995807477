import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsBootstrapTypedInStates } from 'selectors/bootstrap/makeSelectIsBootstrapTypesLoaded'
import { selectFolderCollectionsByType } from 'ducks/folders/selectors/collections'
import {
  doBuildInboxMenuFromMailboxes,
  doRedirectToCollectionAndFolderById,
} from 'ducks/folders/operations/collections'
import FolderCollection from 'components/Folders/Collection'
import { selectIsOnTicketsPage } from 'selectors/location'
import {
  selectIsOnMainPage,
  selectIsOnSearchPageType,
} from 'ducks/location/selectors'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

const doOnBootstrapBuildInboxMenuFromMailboxes = (dispatch, getState) => {
  const state = getState()
  const isOnSearch = selectIsOnSearchPageType(state)
  const currentTicketId = selectCurrentTicketId(state)
  const isOnTickets = selectIsOnTicketsPage(state)
  const isOnMain = selectIsOnMainPage(state)
  const autoRedirect =
    (isOnSearch && !currentTicketId) || isOnTickets || isOnMain

  return dispatch(
    doBuildInboxMenuFromMailboxes({ autoRedirect, ignoreLast: false })
  )
}

const Folders = ({ type }) => {
  const dispatch = useDispatch()
  const [hasTriggeredBootstrap, setHasTriggeredBootstrap] = useState(false)
  const collections = useSelector(state =>
    selectFolderCollectionsByType(state, type)
  )
  const isBootstrapped = useSelector(state =>
    selectIsBootstrapTypedInStates(
      state,
      ['folders', 'mailboxes', 'currentUser', 'agents', 'labels'],
      ['LOADED']
    )
  )
  const isOnMain = useSelector(selectIsOnMainPage)

  const onBootstrap = useCallback(
    () => {
      dispatch(doOnBootstrapBuildInboxMenuFromMailboxes)
    },
    [dispatch]
  )

  const onMainPage = useCallback(
    () => {
      dispatch(
        doRedirectToCollectionAndFolderById(null, null, { ignoreLast: false })
      )
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (onBootstrap && isBootstrapped) {
        if (!hasTriggeredBootstrap) {
          onBootstrap()
          setHasTriggeredBootstrap(true)
        } else if (isOnMain) {
          onMainPage()
        }
      }
    },
    [
      onBootstrap,
      onMainPage,
      isOnMain,
      isBootstrapped,
      hasTriggeredBootstrap,
      setHasTriggeredBootstrap,
    ]
  )

  const compactView = collections.length === 1

  return (
    <>
      {collections.map(({ id }) => (
        <FolderCollection
          key={id}
          collectionId={id}
          compactView={compactView}
        />
      ))}
    </>
  )
}

export default React.memo(Folders)
