import isFastDeepEqual from 'fast-deep-equal'
import config from 'config'
import dot from 'dot-object'
import snakecase from 'lodash.snakecase'

import { camelize } from 'util/strings'
import { isDefined } from 'util/nullOrUndefinedChecks'

export * from './objectToId'

export const emptyObj = config.isDevelopment ? Object.freeze({}) : {}

export const isEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object

export const filter = (predicate, object) => {
  return Object.keys(object).reduce((newObject, key) => {
    if (predicate(object[key])) {
      // eslint-disable-next-line  no-param-reassign
      newObject[key] = object[key]
    }
    return newObject
  }, {})
}

export const hasProp = (obj, prop) =>
  Object.prototype.hasOwnProperty.call(obj, prop)

export const pick = (keys, object = {}, includeEmptyKeys = false) => {
  return keys.reduce((newObject, key) => {
    if (hasProp(object, key) || includeEmptyKeys) {
      newObject[key] = object[key] // eslint-disable-line no-param-reassign
    }
    return newObject
  }, {})
}

export const pickAndChangeKey = (
  keysObject,
  object = {},
  includeEmptyKeys = false
) => {
  return Object.keys(keysObject).reduce((newObject, key) => {
    if (hasProp(object, key) || includeEmptyKeys) {
      newObject[keysObject[key]] = object[key] // eslint-disable-line no-param-reassign
    }
    return newObject
  }, {})
}

export const omit = (withoutKeys, object) => {
  const newObject = Object.assign({}, object)

  withoutKeys.forEach(key => delete newObject[key])

  return newObject
}

export const keys = obj => Object.keys(obj)
export const length = obj => obj && keys(obj).length
export const values = obj => Object.values(obj)

export const flip = (object, options = {}) => {
  return keys(object).reduce((newObject, key) => {
    if (options && options.skip.indexOf(key) > -1) return newObject
    // eslint-disable-next-line  no-param-reassign
    newObject[object[key]] = key
    return newObject
  }, {})
}

const assign = (...obj) => Object.assign({}, ...obj)

const curriedMap = func => elems => elems.map(elem => func(elem))

/**
 * Curried map function for objects. Iterates over the given `obj`, applying
 * `func`, and returning a new object. Similar to lodash's `mapValues()`
 *
 * @example
 *
 * const obj = { '1': 'heyyy', '2': 'hooo' }
 * const capitalize = (string) => string[0].toUpperCase() + string.slice(1)
 *
 * > map(capitalize)(obj)
 * // => { '1': 'Heyyy', '2': 'Hooo' }
 */
export const mapObject = func => object => {
  const obj = assign(object) // shallow copy
  curriedMap(x => (obj[x] = func(obj[x])))(keys(obj))
  return obj
}

export const deepCopy = (obj, options) => {
  const { stringify: { replacer, space } = {}, parse: { reviver } = {} } =
    options || {}
  const isNull = obj === undefined || obj === null
  // eslint-disable-next-line  no-param-reassign
  obj = isNull ? {} : obj
  return JSON.parse(JSON.stringify(obj, replacer, space), reviver)
}

/**
 * Curried map function for objects. Iterates over the given `obj`, applying
 * `func` to the object props (keys), and returning a new object.
 *
 * @example
 *
 * const obj = { 'some_thing': 'a', 'other thing': 'b' }
 *
 * > map(camelize)(obj)
 * // => { 'someThing': 'a', 'otherThing': 'b' }
 */
export const mapObjectKeys = func => object => {
  const obj = {}
  curriedMap(x => (obj[func(x)] = object[x] ? deepCopy(object[x]) : object[x]))(
    keys(object)
  )
  return obj
}

// Poor mans object comparison
export const equals = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2)

/**
 * Given two objects, checks whether keys and values from first object are
 * equal to keys and values in second object (additional keys in second object
 * are ignored).
 */
export const looselyEquals = (obj1, obj2) => {
  return Object.keys(obj1).every(key => key in obj2 && obj1[key] === obj2[key])
}

export const isDeepEqual = (...args) => isFastDeepEqual(...args)

export const downcaseObjectKeys = mapObjectKeys(s => s.toLowerCase())
export const camelizeObjectKeys = mapObjectKeys(s =>
  camelize(s, { lowercase: false })
)

export function getValueByPath(path, obj) {
  if (typeof obj !== 'object' || obj === null || obj === undefined) {
    return null
  }
  // TODO (jscheel): Refactor all uses of this to just use dot.pick. I'm not doing
  // this right now because I don't currently have the bandwidth to test the changes.
  return dot.pick(path, obj)
}

/**
 * Swap the key and values of an object
 */
export function invert(obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    // eslint-disable-next-line no-param-reassign
    newObj[obj[key]] = key
    return newObj
  }, {})
}

export const sortKeys = unordered => {
  const ordered = {}
  Object.keys(unordered)
    .sort()
    .forEach(key => {
      ordered[key] = unordered[key]
    })
  return ordered
}

export const compact = (object = {}) => {
  return Object.keys(object).reduce((newObject, key) => {
    if (object[key]) {
      newObject[key] = object[key] // eslint-disable-line no-param-reassign
    }
    return newObject
  }, {})
}

export const isObject = object => {
  return isDefined(object) && typeof object === 'object'
}

export const snakecaseKeys = object => {
  return Object.keys(object).reduce((result, key2) => {
    // eslint-disable-next-line no-param-reassign
    result[snakecase(key2)] = object[key2]
    return result
  }, {})
}

export const snakecaseKeysNested = object => {
  if (!isObject(object)) return object

  if (Array.isArray(object)) {
    return object.map(snakecaseKeysNested)
  }

  return Object.keys(object).reduce((result, key) => {
    const snakeKey = snakecase(key)
    // eslint-disable-next-line no-param-reassign
    result[snakeKey] = snakecaseKeysNested(object[key])
    return result
  }, {})
}

export function deepFreeze(o) {
  Object.freeze(o)

  Object.getOwnPropertyNames(o).forEach(prop => {
    if (
      // eslint-disable-next-line no-prototype-builtins
      o.hasOwnProperty(prop) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop])
    }
  })

  return o
}

export const removeNullValues = data => {
  if (Array.isArray(data)) {
    return data.filter(item => item !== null).map(item => {
      if (isObject(item)) {
        return removeNullValues(item)
      }
      return item
    })
  }

  if (isObject(data)) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== null) {
        // eslint-disable-next-line no-param-reassign
        acc[key] = isObject(value) ? removeNullValues(value) : value
      }
      return acc
    }, {})
  }

  return data
}
