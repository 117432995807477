import React, { useState, useCallback, useEffect, useMemo } from 'react'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Help from '@groovehq/internal-design-system/lib/components/Field/Help/Help'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useController } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useRhfDirtyHold, useConfirmHoldsCallback } from 'util/dirtyHolds'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'

import { doUpdateChannel } from 'ducks/channels/actions'
import {
  DROPDOWN,
  MULTI_SELECT,
  PUBLIC_CUSTOM_FIELD_TYPES,
  TYPE_ICONS_MAP,
} from 'ducks/crm/customFields/types'
import * as icons from 'assets/icons/groove/v2'
import { capitalize, humanize } from 'util/strings'
import Switch from '@groovehq/internal-design-system/lib/components/Switch/Switch'
import QuestionTooltip from 'components/QuestionTooltip'
import { selectCurrentChannels } from 'ducks/channels/selectors'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import ArrayOfStrings from 'shared/components/ui/Form/ArrayOfStrings'
import { selectCustomFieldForKey } from 'ducks/crm/customFields'
import { useLoadAllCustomFields } from 'ducks/crm/customFields/hooks'
import { buildIdFromAny, getRawId } from 'util/globalId'
import { doFetchMailboxes } from 'ducks/mailboxes/actions'
import { getChannelIdFromKey, isGlobalKey } from 'ducks/crm/channels/utils'
import {
  customFieldTypeDropdownItem,
  dropdownContainer,
  textFieldCss,
  dropdownBtn,
} from './styles'
import { generateKey, stripPrefix } from './utils'

const FIELD_KEY = 'key'
const FIELD_NAME = 'name'
const FIELD_TYPE = 'type'
const FIELD_REQUIRED = 'required'
const FIELD_GLOBAL = 'global'
const FIELD_CHANNEL = 'channel'
const FIELD_OPTIONS = 'options'

yup.addMethod(yup.string, 'uniqueName', function uniqueName(
  getConfig,
  errorMessage
) {
  return this.test('unique-name', errorMessage, function validate(value) {
    const { path, createError } = this
    const { isUpdate, customFields, existingCustomField } = getConfig() // Get Redux state here

    // Creates always need to check for the name uniqness
    // Updates only need to check when the name has changed
    if (!isUpdate || existingCustomField.name !== value) {
      // Check if name is already in the list
      if (customFields.some(cf => cf.name === value)) {
        return createError({ path, message: errorMessage })
      }
    }

    return true
  })
})

const CUSTOM_FIELD_TYPES_FOR_DROPDOWN = PUBLIC_CUSTOM_FIELD_TYPES.map(key => {
  const Icon = icons[TYPE_ICONS_MAP[key]]
  const searchString = humanize(key.toLowerCase())
  const asHuman = capitalize(searchString)
  return {
    value: key,
    name: (
      <div css={customFieldTypeDropdownItem}>
        {Icon && <Icon className="grui ml-2 mr-8" />}
        {asHuman}
      </div>
    ),
  }
})

const DEFAULT_FORM_STATE = {
  [FIELD_KEY]: null,
  [FIELD_NAME]: '',
  [FIELD_TYPE]: null,
  [FIELD_REQUIRED]: false,
  [FIELD_GLOBAL]: true,
  [FIELD_CHANNEL]: null,
  [FIELD_OPTIONS]: [{ value: 'Option 1' }, { value: 'Option 2' }],
}

export const buildDefaultValues = channelId => {
  const channel = getChannelIdFromKey(channelId)
  const isGlobal = !channel

  return {
    ...DEFAULT_FORM_STATE,
    global: isGlobal,
    channel,
  }
}

export default function CustomFieldCreateEdit({
  drawerId,
  drawerResourceId: inputCustomFieldKey,
  drawerCategoryId: inputCategoryId,
  previousDrawer,
  open,
  onClose,
  onExit,
  queryId,
  ...rest
}) {
  const categoryId = inputCategoryId === 'cfm_global' ? null : inputCategoryId
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const isUpdate = !!inputCustomFieldKey && inputCustomFieldKey !== 'new'
  const {
    isLoading,
    isLoaded,
    customFields,
    reload: reloadCustomFields,
  } = useLoadAllCustomFields()
  const channels = useSelector(selectCurrentChannels)

  const channelsForDropdown = useMemo(
    () => {
      return channels.map(c => ({
        value: buildIdFromAny('Channel', c.id),
        name: c.name,
      }))
    },
    [channels]
  )

  // not needed for creates but cannot have conditional hooks
  // call anyway, it'll just return null
  const existingCustomField = useSelector(state =>
    selectCustomFieldForKey(state, { key: inputCustomFieldKey })
  )

  const isNoResultFound = isUpdate ? !isLoading && !existingCustomField : false

  const defaultValues = useMemo(() => buildDefaultValues(categoryId), [
    categoryId,
  ])

  // keys should preferably match the object being stored in the entity store
  // that way for updates, you can just spread the entity in reset() and not have to set each property individually
  const FORM_SCHEMA = useMemo(
    () =>
      yup.object().shape({
        [FIELD_KEY]: yup.string().nullable(),
        [FIELD_NAME]: yup
          .string()
          .required('Name is required')
          .uniqueName(
            () => ({ isUpdate, customFields, existingCustomField }), // Adjust for your state structure
            'Name must be unique'
          ),
        [FIELD_TYPE]: yup
          .string()
          .nullable()
          .required('Type is required'),
        [FIELD_REQUIRED]: yup.boolean(),
        [FIELD_GLOBAL]: yup.boolean(),
        [FIELD_CHANNEL]: yup
          .string()
          .nullable()
          .when(FIELD_GLOBAL, {
            is: false,
            then: schema => schema.required('Channel is required'),
            otherwise: schema => schema,
          }),
        [FIELD_OPTIONS]: yup
          .array()
          .of(
            yup.object().shape({
              value: yup.string().required('Option value is required'),
            })
          )
          .when(FIELD_TYPE, {
            is: MULTI_SELECT,
            then: schema => schema.min(1, 'At least one option is required'),
            otherwise: schema => schema,
          })
          .when(FIELD_TYPE, {
            is: DROPDOWN,
            then: schema => schema.min(1, 'At least one option is required'),
            otherwise: schema => schema,
          }),
      }),
    [customFields, isUpdate, existingCustomField]
  )

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues,
  })

  const { releaseHold } = useRhfDirtyHold(drawerId, control)

  useEffect(
    () => {
      return () => releaseHold()
    },
    [releaseHold]
  )

  useEffect(
    () => {
      if (isUpdate && open && !isLoading && isLoaded && !!existingCustomField) {
        // load fields with template values
        reset({
          [FIELD_KEY]: stripPrefix(existingCustomField.key),
          [FIELD_NAME]: existingCustomField.name,
          [FIELD_TYPE]: existingCustomField.type,
          [FIELD_REQUIRED]: existingCustomField.required,
          [FIELD_GLOBAL]: isGlobalKey(existingCustomField.key),
          [FIELD_CHANNEL]: getChannelIdFromKey(existingCustomField.key),
          [FIELD_OPTIONS]:
            existingCustomField.options?.map((o, index) => ({
              value: o.value,
              index,
            })) || [],
        })
        // When the drawer gets closed, clear the form
      } else if (!open) {
        reset(DEFAULT_FORM_STATE)
      }
    },
    [existingCustomField, isUpdate, reset, open, isLoading, isLoaded]
  )

  const handleOnClose = useCallback(onClose, [onClose])
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [onExit])

  const {
    [FIELD_TYPE]: typeId,
    [FIELD_GLOBAL]: isGlobal,
    [FIELD_CHANNEL]: channelId,
    [FIELD_OPTIONS]: options,
  } = watch()

  const {
    field: { onChange: handleSelectType },
  } = useController({
    name: FIELD_TYPE,
    control,
  })

  const {
    field: { onChange: handleSelectChannel },
  } = useController({
    name: FIELD_CHANNEL,
    control,
  })

  const {
    field: { onChange: handleOptionsChange },
  } = useController({
    name: FIELD_OPTIONS,
    control,
  })

  const selectedType = useMemo(
    () => CUSTOM_FIELD_TYPES_FOR_DROPDOWN.find(item => item.value === typeId),
    [typeId]
  )

  const selectedChannel = useMemo(
    () => channelsForDropdown.find(item => item.value === channelId),
    [channelsForDropdown, channelId]
  )

  // We use the custom field links on channels to save custom fields for channels.
  // When the global option is selected we just use the first channel.
  const saveChannelId = selectedChannel?.value || channels?.[0]?.id

  const saveChannel = useMemo(
    () => {
      const channel = channels.find(c => c.id === getRawId(saveChannelId))
      if (!channel) return null

      return {
        ...channel,
        customFields: channel.customFields.map(cfi =>
          customFields.find(cf => cf.id === cfi)
        ),
      }
    },
    [saveChannelId, channels, customFields]
  )

  const onSubmit = useCallback(
    async data => {
      setIsSaving(true)
      const key = data.key || generateKey(data.name)

      const channelCustomFields = saveChannel?.customFields.map(cf => ({
        key: stripPrefix(cf.key),
        scope: isGlobalKey(cf.key) ? 'GLOBAL' : 'CHANNEL',
        required: cf.required,
        name: cf.name,
        type: cf.type,
        options:
          cf.options?.map(o => ({
            value: o.value,
            label: o.value,
          })) || [],
        isArray: false,
      }))
      const existingField =
        isUpdate && channelCustomFields.find(cf => cf.key === key)

      const newCustomField = Object.assign(existingField || {}, {
        scope: data.global ? 'GLOBAL' : 'CHANNEL',
        required: data.required,
        name: data.name,
        type: data.type,
        options: data.options?.map(o => ({ label: o.value, value: o.value })),
        isArray: false,
      })

      // If the field already exists the Object.assign updates the value in the array
      // without changing the position, so we only need to push it to the array if it
      // doesnt exist
      if (!existingField) {
        newCustomField.key = key
        channelCustomFields.push(newCustomField)
      }

      await dispatch(
        doUpdateChannel(saveChannel.id, { customFields: channelCustomFields })
      )
      releaseHold()
      if (data.global) {
        await Promise.all([reloadCustomFields(), dispatch(doFetchMailboxes())])
        releaseHold()
      }

      setIsSaving(false)
      onClose()
    },
    [dispatch, onClose, releaseHold, saveChannel, isUpdate, reloadCustomFields]
  )

  const DrawerForm = useCallback(
    props => {
      return <form onSubmit={handleSubmit(onSubmit)} {...props} />
    },
    [handleSubmit, onSubmit]
  )

  const optionError = !!errors?.[FIELD_OPTIONS]

  let saveBtnText
  if (isSaving) {
    saveBtnText = 'Saving...'
  } else if (isUpdate) {
    saveBtnText = 'Save'
  } else {
    saveBtnText = 'Create'
  }

  return (
    <Drawer
      {...rest}
      open={open}
      onClose={handleOnExit}
      title={isUpdate ? 'Edit custom field' : 'New custom field'}
      isLoading={isLoading}
      isNoResultFound={isNoResultFound}
      footer={
        <div>
          <MessageCard type="warning" className="grui m-8">
            Please note that the global and type fields cannot be changed after
            the custom field has been created.
          </MessageCard>
          <ModalBtns
            saveBtnDisabled={isLoading || !isValid || !isDirty || isSaving}
            saveBtnText={saveBtnText}
            // type="submit" will automatically trigger the form submit event when clicked
            saveBtnHtmlType="submit"
            tertiaryBtnText="Cancel"
            tertiaryBtnDisabled={isSaving}
            onClickTertiaryBtn={handleOnClose}
          />
        </div>
      }
      container={DrawerForm}
    >
      <div className="grui pt-12">
        <Field
          {...register(FIELD_NAME)}
          css={textFieldCss}
          label="Name"
          placeholder="Enter custom field name..."
          validateStatus={errors?.[FIELD_NAME] ? 'error' : undefined}
          help={errors?.[FIELD_NAME]?.message}
        />
      </div>
      <div className="grui mt-12">
        <div css={fieldStyles.labelBox}>Type</div>
        <div css={dropdownContainer}>
          <Dropdown
            css={dropdownBtn}
            overlay={<DropdownMenu data={CUSTOM_FIELD_TYPES_FOR_DROPDOWN} />}
            disabled={isUpdate}
            validateStatus={errors?.[FIELD_TYPE] ? 'error' : undefined}
            help={errors?.[FIELD_TYPE]?.message}
            onSelect={handleSelectType}
            selectedKey={typeId}
          >
            <Dropdown.Button>
              {selectedType?.name || '- Please select -'}
            </Dropdown.Button>
          </Dropdown>
        </div>
      </div>
      <div className="grui mt-12">
        <div css={fieldStyles.labelBox}>
          Required
          <QuestionTooltip
            className="grui ml-2"
            tooltip="When enabled prevents responding to conversations until the custom field is filled in."
            variant="v2"
          />
        </div>
        <div>
          <Switch id={FIELD_REQUIRED} {...register(FIELD_REQUIRED)} />
        </div>
      </div>
      <div className="grui mt-12">
        <div css={fieldStyles.labelBox}>
          Global{' '}
          <QuestionTooltip
            className="grui ml-2"
            tooltip={`When enable Custom field will appear on all ${app.t(
              'mailboxes'
            )}.`}
            variant="v2"
          />
        </div>
        <div>
          <Switch
            id={FIELD_GLOBAL}
            disabled={isUpdate}
            {...register(FIELD_GLOBAL)}
          />
        </div>
      </div>
      {!isGlobal && (
        <div className="grui mt-12">
          <div css={fieldStyles.labelBox}>Channel</div>
          <div css={dropdownContainer}>
            <Dropdown
              overlay={<DropdownMenu data={channelsForDropdown} />}
              disabled={isUpdate}
              validateStatus={errors?.[FIELD_CHANNEL] ? 'error' : undefined}
              help={errors?.[FIELD_CHANNEL]?.message}
              onSelect={handleSelectChannel}
              selectedKey={channelId}
            >
              <Dropdown.Button css={dropdownBtn}>
                {selectedChannel?.name || '- Please select -'}
              </Dropdown.Button>
            </Dropdown>
          </div>
        </div>
      )}
      {[DROPDOWN, MULTI_SELECT].includes(typeId) && (
        <div className="grui mt-12">
          <div css={fieldStyles.labelBox}>Options</div>
          <div>
            <ArrayOfStrings
              onChange={handleOptionsChange}
              maxItems={100}
              name="options"
              sortable
              title=""
              items={options}
            />
            {optionError && (
              <Help help="Options invalid" validateStatus="error" />
            )}
          </div>
        </div>
      )}
    </Drawer>
  )
}
