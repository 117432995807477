/* eslint-disable no-param-reassign */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCustomFields } from 'ducks/crm/customFields/selectors/base'
import {
  getChannelIdFromKey,
  isChannelCustomFieldKey,
  isChannelKey,
} from 'ducks/crm/channels/utils'
import { selectCurrentChannelsById } from 'ducks/channels/selectors'
import produce from 'immer'
import { getRawId } from 'util/globalId'
import {
  DATE,
  DROPDOWN,
  MULTI_SELECT,
  NUMBER,
} from 'ducks/crm/customFields/types'
import { FEATURE_INBOX_RULES_TIER_1 } from 'ducks/billing/featureTypes'
import { INBOX } from 'ducks/billing/productTypes'
import { channel as customFieldIcon } from 'assets/icons/groove/v2'
import { selectIsCustomFieldsEnabled } from 'selectors/app/selectIsCustomFieldsEnabled'
import {
  containsDoesNotContainOperators,
  ruleConditions,
  ruleConditionsParemetersSectionTitles,
  numberOperators,
  textAndPartialTextOperators,
} from './data'

export const useRuleVariables = () => {
  const customFields = useSelector(selectCustomFields)
  const channelsById = useSelector(selectCurrentChannelsById)
  const isCustomFieldsEnabled = useSelector(selectIsCustomFieldsEnabled)

  const {
    ruleConditions: ruleConditionsWithCustomFields,
    ruleConditionsParemetersSectionTitles: ruleConditionsWithCustomFieldsParemetersSectionTitles,
  } = useMemo(
    () => {
      if (!isCustomFieldsEnabled)
        return {
          ruleConditions,
          ruleConditionsParemetersSectionTitles,
        }

      return produce(
        {
          ruleConditions,
          ruleConditionsParemetersSectionTitles,
        },
        draft => {
          const maxOrder = Math.max(
            ...Object.values(ruleConditionsParemetersSectionTitles).map(
              ({ order }) => order
            )
          )
          let sectionOrder = maxOrder + 2
          draft.ruleConditions.parameters.customField = []
          customFields.forEach(field => {
            if (!isChannelCustomFieldKey(field.key)) return

            const channelId = getRawId(getChannelIdFromKey(field.key))

            const channelName = channelsById[channelId]?.name
            if (isChannelKey(field.key) && !channelName) return

            if (!draft.ruleConditions.parameters[channelId]) {
              draft.ruleConditions.parameters[channelId] = []
              draft.ruleConditionsParemetersSectionTitles[channelId] = {
                title: channelName || `All ${app.t('mailboxes')}`,
                icon: customFieldIcon,
                order: !channelName ? maxOrder + 1 : sectionOrder,
              }
              sectionOrder += 1
            }
            const value = `CUSTOM_FIELD_${field.key}`

            const parameter = {
              name: field.name,
              param: value,
              product: INBOX,
              feature: FEATURE_INBOX_RULES_TIER_1,
            }

            if ([NUMBER].includes(field.type)) {
              draft.ruleConditions.param_operators[value] = numberOperators
              draft.ruleConditions.values[value] = {
                dataType: 'NUMBER',
              }
            } else if ([DATE].includes(field.type)) {
              draft.ruleConditions.param_operators[value] = numberOperators
              draft.ruleConditions.values[value] = {
                dataType: 'DATE',
              }
            } else if ([DROPDOWN, MULTI_SELECT].includes(field.type)) {
              draft.ruleConditions.param_operators[value] = [
                ...containsDoesNotContainOperators,
              ]
              draft.ruleConditions.values[value] = {
                dataType: 'CUSTOM_FIELDS',
                customFieldKey: field.key,
              }
            } else {
              draft.ruleConditions.param_operators[
                value
              ] = textAndPartialTextOperators
              draft.ruleConditions.values[value] = {
                dataType: 'TEXT',
              }
            }
            draft.ruleConditions.parameters[channelId].push(parameter)
          })
        }
      )
    },
    [customFields, channelsById, isCustomFieldsEnabled]
  )
  return {
    ruleConditionsWithCustomFields,
    ruleConditions,
    ruleConditionsParemetersSectionTitles,
    ruleConditionsWithCustomFieldsParemetersSectionTitles,
  }
}
