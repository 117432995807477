import { css } from '@emotion/react'

export const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

export const dropdownBtn = css`
  min-width: 100%;
`

export const textFieldCss = css`
  max-width: 100%;
`

export const customFieldTypeDropdownItem = css`
  display: flex;
  align-items: center;
`
