import React from 'react'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import useFetchEntitiesByIds from 'ducks/entities/hooks/useFetchEntitiesByIds'
import { doFetchContacts } from 'ducks/crm/contacts'
import { FETCH_CONTACTS } from 'ducks/crm/contacts/types'
import { pluralize } from 'util/strings'
import { useSelector } from 'react-redux'
import { selectSearchByQueryId } from 'ducks/searches/selectors'

const ContactDeleteContent = ({ ids, queryId, deleteMode }) => {
  const { requestState, entities } = useFetchEntitiesByIds(
    ids,
    'contacts',
    doFetchContacts,
    FETCH_CONTACTS
  )

  const entitiesArr = Object.values(entities)
  const search = useSelector(state => selectSearchByQueryId(state, queryId))
  const { totalCount: searchTotal } = search

  const isIdDelete = deleteMode === 'ids'

  if (entitiesArr.length === 0 && requestState.loading) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  if (entitiesArr.length === 0) {
    return <span css={text.styles.fontMedium}>No results...</span>
  }

  if (isIdDelete && ids.length === 1) {
    const entity = entitiesArr[0]
    const conversationCount = entity?.conversationCount || 0

    return (
      <>
        <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
          You are about to delete the customer “<span
            css={text.styles.fontMedium}
          >
            {entity.name || entity.email}
          </span>”.
        </p>
        <p css={[text.styles.textNormal]}>
          This customer is linked to{' '}
          <span css={text.styles.fontMedium}>{conversationCount}</span>{' '}
          {pluralize(conversationCount, 'conversation', 's')}.
        </p>
        <p css={[text.styles.textNormal]}>
          All conversations linked to this customer will be deleted and cannot
          be recovered. If they interact with any of your channels again a new
          customer will be created.
        </p>
        <p css={paragraph.styles.preflight} className="grui mt-14 mb-8">
          Are you sure you want to proceed with this action?
        </p>
      </>
    )
  }

  const totalToBeRemoved = isIdDelete ? ids.length : searchTotal
  const additional = totalToBeRemoved - entitiesArr.length

  return (
    <div css={text.styles.textNormal}>
      You are about to delete:
      {entitiesArr.map(row => (
        <p key={row.id} css={paragraph.styles.preflight}>
          <span css={text.styles.fontMedium}>
            <>“</>
            {row.name || row.email}
            <>”</>
          </span>
        </p>
      ))}
      {additional > 0 && <p>and {additional} more items.</p>}
      <p css={[text.styles.textNormal]}>
        All conversations linked to these customers will be deleted and cannot
        be recovered. If they interact with any of your channels again a new
        customer will be created.
      </p>
    </div>
  )
}

export default ContactDeleteContent
