import {
  tagV2,
  snoozeV2,
  message,
  teammates,
  box,
  notify,
  conversationType,
  conversationStatus,
  recipient,
  interactionCount,
} from 'assets/icons/groove/v2'
import {
  FEATURE_INBOX_RULES,
  FEATURE_INBOX_RULES_TIER_1,
  FEATURE_INBOX_RULES_TIER_2,
} from 'ducks/billing/featureTypes'
import { INBOX } from 'ducks/billing/productTypes'
import {
  OPERATOR_TYPE_CONTAINS,
  OPERATOR_TYPE_DOES_NOT_CONTAIN,
  OPERATOR_TYPE_GT,
  OPERATOR_TYPE_GTEQ,
  OPERATOR_TYPE_LT,
  OPERATOR_TYPE_LTEQ,
} from 'util/operators'
import { generateDeprecatedConditions } from './util'

export const isOperator = [
  {
    name: 'is',
    value: 'IS',
  },
]

export const isOrIsNotOperators = [
  ...isOperator,
  {
    name: 'is not',
    value: 'IS_NOT',
  },
]

export const containsDoesNotContainOperators = [
  {
    name: 'contains',
    value: OPERATOR_TYPE_CONTAINS,
  },
  {
    name: 'does not contain',
    value: OPERATOR_TYPE_DOES_NOT_CONTAIN,
  },
]

export const containsDoesNotContainWordsOperators = [
  {
    name: 'contains full word/phrase',
    value: 'CONTAINS_WORDS',
  },
  {
    name: 'does not contain full word/phrase',
    value: 'DOES_NOT_CONTAIN_WORDS',
  },
]

export const startsWithEndsWithOperators = [
  {
    name: 'starts with',
    value: 'STARTS_WITH',
  },
  {
    name: 'ends with',
    value: 'ENDS_WITH',
  },
]

export const matchesOperator = [
  {
    name: 'matches',
    value: 'MATCHES',
  },
]

export const domainOperators = [
  {
    name: 'domain is',
    value: 'DOMAIN_IS',
  },
  {
    name: 'domain is not',
    value: 'DOMAIN_IS_NOT',
  },
]

export const textAndPartialTextOperators = [
  ...isOrIsNotOperators,
  ...containsDoesNotContainOperators,
  ...containsDoesNotContainWordsOperators,
  ...startsWithEndsWithOperators,
  ...matchesOperator,
]

export const textAndDomainOperators = [
  ...domainOperators,
  ...isOrIsNotOperators,
  ...containsDoesNotContainOperators,
  ...startsWithEndsWithOperators,
  ...matchesOperator,
]

const contactNameOperators = [
  ...isOrIsNotOperators,
  ...containsDoesNotContainOperators,
  ...containsDoesNotContainWordsOperators,
  ...startsWithEndsWithOperators,
]

export const ltGtEqOperators = [
  {
    name: 'less than',
    value: OPERATOR_TYPE_LT,
  },
  {
    name: 'less than or equal',
    value: OPERATOR_TYPE_LTEQ,
  },
  {
    name: 'greater than',
    value: OPERATOR_TYPE_GT,
  },
  {
    name: 'greater than or equal',
    value: OPERATOR_TYPE_GTEQ,
  },
]

export const numberOperators = [...isOrIsNotOperators, ...ltGtEqOperators]

export const ruleConditionsParemetersSectionTitles = {
  conversationStatus: {
    title: 'Update conversation status',
    icon: box,
    order: 0,
  },
  conversationType: {
    title: 'Conversation type',
    icon: conversationType,
    order: 1,
  },
  recipientInfo: {
    title: 'Recipient info',
    icon: recipient,
    order: 2,
  },
  tag: {
    title: 'Tagging',
    icon: tagV2,
    order: 3,
  },
  assignee: {
    title: 'Assignee',
    icon: teammates,
    order: 4,
  },
  interactionCount: {
    title: 'Interaction count',
    icon: interactionCount,
    order: 5,
  },
  message: {
    title: 'Message details',
    icon: message,
    order: 6,
  },
  time: {
    title: 'Time',
    icon: conversationStatus,
    order: 7,
  },
}

// NOTE: make sure to change for_rule_conditions.rb if you change the product/feature for below
// NOTE: make sure to change useAddFeatureUsageIndicator if we changed parameters's structure
export const ruleConditions = {
  parameters: {
    conversationStatus: [
      {
        name: 'Conversation is Open',
        selectedName: 'Conversation is',
        param: 'STATUS',
        operator: OPERATOR_TYPE_CONTAINS,
        value: '1000,2000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is Closed',
        selectedName: 'Conversation is',
        param: 'STATUS',
        operator: OPERATOR_TYPE_CONTAINS,
        value: '5000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is Snoozed',
        selectedName: 'Conversation is',
        param: 'STATUS',
        operator: OPERATOR_TYPE_CONTAINS,
        value: '4000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is in Trash',
        selectedName: 'Conversation is',
        param: 'STATUS',
        operator: OPERATOR_TYPE_CONTAINS,
        value: '7000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation is in Spam`,
        selectedName: 'Conversation is',
        param: 'STATUS',
        operator: OPERATOR_TYPE_CONTAINS,
        value: '6000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is Unread',
        selectedName: 'Conversation is',
        param: 'STATUS',
        operator: OPERATOR_TYPE_CONTAINS,
        value: '1000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      ...generateDeprecatedConditions('PRIORITY', numberOperators),
    ],
    conversationType: [
      {
        name: `Conversation ${app.t('Mailbox')} is one of...`,
        param: 'MAILBOX',
        operator: OPERATOR_TYPE_CONTAINS,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation ${app.t('Mailbox')} is not one of...`,
        param: 'MAILBOX',
        operator: OPERATOR_TYPE_DOES_NOT_CONTAIN,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation source is one of...`,
        param: 'SOURCE',
        operator: OPERATOR_TYPE_CONTAINS,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation source is not one of...`,
        param: 'SOURCE',
        operator: OPERATOR_TYPE_DOES_NOT_CONTAIN,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    recipientInfo: [
      {
        name: 'From field',
        param: 'FROM_EMAIL',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'To field',
        param: 'TO_EMAIL',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'CC field',
        param: 'CC',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Bcc field',
        param: 'BCC',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Contact name',
        param: 'CONTACT_NAME',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    tag: [
      {
        name: 'Has specific Tags…',
        param: 'LABELS',
        operator: OPERATOR_TYPE_CONTAINS,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Does not have specific Tags…',
        param: 'LABELS',
        operator: OPERATOR_TYPE_DOES_NOT_CONTAIN,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has no tags',
        param: 'HAS_LABELS',
        operator: 'IS',
        value: 'false',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has tags',
        param: 'HAS_LABELS',
        operator: 'IS',
        value: 'true',
        deprecated: true,
      },
    ],
    assignee: [
      {
        name: 'Assignee is currently one of…',
        param: 'ASSIGNEE',
        operator: OPERATOR_TYPE_CONTAINS,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Assignee is currently not one of…',
        param: 'ASSIGNEE',
        operator: OPERATOR_TYPE_DOES_NOT_CONTAIN,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is unassigned',
        param: 'ASSIGNED',
        operator: 'IS',
        value: 'false',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is assigned',
        param: 'ASSIGNED',
        operator: 'IS',
        value: 'true',
        deprecated: true,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    interactionCount: [
      {
        name: 'Total message count',
        param: 'COMMENTS_COUNT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Customer reply count',
        param: 'ENDUSER_MESSAGE_COUNT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Agent reply count',
        param: 'AGENT_MESSAGE_COUNT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    message: [
      {
        name: 'Subject',
        param: 'SUBJECT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      ...generateDeprecatedConditions(
        'DESCRIPTION',
        textAndPartialTextOperators
      ),
      ...generateDeprecatedConditions(
        'SUBJECT_OR_DESCRIPTION',
        textAndPartialTextOperators
      ),
      {
        name: 'Message content',
        param: 'MESSAGE_CONTENT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Subject or message content',
        param: 'SUBJECT_OR_MESSAGE_CONTENT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has an attachment',
        param: 'ATTACHMENTS',
        operator: 'IS',
        value: 'true',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has no attachment',
        param: 'ATTACHMENTS',
        operator: 'IS',
        value: 'false',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Last message sender',
        param: 'LAST_MESSAGE_SENDER_TYPE',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    time: [
      {
        name: 'Day of the week',
        param: 'TIME_DAY_OF_WEEK',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Current time',
        param: 'TIME_CURRENT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Current date',
        param: 'DATE_CURRENT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Is in business hours',
        param: 'TIME_BUSINESS_HOURS',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Is customer message unreplied?',
        param: 'ENDUSER_UNREPLIED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Is agent message unreplied?',
        param: 'AGENT_UNREPLIED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
    ],
  },
  param_operators: {
    TO_EMAIL: textAndDomainOperators,
    FROM_EMAIL: textAndDomainOperators,
    CC: textAndDomainOperators,
    BCC: textAndDomainOperators,
    CONTACT_NAME: contactNameOperators,
    COMMENTS_COUNT: numberOperators,
    ENDUSER_MESSAGE_COUNT: numberOperators,
    AGENT_MESSAGE_COUNT: numberOperators,
    SUBJECT: textAndPartialTextOperators,
    MESSAGE_CONTENT: textAndPartialTextOperators,
    SUBJECT_OR_MESSAGE_CONTENT: textAndPartialTextOperators,
    TIME_DAY_OF_WEEK: isOrIsNotOperators,
    TIME_CURRENT: ltGtEqOperators,
    TIME_BUSINESS_HOURS: isOrIsNotOperators,
    DATE_CURRENT: numberOperators,
    ENDUSER_UNREPLIED: isOrIsNotOperators,
    AGENT_UNREPLIED: isOrIsNotOperators,
    LAST_MESSAGE_SENDER_TYPE: isOrIsNotOperators,
  },
  values: {
    MAILBOX: {
      dataType: 'MAILBOXES',
    },
    STATUS: {
      dataType: 'STATUSES',
    },
    FROM_EMAIL: {
      dataType: 'TEXT',
    },
    TO_EMAIL: {
      dataType: 'TEXT',
    },
    CC: {
      dataType: 'TEXT',
    },
    BCC: {
      dataType: 'TEXT',
    },
    SUBJECT: {
      dataType: 'TEXT',
    },
    DESCRIPTION: {
      dataType: 'TEXTAREA',
    },
    MESSAGE_CONTENT: {
      dataType: 'TEXTAREA',
    },
    SUBJECT_OR_DESCRIPTION: {
      dataType: 'TEXTAREA',
    },
    SUBJECT_OR_MESSAGE_CONTENT: {
      dataType: 'TEXTAREA',
    },
    ADD_NOTE: {
      dataType: 'TEXTAREA',
    },
    CONTACT_NAME: {
      dataType: 'TEXT',
    },
    LABELS: { dataType: 'TAGS', paramType: 'names' },
    TAG: { dataType: 'TAG', paramType: 'name' },
    TAGS: { dataType: 'TAGS', paramType: 'names' },
    PRIORITY: [
      {
        name: 'Low',
        value: 'low',
      },
      {
        name: 'Medium',
        value: 'medium',
      },
      {
        name: 'High',
        value: 'high',
      },
      {
        name: 'Urgent',
        value: 'urgent',
      },
    ],
    SOURCE: { dataType: 'TICKETSOURCES' },
    ASSIGNEE: { dataType: 'TEAMMATES' },
    COMMENTS_COUNT: {
      dataType: 'NUMBER',
    },
    ENDUSER_MESSAGE_COUNT: {
      dataType: 'NUMBER',
    },
    AGENT_MESSAGE_COUNT: {
      dataType: 'NUMBER',
    },
    TIME_DAY_OF_WEEK: [
      {
        name: 'Monday',
        value: '1',
      },
      {
        name: 'Tuesday',
        value: '2',
      },
      {
        name: 'Wednesday',
        value: '3',
      },
      {
        name: 'Thursday',
        value: '4',
      },
      {
        name: 'Friday',
        value: '5',
      },
      {
        name: 'Saturday',
        value: '6',
      },
      {
        name: 'Sunday',
        value: '0',
      },
    ],
    TIME_CURRENT: { dataType: 'TIME' },
    TIME_BUSINESS_HOURS: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    DATE_CURRENT: { dataType: 'DATE' },
    ENDUSER_UNREPLIED: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    AGENT_UNREPLIED: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    LAST_MESSAGE_SENDER_TYPE: [
      {
        name: 'Agent',
        value: 'agent',
      },
      {
        name: 'Customer',
        value: 'enduser',
      },
    ],
  },
}

export const ruleActionsParemetersSectionTitles = {
  conversationStatus: {
    title: 'Update conversation status',
    icon: box,
  },
  snooze: {
    title: 'Snooze',
    icon: snoozeV2,
  },
  teammate: {
    title: app.t('Agents'),
    icon: teammates,
  },
  tag: {
    title: 'Tagging',
    icon: tagV2,
  },
  message: {
    title: 'Message actions',
    icon: message,
  },
  notification: {
    title: 'Notify',
    icon: notify,
  },
}

const ruleActions = isPaidAccount => {
  const parameters = {
    conversationStatus: [
      {
        name: 'Close conversation',
        type: 'STATUS',
        value: 'closed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Reopen conversation',
        type: 'STATUS',
        value: 'opened',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Move conversation to Trash',
        type: 'STATUS',
        value: 'trash',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Move conversation to Spam',
        type: 'STATUS',
        value: 'spam',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Set Priority as',
        type: 'PRIORITY',
        deprecated: true,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    snooze: [
      {
        name: 'Snooze conversation',
        type: 'SNOOZE_UNTIL',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Unsnooze conversation',
        type: 'UNSNOOZE',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    teammate: [
      {
        name: `Assign to ${app.t('agent')}`,
        type: 'ASSIGNEE_ID',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Assign to available ${app.t('agent')}`,
        type: 'NEXT_AVAILABLE_AGENT',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Assign to ${app.t('group')}`,
        type: 'ASSIGNEE_GROUP_ID',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Assign to available ${app.t('group')}`,
        type: 'NEXT_AVAILABLE_AGENT_IN_GROUP',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Unassign conversation',
        type: 'UNASSIGN',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    tag: [
      {
        name: 'Add tag',
        type: 'LABEL',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
        deprecated: true,
      },
      {
        name: 'Add tags',
        type: 'LABELS',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Remove tag',
        type: 'REMOVE_LABEL',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Clear tags',
        type: 'REMOVE_ALL_LABELS',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    message: [
      {
        name: `Move ${app.t('mailbox')}`,
        type: 'MAILBOX',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Reply to message',
        type: 'SEND_EMAIL_REPLY',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Forward conversation',
        type: 'FORWARD_CONVERSATION',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Add a star',
        type: 'STAR',
        value: '4000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Remove star',
        type: 'STAR',
        value: '1000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Post a note',
        type: 'ADD_NOTE',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Replace subject',
        type: 'SUBJECT',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    notification: [
      {
        name: 'Send in-app notification to...',
        type: 'SEND_IN_APP_NOTIFICATION_TO_AGENTS',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Add followers to conversation',
        type: 'ADD_AGENTS_AS_FOLLOWERS',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
  }

  const values = {
    PRIORITY: {
      dataType: 'LIST',
      options: [
        {
          name: 'Low',
          value: '1000',
        },
        {
          name: 'Medium',
          value: '2000',
        },
        {
          name: 'High',
          value: '3000',
        },
        {
          name: 'Urgent',
          value: '4000',
        },
      ],
    },
    LABEL: { dataType: 'TAG', paramType: 'name' },
    LABELS: { dataType: 'TAGS', paramType: 'ids' },
    REMOVE_LABEL: { dataType: 'TAG', paramType: 'id' },
    REMOVE_ALL_LABELS: { dataType: null },
    NEXT_AVAILABLE_AGENT: { dataType: null },
    UNASSIGN: { dataType: null },
    CHANNEL: {
      dataType: 'LIST',
      options: [
        {
          name: 'Widget',
          value: 'Widget',
        },
        {
          name: 'Facebook',
          value: 'Facebook',
        },
        {
          name: 'Twitter',
          value: 'Twitter',
        },
        {
          name: 'Chat',
          value: 'Chat',
        },
        {
          name: 'Email',
          value: 'Email',
        },
      ],
    },
    SNOOZE_UNTIL: {
      dataType: 'LIST',
      options: [
        {
          name: '4 hours',
          value: '14400',
        },
        {
          name: '2 days',
          value: '172800',
        },
        {
          name: '4 days',
          value: '345600',
        },
        {
          name: '1 week',
          value: '604800',
        },
        {
          name: '2 weeks',
          value: '1209600',
        },
        {
          name: '1 month',
          value: '2592000',
        },
        {
          name: 'Indefinitely',
          value: 'indefinitely',
        },
      ],
    },
    UNSNOOZE: { dataType: null },
    SUBJECT: { dataType: 'SUBJECT' },
    MAILBOX: { dataType: 'LIST', valueType: 'Mailbox' },
    ASSIGNEE_ID: { dataType: 'LIST', valueType: 'Agent' },
    ASSIGNEE_GROUP_ID: { dataType: 'LIST', valueType: 'Group' },
    NEXT_AVAILABLE_AGENT_IN_GROUP: { dataType: 'LIST', valueType: 'Group' },
    ADD_AGENTS_AS_FOLLOWERS: { dataType: 'TEAMMATES', dataOperator: 'and' },
    SEND_EMAIL_TO_AGENTS: {
      dataType: 'MESSAGE_TEMPLATE',
      dataOperator: 'and',
      toAgentDataType: 'TEAMMATES',
      allowSelectAssigned: true,
    },
    SEND_IN_APP_NOTIFICATION_TO_AGENTS: {
      dataType: 'TEXT',
      label: 'Notification message',
      dataOperator: 'and',
      toAgentDataType: 'TEAMMATES',
      allowSelectAssigned: true,
    },
    SEND_EMAIL_TO_REQUESTER: {
      dataType: 'MESSAGE_TEMPLATE',
    },
    SEND_EMAIL_REPLY: {
      dataType: 'MESSAGE_TEMPLATE',
      toAgentDataType: 'REPLY_AGENT',
    },
    ADD_NOTE: { dataType: 'NOTE', toAgentDataType: 'REPLY_AGENT' },
    FORWARD_CONVERSATION: { dataType: 'EMAIL' },
    TIME_CURRENT: { dataType: 'TIME' },
    DATE_CURRENT: { dataType: 'DATE' },
  }

  if (isPaidAccount) {
    parameters.message.push({
      name: 'Send a new email to the customer',
      type: 'SEND_EMAIL_TO_REQUESTER',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_1,
    })

    parameters.notification.unshift({
      name: 'Send email notification to...',
      type: 'SEND_EMAIL_TO_AGENTS',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_1,
    })
  }

  return {
    parameters,
    values,
  }
}

export const ruleActionsForPaid = {
  ...ruleActions(true),
}

export const ruleActionsForUnpaid = {
  ...ruleActions(false),
}

export const ruleTriggersParemetersSectionTitles = {
  message: {
    title: 'Message triggers',
    icon: message,
  },
  conversationStatus: {
    title: 'State changes',
    icon: box,
  },
  teammate: {
    title: 'Teammates',
    icon: teammates,
  },
  snooze: {
    title: 'Snooze',
    icon: snoozeV2,
  },
  tag: {
    title: 'Tags',
    icon: tagV2,
  },
}

// NOTE: make sure to change for_rule_triggers.rb if you change the product/feature for below
export const ruleTriggers = {
  parameters: {
    message: [
      {
        name: 'New conversation is received',
        value: 'CREATED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Inbound message received',
        value: 'NEW_INBOUND_MESSAGE',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Outbound message sent',
        value: 'NEW_OUTBOUND_MESSAGE',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `New conversation is created by ${app.t('agent')}`,
        value: 'CREATED_BY_AGENT',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Conversation moves to ${app.t('mailbox')}`,
        value: 'MAILBOX_CHANGED',
        inputType: 'mailbox',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is rated',
        value: 'CONVERSATION_RATED',
        inputType: 'rating',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Custom field is changed',
        value: 'CUSTOM_FIELD_CHANGED',
        inputType: 'customfields',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Any update occurs on a conversation',
        value: 'UPDATED',
        tooltip:
          'This will trigger the rule when anything happens on the conversation.',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    conversationStatus: [
      {
        name: 'Conversation changes to Closed',
        value: 'CONVERSATION_CLOSED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is Opened/Reopened',
        value: 'CONVERSATION_OPENED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is sent to Trash',
        value: 'CONVERSATION_TRASHED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is sent to Spam',
        value: 'CONVERSATION_SPAMMED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is restored from Trash',
        value: 'CONVERSATION_UNTRASHED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    teammate: [
      {
        name: `${app.t('Agent')} is mentioned`,
        value: 'AGENT_MENTIONED',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `${app.t('Agent')} is assigned`,
        value: 'AGENT_ASSIGNED',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `${app.t('Agent')} is unassigned`,
        value: 'AGENT_UNASSIGNED',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'New note is added by...',
        value: 'NEW_NOTE',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    snooze: [
      {
        name: 'Conversation Snoozed',
        value: 'CONVERSATION_SNOOZED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation Snooze expires',
        value: 'CONVERSATION_AUTOMATIC_UNSNOOZED',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    tag: [
      {
        name: 'Tag is added',
        value: 'LABEL_ADDED',
        inputType: 'tag',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Tag is removed',
        value: 'LABEL_REMOVED',
        inputType: 'tag',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
  },
}

export const ruleTriggersBySettingInputNeeded = Object.fromEntries(
  Object.values(ruleTriggers.parameters)
    .flat(1)
    .filter(obj => !['CREATED_BY_AGENT', 'MAILBOX_CHANGED'].includes(obj.value))
    .map(obj => [obj.value, obj.inputType !== undefined])
)

export const ruleTriggersRequireConditions = [
  'CREATED',
  'UPDATED',
  'NEW_INBOUND_MESSAGE',
  'NEW_OUTBOUND_MESSAGE',
  'NEW_NOTE',
]
