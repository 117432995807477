import { css } from '@emotion/react'

const messageCard = css`
  & > div {
    width: 100%;
  }
`

const container = css`
  grid-auto-flow: row;
  grid-template-columns: 80% auto;
`

export const styles = {
  container,
  messageCard,
}
