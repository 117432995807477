import { selectContactsByEmail } from 'ducks/crm/contacts/selectors/base'
import { doSearchRecipients } from 'ducks/drafts2/operations'

export const doFetchRecipients = currentPart => (dispatch, getState) => {
  const contactsByEmail = selectContactsByEmail(getState())
  if (!currentPart) return
  const { operator, value } = currentPart
  if (
    (operator === 'from' || operator === 'keywords') &&
    !contactsByEmail[value]
  ) {
    dispatch(doSearchRecipients(value))
  }
}
