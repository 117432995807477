import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const label = theme => css`
  font-size: ${rem(19)};
  color: ${theme.color.info['900']};
  font-weight: ${theme.fontWeight.medium};
`

const switchButton = css`
  right: ${rem(24)};
  position: absolute;
`

const arrowDown = theme => css`
  top: 4px;
  display: flex;
  position: relative;
  color: ${theme.color.monochrome.dark};
`

export const styles = {
  switchButton,
  arrowDown,
  label,
}
