import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'

import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import HeaderWithImage from '@groovehq/internal-design-system/lib/components/HeaderWithImage/HeaderWithImage'
import { useChannel } from 'ducks/channels/hooks'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import {
  doBuildInboxMenuFromMailboxes,
  doBuildMenuFromWidgets,
  doRedirectToCollectionAndFolderById,
} from 'ducks/folders/operations/collections'
import {
  doOpenEditChannelPage,
  doOpenEditWidgetPage,
} from 'subapps/settings/actions/redirect'
import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import { styles } from './styles'
import { drawerConfig } from '../Channels.data'

const { DEFAULT_DRAWER_TITLE } = drawerConfig

const CreationFeedback = ({
  onExit,
  drawerChannelType: channelType,
  drawerResourceId: channelId,
  drawerSetupIncomplete: setupIncomplete,
  drawerInstructionsSent: instructionsSent,
  drawerInstructionsEmail: instructionsEmail,
}) => {
  const dispatch = useDispatch()
  const { channel, isLoading, isMissing } = useChannel(channelId)

  useEffect(
    () => {
      if (isMissing) onExit()
    },
    [isMissing, onExit]
  )

  const { email, folders } = channel || {}

  const handleRebuildCloseAndRedirect = useCallback(
    () => {
      if (isBridgeChannelType(channelType)) {
        dispatch(doBuildMenuFromWidgets({ autoRedirect: false }))
        onExit()
        dispatch(
          doRedirectToCollectionAndFolderById(channelId, null, {
            ignoreLast: true,
          })
        )
      } else {
        dispatch(doBuildInboxMenuFromMailboxes())
        onExit()
        dispatch(
          doRedirectToCollectionAndFolderById(
            channelId,
            folders ? folders[0].id : null
          )
        )
      }
    },
    [dispatch, onExit, channelId, folders, channelType]
  )

  const handleCloseAndRedirectToSettings = useCallback(
    () => {
      dispatch(doBuildInboxMenuFromMailboxes())
      if (isBridgeChannelType(channelType)) {
        dispatch(doOpenEditWidgetPage(channelId))
      } else {
        dispatch(doOpenEditChannelPage(channelId))
      }
      onExit()
    },
    [dispatch, onExit, channelId, channelType]
  )

  const wordingType = isBridgeChannelType(channelType) ? 'channel' : 'inbox'

  const footer = (
    <ModalBtns tertiaryBtnText="Close" onClickTertiaryBtn={onExit} />
  )

  const intro = useMemo(
    () => {
      if (setupIncomplete) {
        return `Your ${app.t(
          'mailbox'
        )} is created, but will need to have forwarding setup before it starts receiving emails.`
      } else if (instructionsSent) {
        return (
          <p css={paragraph.styles.preflight}>
            Great news! We&apos;ve sent the setup instructions to &quot;{
              instructionsEmail
            }&quot; with all the details to setup the email forwarding!
            <br />
            <br />
            As soon as this is done, your email will arrive right in your
            Groove&nbsp;
            {app.t('mailbox')}.
          </p>
        )
      } else if (channelType === 'facebook') {
        return `All new Messenger conversations for the configured pages will now
  be available for you in Groove.`
      } else if (channelType === 'instagram') {
        return `All new Instagram Messenger conversations for the configured accounts will now
  be available for you in Groove.`
      }
      if (channelType === CHANNEL_TYPE.FORWARDING) {
        return `Great news! We&apos;ve verified that your ${app.t(
          'mailbox'
        )} "${email}" is working as expected!`
      }

      return `Great news!, Your ${app.t(
        'mailbox'
      )} for "${email}" is created, and all new emails appear here.`
    },
    [channelType, email, setupIncomplete, instructionsSent, instructionsEmail]
  )
  const title = useMemo(
    () => {
      if (setupIncomplete) {
        return `Your ${app.t(
          'mailbox'
        )} has been created, but setup still needs to be finalised.`
      }
      if (instructionsSent) {
        return 'Forwarding instructions sent!'
      }
      return "You're all set!"
    },
    [setupIncomplete, instructionsSent]
  )

  return (
    <Drawer
      title={DEFAULT_DRAWER_TITLE}
      isLoading={isLoading}
      footer={footer}
      onClose={onExit}
    >
      <HeaderWithImage
        className="grui mt-14 mb-12"
        css={styles.header}
        title={title}
        intro={intro}
      />
      {instructionsSent && (
        <MessageCard type="warning" className="grui mt-11">
          <div css={text.styles.fontMedium}>Just a reminder</div>
          <p css={paragraph.styles.preflight} className="grui my-5">
            You will not receive emails to this email address in Groove until
            email forwarding is setup.
          </p>
        </MessageCard>
      )}
      <div css={text.styles.textNormal} className="grui mt-12">
        <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
          What&apos;s next:
        </p>
        <Button
          type="primary"
          onClick={handleRebuildCloseAndRedirect}
          as="a"
          css={styles.fullWidth}
          className="grui mt-10 mb-5"
        >
          Take me to my new {wordingType}
        </Button>
        <Button
          type="tertiary"
          onClick={handleCloseAndRedirectToSettings}
          as="a"
          css={styles.fullWidth}
        >
          Take me to more settings
        </Button>
      </div>
    </Drawer>
  )
}

export default CreationFeedback
