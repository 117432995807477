import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool } from 'prop-types'
import { doPermanentlyDeleteCustomField } from 'ducks/crm/customFields/operations/deleting'
import { doFetchMailboxes } from 'ducks/mailboxes/actions'
import { useCustomField } from 'ducks/crm/customFields/hooks'
import { getChannelIdFromKey } from 'ducks/crm/channels/utils'
import EntityDeleteDrawer from '../EntityDelete'
import DeleteCustomFieldContent from './Content'

const CustomFieldDelete = props => {
  const {
    title,
    deleteBtnText,
    open,
    drawerResourceId: customFieldKey,
    drawerCategoryId: customFieldCategoryId,
    onClose,
    onExit,
    onRemove,
  } = props

  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const { customField, isLoading } = useCustomField(customFieldKey, {
    mode: 'key',
  })
  const channelId = getChannelIdFromKey(customFieldCategoryId)

  const handleOnRemove = useCallback(
    async () => {
      if (customField) {
        setIsDeleting(true)
        await dispatch(doPermanentlyDeleteCustomField(customField.id))
        await dispatch(doFetchMailboxes())
        if (onRemove) onRemove()
      }
    },
    [dispatch, onRemove, customField]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      hardDelete
      onRemove={handleOnRemove}
      onClose={onClose}
      tertiaryBtnText="Cancel"
      onCancel={onExit}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
      isLoading={isLoading || isDeleting}
    >
      <DeleteCustomFieldContent
        channelId={channelId}
        customFieldKey={customFieldKey}
      />
    </EntityDeleteDrawer>
  )
}

CustomFieldDelete.propTypes = {
  title: string,
  deleteBtnText: string,
  open: bool.isRequired,
  drawerResourceId: string,
}

CustomFieldDelete.defaultProps = {
  title: `Delete custom field`,
  deleteBtnText: 'Delete',
  drawerResourceId: '',
  queryId: null,
}

export default CustomFieldDelete
