import React, { useEffect, useState } from 'react'
import { isBlank } from 'util/strings'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import DemoInboxCard from 'components/TrialSetup/DemoInboxCard'
import OnboardingWorkflowCard from 'components/TrialSetup/OnboardingWorkflowCard'
import TicketConversationList from 'subapps/ticketing/components/TicketList/TicketConversationList'
import TicketListHeader from 'subapps/ticketing/components/TicketList/Header'
import { selectShowDemoInboxCard } from 'selectors/trial_setup'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { selectShowOnboardingWorkflowInConversationList } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflowInConversationList'
import { doFetchFolderCounts } from 'ducks/searches/operations/doFetchFolderCounts'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import usePrevious from 'util/hooks/usePrevious'
import { doFetchTickets } from 'ducks/tickets/actions'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { doRedirectToCollectionAndFolderById } from 'ducks/folders/operations/collections'
import {
  selectIsOnSearchPageType,
  selectIsOnSearchPageWithLegacySearch,
} from 'ducks/location/selectors'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectPreviousQueryId } from 'ducks/searches/selectors/selectPreviousQueryId'
import { selectPreviousQuery } from 'ducks/searches/selectors/selectPreviousQuery'
import { selectFetchFolderCountsRequestForChannelId } from 'ducks/searches/selectors/selectFetchFolderCountsRequestForChannelId'
import { selectSearchIsLoadedByQueryId } from 'ducks/searches/selectors'

import styles from './styles.less'
import LimitSearchHistoryCard from '../LimitSearchHistoryCard'

// Wraps the ticket list container and adds mouseEnter/Leave events
const TicketList = ({ channelType }) => {
  const dispatch = useDispatch()
  const currentQueryId = useSelector(selectCurrentQueryId)
  const is3ColumnView = useSelector(selectPrefersClassicView)
  const prevStateCurrentQueryId = useSelector(selectPreviousQueryId)
  const isPreviousQueryLoaded = useSelector(state =>
    selectSearchIsLoadedByQueryId(state, prevStateCurrentQueryId)
  )

  const prevCurrentQueryId =
    usePrevious(currentQueryId) ||
    (isPreviousQueryLoaded ? prevStateCurrentQueryId : null)
  const { channel = [] } = useSelector(selectCurrentQuery)
  const { channel: previousChannel = [] } = useSelector(selectPreviousQuery)
  const channelId = channel[0]
  const prevChannelId = usePrevious(channelId) || previousChannel[0]
  const isOnSearchPage = useSelector(selectIsOnSearchPageType)
  const { loaded: stateHasLoadedCounts } = useSelector(state =>
    selectFetchFolderCountsRequestForChannelId(state, channelId)
  )
  const [hasLoadedCounts, setHasLoadedCounts] = useState(stateHasLoadedCounts)

  const showDemoInboxCard = useSelector(selectShowDemoInboxCard)
  const showOnboardingWorkflow = useSelector(
    selectShowOnboardingWorkflowInConversationList
  )
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const isOnSearchPageWithLegacySearch = useSelector(
    selectIsOnSearchPageWithLegacySearch
  )
  const currentTicketId = useSelector(selectCurrentTicketId)

  // Kevin R (2025-02-14)
  // This use effect is a little complicated and there is allot of pieces that goes into determining
  // what it needs to do.

  // When you navigate to a folder we ALWAYS want to
  // 1. Load the first page of conversations
  // 2. Load the counts
  // 3. We DO NOT want to reload the conversations and counts when navigating between conversations
  //    In 3 column view this is fairly simple as the TicketList component never gets unmounted.
  //    We can simply track the current and previous queryIds and has counts loaded state inside
  //    the component. 3 Column view is where things gets completed as the TicketList gets unmounted
  //    when we navigate to a ticket. Then when we land back on the ticket list page we dont "know"
  //    where we previously were. This is addressed by tracking the previousQueryId inside the redux
  //    state and enduring that the "previous" values has a default value of the redux state values.
  //    This prevents reloading tickets and counts when its not required.

  //    The reason why this is really important is when a customer has auto advance enabled on 3
  //    column view. Taking a open/close action causes an redirect to occur optimistically. If we
  //    then load the conversations, there is a fairly high probability that the conversation and
  //    counts returned will not reflect the updated values of the operation that has just occured.
  useEffect(
    () => {
      if (!isBlank(currentQueryId) && currentQueryId !== prevCurrentQueryId) {
        dispatch(
          doFetchTickets({
            isReload: false,
            loadFirst: true,
            allowOpenFirst: true,
          })
        )
      } else if (
        isBlank(currentQueryId) &&
        isOnSearchPage &&
        // We have a directy implemented in the doBuildInboxMenuFromMailboxes which will
        // convert and redirect to valid search url. The problem is that this legacy search
        // doesnt cause the currentQueryId to be set. We need to wait for the redirection code
        // to do its thing and after that we should redirect away if the query id is blank.
        !isOnSearchPageWithLegacySearch
      ) {
        dispatch(
          doRedirectToCollectionAndFolderById(null, null, {
            ignoreLast: true,
            preservePage: isOnSearchPage && currentTicketId,
          })
        )
      }

      // We always need  to load the global non channel search
      // If the request below wont load it, do it here
      if (!hasLoadedCounts && channelId) {
        dispatch(doFetchFolderCounts({ channelType }))
      }

      if (!hasLoadedCounts || prevChannelId !== channelId) {
        if (!hasLoadedCounts) setHasLoadedCounts(true)
        dispatch(doFetchFolderCounts({ channelId, channelType }))
      }
    },
    [
      dispatch,
      currentQueryId,
      prevCurrentQueryId,
      prevChannelId,
      channelId,
      channelType,
      hasLoadedCounts,
      isOnSearchPage,
      isOnSearchPageWithLegacySearch,
      currentTicketId,
    ]
  )

  return (
    <div className={styles.leftCell}>
      <TicketListHeader isSidebarOpen isWide={is3ColumnView} />
      {isManager && (
        <>
          <LimitSearchHistoryCard
            className={cn('grui mx-10 my-5', {
              'pt-10': !is3ColumnView,
            })}
          />
          {showOnboardingWorkflow && (
            <OnboardingWorkflowCard
              mini={!is3ColumnView}
              className={cn({
                'grui mb-14 mt-14': is3ColumnView,
              })}
            />
          )}
        </>
      )}
      <TicketConversationList channelType={channelType} />
      {!showOnboardingWorkflow &&
        showDemoInboxCard && <DemoInboxCard mini={!is3ColumnView} />}
    </div>
  )
}

export default TicketList
