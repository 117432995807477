import React from 'react'
import * as icons from 'assets/icons/groove/v2'
import { TYPE_ICONS_MAP } from 'ducks/crm/customFields'
import { camelize } from 'util/strings'

const FormLabel = ({ icon, name, type }) => {
  const iconKey = camelize(icon)
  const Icon = icons[iconKey || TYPE_ICONS_MAP[type] || 'edit']

  return (
    <>
      {Icon && <Icon className="grui" />}
      &nbsp;{name}
    </>
  )
}

export default FormLabel
