import React, { useState, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { func, shape, string } from 'prop-types'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'

import { selectGroupsSortedByName } from 'selectors/app/groups'
import { selectMailboxesIncludingInaccessible } from 'ducks/mailboxes/selectors/selectMailboxesIncludingInaccessible'
import { useWatch } from 'react-hook-form'
import { omit } from 'util/objects'
import { propFunc } from 'util/functions'
import { selectCurrentPaidActiveAgentsWithCurrentUserSort } from 'ducks/agents/selectors'

import RuleValue from '../RuleValue'
import DropdownMenu from '../DropdownMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  useSearchItems,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import AutomationItem from '../AutomationItem'
import { styles as automationItemStyles } from '../AutomationItem/styles'
import { styles } from './Condition.styles'
import {
  buildPossibleValueOptions,
  selectValue,
  getConditionKey,
  selectParameter,
  isWrapAllowed,
} from './util'

const ConditionWithSearch = ({
  variables,
  onRemoveCondition,
  conditionErrorMessage,
  onBlur,
  index,
  onConditionChange,
  decodeIds = false,
  control,
  name,
  placeholder,
  conditionMatchTypeName,
  parameterSectionTitles,
  className,
  itemKey,
  tooltipForAdvancedFeature,
}) => {
  const condition = useWatch({
    control,
    name,
  })

  const conditionMatchType = useWatch({
    control,
    name: conditionMatchTypeName,
  })
  const isAnyCondition = String(conditionMatchType).toLowerCase() === 'any'

  const {
    param: conditionParam,
    operator: conditionOperator,
    value: conditionValue,
  } =
    condition || {}

  const {
    parameters: allParameters,
    param_operators: paramOperators,
    values,
  } = variables
  const paramOperator = useMemo(() => paramOperators[conditionParam] || [], [
    paramOperators,
    conditionParam,
  ])
  const agents = useSelector(selectCurrentPaidActiveAgentsWithCurrentUserSort)
  const groups = useSelector(selectGroupsSortedByName)
  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)
  const dataType = values[conditionParam]?.dataType || 'AUTO'
  const paramType = values[conditionParam]?.paramType
  const wrapAllowed = isWrapAllowed(dataType)

  const [initialConditionParam] = useState(conditionParam)
  const [initialOperator] = useState(conditionOperator)
  const [filteredParameters, setFilteredParameters] = useState({})
  const [search, setSearch] = useState('')
  const [parameterMenuVisible, setParameterMenuVisibleChange] = useState(
    !!condition && !conditionParam
  )
  const [isAutomationItemFocused, setIsAutomationItemFocused] = useState(false)
  const searchRef = useRef()
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    parameterMenuVisible
  )

  const possibleOptionsForParameterKey = useCallback(
    (parameterKey, operator) =>
      buildPossibleValueOptions(
        values,
        parameterKey,
        agents,
        groups,
        mailboxes,
        {
          decodeIds,
          operator,
        }
      ),
    [agents, groups, mailboxes, decodeIds, values]
  )

  const possibleOptionsArray = useMemo(
    () => possibleOptionsForParameterKey(conditionParam, conditionOperator),
    [conditionParam, conditionOperator, possibleOptionsForParameterKey]
  )

  const parameters = useMemo(
    () => {
      return Object.keys(allParameters).reduce((accumulator, key) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = allParameters[key]
          .filter(
            p =>
              !p.deprecated ||
              (p.param === initialConditionParam &&
                p.operator === initialOperator)
          )
          .map(p => omit(['deprecated'], p))
        return accumulator
      }, {})
    },
    [allParameters, initialConditionParam, initialOperator]
  )

  useSearchItems({
    data: parameters,
    search,
    onSearchResultChange: setFilteredParameters,
  })

  const selectedParameter = useMemo(
    () =>
      selectParameter(
        allParameters,
        conditionParam,
        conditionOperator,
        conditionValue
      ),
    [conditionParam, allParameters, conditionOperator, conditionValue]
  )

  const selectedOperator = useMemo(
    () => selectValue(paramOperator, conditionOperator),
    [paramOperator, conditionOperator]
  )

  const selectedValue = useMemo(
    () => {
      return Array.isArray(possibleOptionsArray)
        ? selectValue(possibleOptionsArray, conditionValue)
        : null
    },
    [possibleOptionsArray, conditionValue]
  )

  const handleBlur = useCallback(
    () => {
      onBlur(index)
    },
    [onBlur, index]
  )

  // Handle select parameter
  const handleSelectParameter = useCallback(
    key => {
      const [param, operator, conditionVal] = key.split('-')
      setParameterMenuVisibleChange(false)

      const newParamOperator = paramOperators[param] || []
      const newValue = values[param] || ''
      let lastValue = conditionVal || (newValue[0] ? newValue[0].value : '')

      const newPossibleOptionsArray = possibleOptionsForParameterKey(param)

      if (Array.isArray(newPossibleOptionsArray)) {
        lastValue = newPossibleOptionsArray[0].value
      }

      // Pass select values to condition
      onConditionChange(
        {
          ...condition,
          param,
          operator:
            operator || (newParamOperator[0] && newParamOperator[0].value),
          value: lastValue,
        },
        index
      )
    },
    [
      onConditionChange,
      paramOperators,
      values,
      index,
      condition,
      possibleOptionsForParameterKey,
    ]
  )

  // Handle select param operator
  const handleSelectParamOperatorKey = useCallback(
    key => {
      onConditionChange(
        {
          ...condition,
          operator: key,
        },
        index
      )
    },
    [onConditionChange, index, condition]
  )

  // Handle select value
  const handleSelectValueKey = useCallback(
    key => {
      onConditionChange(
        {
          ...condition,
          value: key,
        },
        index
      )
    },
    [onConditionChange, index, condition]
  )

  const handleRemove = useCallback(
    () => {
      onRemoveCondition(condition, index)
    },
    [onRemoveCondition, index, condition]
  )

  const handleInput = useCallback(
    e => {
      const newValue = e.target.value
      onConditionChange(
        {
          ...condition,
          value: newValue,
        },
        index,
        false
      )
    },
    [onConditionChange, index, condition]
  )

  const handleChangeSearch = useCallback(({ target }) => {
    setSearch(target.value)
  }, [])

  const handleParametersMenuVisibleChange = useCallback(
    visible => {
      if (!visible && search) {
        setSearch('')
      }
      setParameterMenuVisibleChange(visible)
    },
    [search]
  )

  const handleOpenParameterMenu = useCallback(
    () => {
      if (!parameterMenuVisible) {
        setParameterMenuVisibleChange(true)
      }
    },
    [parameterMenuVisible]
  )

  const conditionIcon = useMemo(
    () => {
      const conditionSectionKey = Object.keys(variables.parameters).find(
        sectionKey => {
          return variables.parameters[sectionKey].some(
            item => item.param === conditionParam
          )
        }
      )
      return parameterSectionTitles[conditionSectionKey]?.icon
    },
    [conditionParam, variables, parameterSectionTitles]
  )

  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder="Search conditions..."
        value={search}
        onChange={handleChangeSearch}
        ref={searchRef}
        shouldFocus={parameterMenuVisible}
      />
    ),
    [search, handleChangeSearch, parameterMenuVisible]
  )

  const parametersMenu = useMemo(
    () => (
      <MenuWithSearch
        focusElementRef={searchRef}
        search={search}
        className="grui pt-3 pb-8"
        onScroll={handleScroll}
      >
        {Object.keys(filteredParameters)
          ?.filter(key => filteredParameters[key]?.length)
          .sort((aKey, bKey) => {
            const orderDiff =
              parameterSectionTitles[aKey]?.order -
              parameterSectionTitles[bKey]?.order

            // If the orders are equal, sort by name
            if (orderDiff === 0) {
              return filteredParameters[aKey]?.name.localeCompare(
                filteredParameters[bKey]?.name
              )
            }

            return orderDiff
          })
          .map(key => {
            const { title, icon } = parameterSectionTitles[key] || {}
            return (
              <div key={key}>
                <MenuWithSearch.Header icon={icon}>
                  {title}
                </MenuWithSearch.Header>
                {filteredParameters[key]?.map(
                  ({
                    value: conditionVal,
                    param,
                    operator,
                    name: conditionName,
                    tooltip,
                    feature,
                    product,
                  }) => {
                    return (
                      <MenuWithSearch.Item
                        key={getConditionKey(param, operator, conditionVal)}
                        itemKey={getConditionKey(param, operator, conditionVal)}
                        tooltip={tooltip}
                        onSelect={handleSelectParameter}
                        selectedKey={getConditionKey(
                          conditionParam,
                          operator && conditionOperator,
                          conditionVal && conditionValue
                        )}
                        visible={parameterMenuVisible}
                        feature={feature}
                        product={product}
                        checkProductFeatureLimit
                        type="condition"
                      >
                        {conditionName}
                      </MenuWithSearch.Item>
                    )
                  }
                )}
              </div>
            )
          })}
      </MenuWithSearch>
    ),
    [
      filteredParameters,
      parameterSectionTitles,
      handleSelectParameter,
      parameterMenuVisible,
      search,
      handleScroll,
      conditionParam,
      conditionOperator,
      conditionValue,
    ]
  )

  return (
    <div className={className}>
      <AutomationItem
        icon={conditionIcon}
        onDelete={handleRemove}
        shouldWrapInnerItems={wrapAllowed}
        onClick={handleOpenParameterMenu}
        onBlur={propFunc(setIsAutomationItemFocused, false)}
        onFocus={propFunc(setIsAutomationItemFocused, true)}
        isError={!!conditionErrorMessage}
      >
        <div css={styles.dropdownContainer}>
          <Dropdown
            header={header}
            overlay={parametersMenu}
            css={[
              menuWithSearchStyles.dropdownContainer,
              shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
            ]}
            onVisibleChange={handleParametersMenuVisibleChange}
            visible={parameterMenuVisible}
            autoHeight
            // Don't let dropdown's arrow event handlers handle the keyboard events
            // MenuWithSearch will do it
            isNavByArrowsDisabled
          >
            <span>
              {selectedParameter || placeholder || 'Select a filter...'}
            </span>
          </Dropdown>
        </div>
        {conditionParam &&
          paramOperators[conditionParam] && (
            <div css={styles.dropdownContainer}>
              <Dropdown
                overlay={
                  <DropdownMenu
                    data={paramOperator}
                    checkProductFeatureLimit
                    tooltipForAdvancedFeature={tooltipForAdvancedFeature}
                  />
                }
                onSelect={handleSelectParamOperatorKey}
                selectedKey={conditionOperator}
                hasMinWidth
              >
                <Dropdown.Button size="small" css={styles.dropdownBtn}>
                  {selectedOperator || <>&nbsp;</>}
                </Dropdown.Button>
              </Dropdown>
            </div>
          )}
        {conditionOperator &&
          values[conditionParam] && (
            <RuleValue
              value={possibleOptionsArray}
              onDropdownSelect={handleSelectValueKey}
              selectedValue={selectedValue}
              onInputBlur={handleBlur}
              onInputChange={handleInput}
              valueKey={conditionValue}
              dataType={dataType}
              paramType={paramType}
              errorMessage={conditionErrorMessage}
              itemKey={itemKey}
              hasMinWidth
              isAutomationItemFocused={isAutomationItemFocused}
              // Remount this component if conditionParam is changed
              // then autoFocus will be working for new inputs
              key={conditionParam}
            />
          )}
      </AutomationItem>
      <div css={[automationItemStyles.operator]}>
        {isAnyCondition ? 'OR' : 'AND'}
      </div>
    </div>
  )
}

ConditionWithSearch.propTypes = {
  variables: shape({}),
  onRemoveCondition: func,
  onBlur: func,
  onConditionChange: func,
  conditionErrorMessage: string,
  conditionMatchTypeName: string,
  className: string,
  tooltipForAdvancedFeature: string,
}

ConditionWithSearch.defaultProps = {
  variables: {},
  onRemoveCondition() {},
  onBlur() {},
  onConditionChange() {},
  conditionErrorMessage: null,
  conditionMatchTypeName: 'matchType',
  className: undefined,
  tooltipForAdvancedFeature: undefined,
}

export default React.memo(ConditionWithSearch)
