import React, { useEffect, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { HEX_REGEX } from 'util/colors'
import { useConfirmHoldsCallback, useRhfDirtyHold } from 'util/dirtyHolds'
import useFetchEntityById from 'ducks/entities/hooks/useFetchEntityById'
import { doFetchTagsV2ByIds, doCreateUpdateTagV2 } from 'ducks/tags/actions'
import { FETCH_TAGS_BY_IDS_NAMES } from 'ducks/tags/actionTypes'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { SETTINGS_TAG_TABLE_ID } from 'ducks/tables/ids'
import { doShowTagsAssignModal } from 'actions/modals'
import { selectQueryParamsQueryId } from 'selectors/location'
import TagCreateEditContent from './Content'

const FORM_SCHEMA = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Tag name is required'),
  color: yup
    .string()
    .trim()
    .uppercase()
    .matches(HEX_REGEX)
    .required('Tag color is required'),
})

function TagCreateEditDrawer({
  drawerResourceId: tagId,
  previousDrawer,
  entityType,
  open,
  onClose,
  onExit,
  drawerId,
  drawerAddAsSelected: addAsSelected,
  drawerOpenTagsAssignModalOnExit: openTagsAssignModalOnExit,
  // optional additionalProp: pre-fill name form field
  initialTagName,
  onCreated,
  ...rest
}) {
  const isPartOfWizard = !!previousDrawer

  const { requestState, entity: tag } = useFetchEntityById(
    tagId,
    'tag',
    doFetchTagsV2ByIds,
    FETCH_TAGS_BY_IDS_NAMES
  )
  const dispatch = useDispatch()
  const methods = useForm({
    mode: 'onChange',
    // Default color value should be lower case,
    // because color from ColorPicker is lower case
    defaultValues: { name: '', color: null },
    resolver: yupResolver(FORM_SCHEMA),
    delayError: 300,
  })
  const {
    handleSubmit,
    reset,
    setValue,
    setFocus,
    control,
    formState: { hasError, isValid, isDirty, isSubmitting },
  } = methods
  const isUpdate = tagId && tagId !== 'new'
  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)
  // If access this drawer without through TagDataTable, need to fetch tags after saving for empty screen
  // in the TagsPage
  const shouldFetchTags = !useSelector(state =>
    selectQueryParamsQueryId(state, {
      targetId: SETTINGS_TAG_TABLE_ID,
    })
  )

  useEffect(
    () => {
      if (!open) return
      setFocus('name')
    },
    [open, setFocus, tag]
  )

  useEffect(
    () => {
      if (!open) return

      if (isUpdate && tag) {
        reset({
          name: initialTagName || tag.name,
          color: tag.color.toLowerCase(),
        })
      } else if (initialTagName) {
        setValue('name', initialTagName, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
      if (!isUpdate) {
        reset({
          color: '#58a2fb',
        })
      }
    },
    [reset, tag, initialTagName, setValue, open, isUpdate]
  )

  const handleOnExit = useConfirmHoldsCallback(null, onExit, [onExit])

  const handleOnClose = useConfirmHoldsCallback(
    holdKey,
    closeParams => {
      onClose(closeParams)

      if (!isPartOfWizard && openTagsAssignModalOnExit) {
        // open tags assignment modal
        dispatch(doShowTagsAssignModal())
      }
    },
    [dispatch, isPartOfWizard, openTagsAssignModalOnExit, onClose]
  )

  const onSubmit = useCallback(
    async fields => {
      const saveResponsePromise = dispatch(
        doCreateUpdateTagV2(tagId, fields, { addAsSelected, shouldFetchTags })
      )

      let closeParams = {}

      if (!isUpdate) {
        // because this is needed for a previous drawer, we need to wait for response from API before closing drawer
        const {
          tagCreate: {
            tag: { id: createdTagId },
          },
        } = await saveResponsePromise

        if (createdTagId) {
          if (onCreated) {
            onCreated(createdTagId)
          }
          if (previousDrawer?.drawerId) {
            closeParams = {
              ...closeParams,
              ...{
                query: {
                  ...buildDrawerQueryParam(
                    previousDrawer.drawerId,
                    'drawerTagId',
                    createdTagId
                  ),
                },
              },
            }
          }
        }
      }

      releaseHold()
      handleOnClose(closeParams)
    },
    [
      dispatch,
      tagId,
      previousDrawer?.drawerId,
      addAsSelected,
      handleOnClose,
      releaseHold,
      isUpdate,
      shouldFetchTags,
      onCreated,
    ]
  )

  const DrawerForm = useCallback(
    props => <form onSubmit={handleSubmit(onSubmit)} {...props} />,
    [handleSubmit, onSubmit]
  )

  const disableSave =
    requestState.loading || isSubmitting || hasError || !isValid || !isDirty

  return (
    <FormProvider {...methods}>
      <Drawer
        {...rest}
        open={open}
        onClose={handleOnExit}
        size="narrow"
        title={isUpdate ? 'Edit tag' : 'New tag'}
        isLoading={requestState.loading}
        isError={requestState.error}
        isNoResultFound={isUpdate && requestState.loaded && !tag}
        footer={
          <ModalBtns
            saveBtnDisabled={disableSave}
            saveBtnText={isUpdate ? 'Save' : 'Create'}
            saveBtnHtmlType="submit"
            tertiaryBtnText="Cancel"
            onClickTertiaryBtn={handleOnClose}
          />
        }
        container={DrawerForm}
      >
        {(!isUpdate || !!tag) && <TagCreateEditContent onSubmit={onSubmit} />}
      </Drawer>
    </FormProvider>
  )
}

TagCreateEditDrawer.propTypes = {
  drawerAddAsSelected: PropTypes.bool,
  drawerOpenTagsAssignModalOnExit: PropTypes.bool,
}

TagCreateEditDrawer.defaultProps = {
  drawerAddAsSelected: false,
  drawerOpenTagsAssignModalOnExit: false,
}

export default TagCreateEditDrawer
