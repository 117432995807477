import { USERS_RANGES } from '../UserRestrictionTypeDropdown'

export const users = []

export const userRanges = [
  {
    key: USERS_RANGES.EVERYONE,
    title: 'Everyone',
  },
  {
    key: USERS_RANGES.AGENTS,
    title: `Specific ${app.t('agents')}`,
  },
  {
    key: USERS_RANGES.TEAMS,
    title: `Specific ${app.t('teams')}`,
  },
  {
    key: USERS_RANGES.ME,
    title: 'Only me',
  },
]
