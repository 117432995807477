import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectMailboxesIncludingInaccessible } from 'ducks/mailboxes/selectors/selectMailboxesIncludingInaccessible'
import usePrevious from 'util/hooks/usePrevious'
import { emptyArr } from 'util/arrays'
import { isFunction } from 'util/functions'
import { Footer, styles, useMultiSelect } from '../MultiSelectMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'

const MailboxesDropdown = ({
  value: inputValue,
  setValue,
  selectedIds = emptyArr,
  onSelectedIdsChange,
  list,
  children,
  menuOffset,
  onVisibleChange,
  className,
}) => {
  const searchRef = useRef()
  const value = inputValue || emptyArr
  const mailboxesIncludingInaccessible = useSelector(
    selectMailboxesIncludingInaccessible
  )
  const [menuVisible, setMenuVisible] = useState(false)
  const [filteredList, setFilteredList] = useState([])
  const [search, setSearch] = useState('')
  const {
    indeterminate,
    allChecked,
    handleCheckAllChange,
    handleToggleItem,
  } = useMultiSelect(filteredList, onSelectedIdsChange, selectedIds)
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )

  const mailboxes = useMemo(
    () => {
      if (list) return list
      return mailboxesIncludingInaccessible
    },
    [list, mailboxesIncludingInaccessible]
  )

  const handleMenuVisibleChange = useCallback(
    visible => {
      setMenuVisible(visible)
      if (isFunction(onVisibleChange)) {
        onVisibleChange(visible)
      }
    },
    [onVisibleChange]
  )

  const handleSelect = useCallback(
    () => {
      setValue(selectedIds)
      handleMenuVisibleChange(false)
    },
    [setValue, selectedIds, handleMenuVisibleChange]
  )

  const handleCancel = useCallback(
    () => {
      handleMenuVisibleChange(false)
    },
    [handleMenuVisibleChange]
  )

  const handleChangeSearch = useCallback(
    ({ target }) => {
      setSearch(target.value)
      if (target.value?.trim()) {
        const newList = mailboxes.filter(({ name }) =>
          name.toLowerCase().includes(target.value?.trim().toLowerCase())
        )
        setFilteredList(newList)
        onSelectedIdsChange(
          selectedIds.filter(id => newList.some(item => item.id === id))
        )
      } else {
        setFilteredList(mailboxes)
      }
    },
    [mailboxes, onSelectedIdsChange, selectedIds]
  )

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch
          search={search}
          onScroll={handleScroll}
          focusElementRef={searchRef}
          onApply={handleSelect}
        >
          {!!filteredList.length && (
            <Checkbox
              id="all-mailboxes"
              indeterminate={indeterminate}
              checked={allChecked}
              css={text.styles.fontMedium}
              gap={16}
              onChange={handleCheckAllChange}
              className="grui px-10 pb-5"
            >
              All {app.t('mailboxes')}
            </Checkbox>
          )}
          {divider}
          <div className="grui pt-3 pb-5">
            {filteredList.map(({ id, name }) => (
              <MenuWithSearch.Item
                key={id}
                itemKey={id}
                onSelect={handleToggleItem}
              >
                <Checkbox
                  css={styles.checkbox}
                  truncate
                  id={id}
                  checked={selectedIds.some(itemId => itemId === id)}
                  gap={16}
                >
                  {name}
                </Checkbox>
              </MenuWithSearch.Item>
            ))}
          </div>
        </MenuWithSearch>
      )
    },
    [
      filteredList,
      indeterminate,
      allChecked,
      selectedIds,
      handleToggleItem,
      handleCheckAllChange,
      search,
      handleScroll,
      handleSelect,
    ]
  )
  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder={`Search ${app.t('mailboxes')}…`}
        onChange={handleChangeSearch}
        value={search}
        ref={searchRef}
        shouldFocus={menuVisible}
      />
    ),
    [handleChangeSearch, search, menuVisible]
  )

  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        saveBtnTitle={`Select ${app.t('mailboxes')}`}
      />
    ),
    [handleSelect, handleCancel]
  )

  const selectedNames = useMemo(
    () => {
      if (mailboxes.length && mailboxes.length === value.length) {
        return `All ${app.t('mailboxes')}`
      }
      const valueArray = Array.isArray(value) ? value : [value]
      return valueArray
        .map(id => mailboxes.find(mailbox => mailbox.id === id)?.name)
        .filter(name => name)
        .join(', ')
    },
    [mailboxes, value]
  )

  useEffect(
    () => {
      if (!search?.trim()) {
        setFilteredList(mailboxes)
      }
    },
    [search, mailboxes]
  )

  const previousMenuVisible = usePrevious(menuVisible)
  useEffect(
    () => {
      if (previousMenuVisible !== menuVisible && !menuVisible) {
        onSelectedIdsChange(value)
        setSearch('')
      }
    },
    // Including onSelectedIdsChange causes circular renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [previousMenuVisible, menuVisible, value]
  )

  return (
    <Dropdown
      overlay={menu}
      header={header}
      footer={footer}
      css={[
        text.styles.textSm,
        styles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      className={className}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
      offset={menuOffset}
      isNavByArrowsDisabled
    >
      {children || (
        <Dropdown.Button css={styles.dropdownBtn} size="small">
          {value.length ? selectedNames : `Select ${app.t('mailboxes')}`}
        </Dropdown.Button>
      )}
    </Dropdown>
  )
}

export default MailboxesDropdown
