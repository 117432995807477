import { Workbox } from 'workbox-window'
import debug from 'util/debug'
import { refreshOnNextPage } from 'util/refreshMiddleware'
import { doRealtimeRoomEvent } from 'ducks/chat/actions/rooms'

const AUTO_UPDATE_MS = 5 * 60 * 1000 // 5 min

const ACTION_MAP = {
  PUSH_EVENT: handlePushEvent,
}

function handlePushEvent(action) {
  const { payload } = action
  if (payload.type === 'g.sync.searches.notifications') {
    const { unread, last_updated_at: createdAt } = payload.content || {}
    if (Object.keys(unread).length > 0) {
      app.store.dispatch(
        doRealtimeRoomEvent({ data: { unread, createdAt } }, true)
      )
    }
  }
}

export function setupServiceWorker() {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js')

    // NOTE (jscheel): We are manually controlling the update process here,
    // instead of relying on workbox, as workbox has some inconsistencies in their
    // implementation of detecting updates. The current process is as follow:

    // 1. Register the service worker
    // 2. Check if a new service worker is waiting to be activated and activate it by posting SKIP_WAITING
    // 3. Start listening to messages from the service worker
    // 4. Start listening for service worker udpates
    // 5. If an updated service worker is found, install it but do not activate immediately
    // 6. If a previous service worker is found after the install, refresh on next page navigation
    // 7. Automatically check for updates to the service worker every n minutes

    wb.register().then(registration => {
      if (registration) {
        if (registration.waiting) {
          registration.waiting.postMessage({
            type: 'SKIP_WAITING',
            action: 'SKIP_WAITING', // deprecated field for old clients running offline-plugin
          })
        }

        registration.addEventListener('updatefound', () => {
          debug('Service worker update detected!')
          if (registration.installing) {
            registration.installing.addEventListener('statechange', () => {
              if (registration.waiting) {
                if (navigator.serviceWorker.controller) {
                  debug('Requesting sw update on next page')
                  refreshOnNextPage()
                }
              }
            })
          }
        })
      }
    })

    // NOTE (jscheel): We don't use workbox's event listening here because workbox
    // waits until the client (this window) is controlled. This doesn't happen
    // until the page is refreshed, which we cannot wait for when setting up push
    // notifications. We broadcast to uncontrolled clients as well, to make sure
    // that the messages get out asap.
    navigator.serviceWorker.addEventListener('message', event => {
      const action = event.data?.action
      if (action) {
        const handler = ACTION_MAP[action.type]
        if (handler) handler(action)
      }
    })

    setInterval(async () => {
      try {
        await wb.update()
      } catch (error) {
        // Kevin R: 2022-02-03
        // This appears to be a transient error that can happen while the service worker
        // is being installed. Simply put, we cannot do anything to fix this error
        // other than waiting for the installation to complete.
        // Should resolve https://app.bugsnag.com/groove/frontend/errors/60e709e13e68d50007bee453
        // https://www.w3.org/TR/service-workers/
        // If newestWorker is null, return a promise rejected with an "InvalidStateError" DOMException and abort these steps.
        if (
          error instanceof DOMException &&
          error.code === DOMException.INVALID_STATE_ERR
        ) {
          // eslint-disable-next-line no-console
          console.error(
            'setupServiceWorker::update: Temporary service worker error',
            error
          )
        } else {
          throw error
        }
      }
    }, AUTO_UPDATE_MS)
  }
}
