import {
  GLOBAL_MAILBOX_CUSTOM_FIELD_PREFIX,
  MAILBOX_CUSTOM_FIELD_PREFIX,
} from 'ducks/crm/channels/constants'

export function generateKey(name) {
  return name
    .toLowerCase() // Convert the string to lowercase
    .replace(/[^\w\s]/g, '') // Remove all special characters (anything that's not a letter, number, or underscore)
    .trim() // Remove leading and trailing spaces
    .replace(/\s+/g, '_') // Replace spaces with underscores (snake_case)
}

export function stripPrefix(key) {
  if (key.startsWith(MAILBOX_CUSTOM_FIELD_PREFIX)) {
    return key
      .split('_')
      .splice(2)
      .join('_')
  } else if (key.startsWith(GLOBAL_MAILBOX_CUSTOM_FIELD_PREFIX)) {
    return key
      .split('_')
      .splice(1)
      .join('_')
  }
  return key
}
