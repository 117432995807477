import React, { useMemo } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { propFunc } from 'util/functions'
import { AGENT_ROLE_ADMIN, AGENT_ROLE_OWNER } from 'ducks/agents/constants'
import CellWithAgentAvatar from '../CellWithAgentAvatar'

const { CellWithButton, RowSelection, RowControls } = Table

const useColumns = ({ currentUser } = {}) => {
  const columns = useMemo(
    () => {
      const currentUserIsManager = [
        AGENT_ROLE_ADMIN,
        AGENT_ROLE_OWNER,
      ].includes(currentUser.role)
      const formatName = ({
        row: { original },
        value,
        onEdit,
        selectedFlatRows,
      }) => {
        return (
          <CellWithButton
            onClick={propFunc(onEdit, original.id)}
            disabled={selectedFlatRows.length > 0}
          >
            {value}
          </CellWithButton>
        )
      }

      const formatCreator = ({ value: creatorId }) => {
        return <CellWithAgentAvatar agentId={creatorId} />
      }

      const createNameWithCheckbox = props => {
        return (
          <div className="grui flex items-center">
            <RowSelection {...props} className="grui mr-10" />
            {formatName(props)}
          </div>
        )
      }

      const showTimes = ({ value }) => {
        if (value === 0) {
          return (
            <div css={[text.styles.textMediumDark, text.styles.italic]}>
              Never
            </div>
          )
        }
        return Number(value).toLocaleString()
      }

      const formatDate = ({ value, selectedFlatRows }) => {
        let component = null
        const controlsDisabled = selectedFlatRows.length > 0
        if (!value) {
          component = (
            <div css={[text.styles.textMediumDark, text.styles.italic]}>
              Never used
            </div>
          )
        } else {
          component = (
            <time dateTime={moment(value).format('MM-DD-YYYY')}>
              {moment(value).format('DD MMM YYYY')}
            </time>
          )
        }

        return (
          <>
            <div
              className={cn('components', {
                'controls-disabled': controlsDisabled,
              })}
            >
              {component}
            </div>
          </>
        )
      }

      const formatCategory = ({ value }) => {
        if (!value) {
          return (
            <span css={[text.styles.textMediumDark, text.styles.italic]}>
              Uncategorized
            </span>
          )
        }
        return value
      }

      const createRowControls = ({
        value,
        deleteData,
        onEdit,
        selectedFlatRows,
      }) => (
        <RowControls
          value={value}
          deleteData={currentUserIsManager && deleteData}
          onEdit={onEdit}
          disabled={selectedFlatRows.length > 0}
        />
      )

      return [
        {
          Header: 'NAME',
          accessor: 'name',
          id: 'name',
          Cell: createNameWithCheckbox,
          width: 305,
          manualSortBy: true,
        },
        {
          Header: 'CATEGORY',
          accessor: 'category.name',
          Cell: formatCategory,
          id: 'category',
          width: 200,
          truncate: true,
          manualSortBy: true,
        },
        {
          Header: 'CREATOR',
          accessor: 'creator_id',
          Cell: formatCreator,
          id: 'creator',
          width: '19%',
          manualSortBy: true,
        },
        {
          Header: 'USED #',
          id: 'used',
          accessor({ count_used: countUsed, last_used_at: lastUsedAt }) {
            if (lastUsedAt === 0) {
              return lastUsedAt
            }
            return countUsed
          },
          Cell: showTimes,
          width: 98,
          manualSortBy: true,
        },
        {
          Header: 'LAST USED',
          id: 'last_used',
          accessor: 'last_used_at',
          Cell: formatDate,
          width: '12%',
          truncate: true,
          manualSortBy: true,
          className: 'row-controls',
        },
        {
          Header: '',
          accessor: 'id',
          Cell: createRowControls,
          disableSortBy: true,
        },
      ].filter(Boolean)
    },
    [currentUser?.role]
  )

  return columns
}

export default useColumns
