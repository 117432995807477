import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import { doUpdateChannel } from 'ducks/channels/actions'
import {
  useConfirmHoldsCallback,
  releaseHold,
  retainHold,
} from 'util/dirtyHolds'
import { doVerifyMailboxIMAP } from 'ducks/mailboxes/actions'
import ChannelDetailsForm from 'subapps/settings/components/shared/forms/ChannelDetails'
import { useChannel } from 'ducks/channels/hooks'
import {
  CHANNEL_TYPE,
  EMAIL_SERVER_AUTH_SSL,
  EMAIL_SERVER_IS_UPDATE_PASSWORD,
  EMAIL_SERVER_LOGIN_EMAIL,
  EMAIL_SERVER_LOGIN_PASSWORD,
  IMAP_SERVER,
  SMTP_SERVER,
} from 'ducks/mailboxes/constants'
import { selectCurrentPage } from 'selectors/location'
import { SETTINGS_INBOX_MANAGEMENT_PAGE } from 'subapps/settings/types'
import { useAdminAccess } from '../../NoAccess/hooks'
import ActionsWithMessage from '../ChannelDetails/ActionsWithMessage'
import { styles } from '../ChannelDetails/styles'
import { oAuthErrorToErrorCode } from '../ChannelDetails/oAuthError'

const AuthenticateIMAPChannel = ({
  onClose,
  onExit,
  drawerChannelType: channelType = CHANNEL_TYPE.IMAP,
  drawerResourceId: channelId,
  drawerId,
}) => {
  const isInInboxSettings =
    useSelector(selectCurrentPage) === SETTINGS_INBOX_MANAGEMENT_PAGE
  const dispatch = useDispatch()
  const { channel, isMissing } = useChannel(channelId, {
    // The full channel is fetching or fetched if in inbox settings
    // We need to use cached channel data to avoid fetch the channel multiple times if refreshing the current drawer link
    useCachedIfAvailable: isInInboxSettings,
  })
  const isLoading = !channel || !Object.hasOwn(channel, IMAP_SERVER)
  const [errorCode, setErrorCode] = useState(null)
  const [isOAuthSccessful, setIsOAuthSccessful] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const timeoutRef = useRef()
  useAdminAccess(onClose)

  const formId = `${drawerId}-form`

  const handleOnClose = useConfirmHoldsCallback(formId, onClose, [onClose])

  const handleError = useCallback(
    error => {
      setErrorCode(oAuthErrorToErrorCode(error, channelType))
      // eslint-disable-next-line no-console
      console.error(error)
    },
    [channelType]
  )

  const onSubmit = useCallback(
    async data => {
      try {
        setIsSaving(true)
        setErrorCode(null)
        const inComingEmailServer = data[IMAP_SERVER]
        delete inComingEmailServer[EMAIL_SERVER_IS_UPDATE_PASSWORD]
        if (!inComingEmailServer[EMAIL_SERVER_LOGIN_PASSWORD]) {
          delete inComingEmailServer[EMAIL_SERVER_LOGIN_PASSWORD]
        }
        // eslint-disable-next-line no-param-reassign
        data[SMTP_SERVER] = {
          ...data[SMTP_SERVER],
          [EMAIL_SERVER_AUTH_SSL]: inComingEmailServer[EMAIL_SERVER_AUTH_SSL],
          [EMAIL_SERVER_LOGIN_EMAIL]:
            inComingEmailServer[EMAIL_SERVER_LOGIN_EMAIL],
          [EMAIL_SERVER_LOGIN_PASSWORD]:
            inComingEmailServer[EMAIL_SERVER_LOGIN_PASSWORD],
        }
        await dispatch(doVerifyMailboxIMAP(channelId, data))
        await dispatch(
          doUpdateChannel(channelId, data, {
            channelType,
            rebuildMenu: false,
          })
        )
        setIsOAuthSccessful(true)
        releaseHold(formId)
        setIsSaving(false)
        timeoutRef.current = setTimeout(() => {
          onExit()
          timeoutRef.current = null
        }, 1000)
      } catch (error) {
        setIsSaving(false)
        retainHold(formId)
        handleError(error)
      }
    },
    [dispatch, channelId, channelType, formId, handleError, onExit]
  )

  const actionsComponentProps = useMemo(
    () => {
      return {
        formId,
        onClose: handleOnClose,
        isInstalling: isSaving,
        channelType,
        errorCode,
        isOAuthSccessful,
        channelId,
        onExit,
        firstStep: true,
      }
    },
    [
      formId,
      handleOnClose,
      isSaving,
      channelType,
      errorCode,
      channelId,
      isOAuthSccessful,
      onExit,
    ]
  )

  const footerRef = useRef(null)

  useEffect(
    () => {
      if (isMissing) {
        onExit()
      }
    },
    [isMissing, onExit]
  )

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Modal
      dataTestId={`channels-details-${channelType}}`}
      portal="#drawer-root"
      open
      onClose={handleOnClose}
      backdropTransparency="light"
      className="grui mt-18"
      isLoading={isLoading}
    >
      <Modal.Title className="grui pt-12">
        Reconnect your IMAP account
      </Modal.Title>
      <Modal.Description>
        {`Occasionally your email account may require re-authentication if you has updated your security settings or changed your password.`}
      </Modal.Description>
      <div css={styles.content} className="grui pt-5 pb-6">
        <ChannelDetailsForm
          formId={formId}
          channelId={channelId}
          channelType="imap"
          onSubmit={onSubmit}
          fullWidth
          actionsPortalRef={footerRef}
          actionsComponent={ActionsWithMessage}
          actionsComponentAdditionalProps={actionsComponentProps}
          inDrawer
          shouldHideGeneralFields
        />
        <div ref={footerRef} className="grui mt-18 text-center" />
      </div>
    </Modal>
  )
}

export default AuthenticateIMAPChannel
