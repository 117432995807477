import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { doDeleteContacts } from 'ducks/crm/contacts'
import EntityDeleteDrawer from 'subapps/settings/components/drawers/EntityDelete'
import { MAX_BULK_CONTACTS_DELETE_SIZE } from 'ducks/crm/contacts/constants'
import ContactDeleteContent from './Content'

const ContactDeleteDrawer = props => {
  const {
    queryId,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    onExit,
    onClose,
    onRemove,
  } = props

  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      const resourceIds = drawerResourceId ? drawerResourceId.split(',') : []

      if (resourceIds.length > MAX_BULK_CONTACTS_DELETE_SIZE) {
        return resourceIds.slice(0, MAX_BULK_CONTACTS_DELETE_SIZE)
      }

      return resourceIds
    },
    [drawerResourceId]
  )

  const handleOnRemove = useCallback(
    () => {
      const response = dispatch(
        doDeleteContacts(ids, {
          queryId,
          mode: drawerDeleteMode,
        })
      )
      if (onRemove) onRemove()

      return response
    },
    [dispatch, ids, queryId, drawerDeleteMode, onRemove]
  )

  const title =
    drawerDeleteMode === 'query' || ids.length > 1
      ? 'Delete customers'
      : 'Delete customer'

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={onExit}
      tertiaryBtnText="Cancel"
      onCancel={onClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
      hardDelete
    >
      <ContactDeleteContent ids={ids} deleteMode={drawerDeleteMode} />
    </EntityDeleteDrawer>
  )
}

export default ContactDeleteDrawer
