import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import usePrevious from 'util/hooks/usePrevious'
import { emptyArr } from 'util/arrays'
import { isFunction } from 'util/functions'
import { useLoadAllCustomFields } from 'ducks/crm/customFields/hooks'
import {
  getChannelIdFromKey,
  isChannelCustomFieldKey,
  isChannelKey,
} from 'ducks/crm/channels/utils'
import { useSelector } from 'react-redux'
import { selectCurrentChannelsById } from 'ducks/channels/selectors'
import { getRawId } from 'util/globalId'
import { Footer, styles, useMultiSelect } from '../MultiSelectMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'

const CustomFieldsDropdown = ({
  value: inputValue,
  setValue,
  selectedIds = emptyArr,
  onSelectedIdsChange,
  children,
  menuOffset,
  onVisibleChange,
  className,
}) => {
  const { customFields: allCustomFields = emptyArr } = useLoadAllCustomFields()
  const channelsById = useSelector(selectCurrentChannelsById)

  const options = useMemo(
    () => {
      return allCustomFields
        .filter(({ key }) => isChannelCustomFieldKey(key))
        .map(cf => {
          const channel = channelsById[getRawId(getChannelIdFromKey(cf.key))]
          if (!channel && isChannelKey(cf.key)) return null
          return {
            label: `${channel?.name || `All ${app.t('mailboxes')}`}: ${
              cf.name
            }`,
            value: cf.key,
          }
        })
        .filter(x => !!x)
    },
    [allCustomFields, channelsById]
  )

  const searchRef = useRef()
  const value = inputValue || emptyArr
  const [menuVisible, setMenuVisible] = useState(false)
  const [filteredList, setFilteredList] = useState([])
  const [search, setSearch] = useState('')
  const { handleToggleItem } = useMultiSelect(
    filteredList,
    onSelectedIdsChange,
    selectedIds
  )
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )

  const handleMenuVisibleChange = useCallback(
    visible => {
      setMenuVisible(visible)
      if (isFunction(onVisibleChange)) {
        onVisibleChange(visible)
      }
    },
    [onVisibleChange]
  )

  const handleSelect = useCallback(
    () => {
      setValue(selectedIds)
      handleMenuVisibleChange(false)
    },
    [setValue, selectedIds, handleMenuVisibleChange]
  )

  const handleCancel = useCallback(
    () => {
      handleMenuVisibleChange(false)
    },
    [handleMenuVisibleChange]
  )

  const handleChangeSearch = useCallback(
    ({ target }) => {
      setSearch(target.value)
      if (target.value?.trim()) {
        const newList = options.filter(({ value: optionValue, label }) => {
          const normalized = target.value?.trim().toLowerCase()
          return (
            label.toLowerCase().includes(normalized) ||
            optionValue.toLowerCase().includes(normalized)
          )
        })
        setFilteredList(newList)
        onSelectedIdsChange(
          selectedIds.filter(optionValue =>
            newList.some(option => option.value === optionValue)
          )
        )
      } else {
        setFilteredList(options)
      }
    },
    [options, onSelectedIdsChange, selectedIds]
  )

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch
          search={search}
          onScroll={handleScroll}
          focusElementRef={searchRef}
          onApply={handleSelect}
        >
          {divider}
          <div className="grui pt-3 pb-5">
            {filteredList.map(({ label, value: optionValue }) => (
              <MenuWithSearch.Item
                key={optionValue}
                itemKey={optionValue}
                onSelect={handleToggleItem}
              >
                <Checkbox
                  css={styles.checkbox}
                  truncate
                  id={optionValue}
                  checked={selectedIds.some(ov => ov === optionValue)}
                  gap={16}
                >
                  {label}
                </Checkbox>
              </MenuWithSearch.Item>
            ))}
          </div>
        </MenuWithSearch>
      )
    },
    [
      filteredList,
      selectedIds,
      handleToggleItem,
      search,
      handleScroll,
      handleSelect,
    ]
  )
  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder="Search"
        onChange={handleChangeSearch}
        value={search}
        ref={searchRef}
        shouldFocus={menuVisible}
      />
    ),
    [handleChangeSearch, search, menuVisible]
  )

  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        saveBtnTitle="Select"
      />
    ),
    [handleSelect, handleCancel]
  )

  const selectedNames = useMemo(
    () => {
      if (options.length && options.length === value.length) {
        return `All`
      }
      return value
        .map(id => options.find(option => option.value === id)?.label)
        .filter(name => name)
        .join(', ')
    },
    [options, value]
  )

  useEffect(
    () => {
      if (!search?.trim()) {
        setFilteredList(options)
      }
    },
    [search, options]
  )

  const previousMenuVisible = usePrevious(menuVisible)
  useEffect(
    () => {
      if (previousMenuVisible !== menuVisible && !menuVisible) {
        onSelectedIdsChange(value)
        setSearch('')
      }
    },
    // Including onSelectedIdsChange causes circular renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [previousMenuVisible, menuVisible, value]
  )

  return (
    <Dropdown
      overlay={menu}
      header={header}
      footer={footer}
      css={[
        text.styles.textSm,
        styles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      className={className}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
      offset={menuOffset}
      isNavByArrowsDisabled
    >
      {children || (
        <Dropdown.Button css={styles.dropdownBtn} size="small">
          {value.length ? selectedNames : 'Select'}
        </Dropdown.Button>
      )}
    </Dropdown>
  )
}

export default CustomFieldsDropdown
