import { constructSearchQueryObject } from './index'

export default function isSearchValid(search, validOperators) {
  const queryObject = constructSearchQueryObject(search)
  return Object.keys(queryObject).reduce((isValid, key) => {
    if (Object.keys(validOperators).indexOf(key) < 0) return false // Invalid key
    const validValues = validOperators[key]
    const values = queryObject[key]
    const areValuesValid =
      values.reduce((isValueValid, value) => {
        if (validValues.indexOf(value) < 0) return false
        if (value === '') return false
        return isValueValid
      }, true) && values.length > 0
    if (validValues !== '*' && !areValuesValid) return false // Invalid values
    return isValid
  }, true)
}
