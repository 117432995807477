import { OPERATOR_TYPE_EQ } from 'util/operators'
import { equalsCaseInsensitive } from 'util/strings'

export const hasDeletedCondition = conditions => {
  return (conditions || []).some(
    c =>
      equalsCaseInsensitive(c.param, 'deleted') &&
      equalsCaseInsensitive(c.operator, OPERATOR_TYPE_EQ) &&
      equalsCaseInsensitive(c.value, 'true')
  )
}
