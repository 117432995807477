import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styles as modalBtnsStyles } from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns.styles'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { doFetchBillingData } from 'ducks/billing/operations'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import { doUpdateAccountPreference } from 'ducks/accountPreferences/operations'
import {
  selectPrefersInboxBillingVersion,
  selectPrefersKbBillingVersion,
  selectPrefersAddonBillingVersion,
} from 'selectors/app/selectAccountPreferences'
import { ImpersonateAccessDrawer } from '../NoAccess'
import { styles } from './styles'

const ALLOWED_INBOX_VERSIONS = [
  'v4',
  'v5',
  'v6',
  'v7',
  'v8',
  'v9',
  'v10',
  'v11',
  'v12',
  'v13',
  'v14',
  'v15',
  'v16',
  'v17',
  'v18',
  'v20',
  'v21',
  'v22',
]
const ALLOWED_KB_VERSIONS = ['v1', 'v2', 'v3', 'v4', 'v5', 'v6', 'v7']
const ALLOWED_ADDON_VERSIONS = ['v2']

const BillingChangeVersions = ({ open, onExit }) => {
  const dispatch = useDispatch()
  const currentInboxBillingVersion = useSelector(
    selectPrefersInboxBillingVersion
  )
  const currentKbBillingVersion = useSelector(selectPrefersKbBillingVersion)
  const currentAddonBillingVersion = useSelector(
    selectPrefersAddonBillingVersion
  )

  const [inboxBillingVersion, setInboxBillingVersion] = useState(
    currentInboxBillingVersion
  )
  const [kbBillingVersion, setKbBillingVersion] = useState(
    currentKbBillingVersion
  )
  const [addonBillingVersion, setAddonBillingVersion] = useState(
    currentAddonBillingVersion
  )
  const [isSaving, setIsSaving] = useState(false)

  const inboxBillingVerrsionOptions = useMemo(
    () =>
      [...ALLOWED_INBOX_VERSIONS].reverse().map(version => ({
        value: version,
        text: version,
      })),
    []
  )
  const kbBillingVerrsionOptions = useMemo(
    () =>
      [...ALLOWED_KB_VERSIONS].reverse().map(version => ({
        value: version,
        text: version,
      })),
    []
  )
  const addonBillingVerrsionOptions = useMemo(
    () =>
      [...ALLOWED_ADDON_VERSIONS].reverse().map(version => ({
        value: version,
        text: version,
      })),
    []
  )

  const handleUpdateVersions = useCallback(
    async () => {
      let changed = false
      if (isSaving) return

      setIsSaving(true)
      if (currentInboxBillingVersion !== inboxBillingVersion) {
        changed = true
        await dispatch(
          doUpdateAccountPreference(
            'inbox_billing_version',
            inboxBillingVersion
          )
        )
      }
      if (currentKbBillingVersion !== kbBillingVersion) {
        changed = true
        await dispatch(
          doUpdateAccountPreference('kb_billing_version', kbBillingVersion)
        )
      }
      if (currentAddonBillingVersion !== addonBillingVersion) {
        changed = true
        await dispatch(
          doUpdateAccountPreference(
            'addon_billing_version',
            addonBillingVersion
          )
        )
      }
      if (changed) {
        await dispatch(doFetchBillingData())
      }
      setIsSaving(false)
      onExit()
    },
    [
      currentInboxBillingVersion,
      currentKbBillingVersion,
      currentAddonBillingVersion,
      inboxBillingVersion,
      kbBillingVersion,
      addonBillingVersion,
      dispatch,
      isSaving,
      onExit,
    ]
  )

  const footer = (
    <div css={[modalBtnsStyles.base, styles.btns]}>
      <Button
        type="info"
        size="big"
        onClick={handleUpdateVersions}
        disabled={isSaving}
      >
        {isSaving && 'Saving versions...'} {!isSaving && 'Save versions'}
      </Button>
    </div>
  )

  return (
    <>
      <ImpersonateAccessDrawer
        title={`Update pricing versions`}
        open={open}
        footer={footer}
        onClose={onExit}
      >
        <div className="grui pt-10" css={[styles.container]}>
          <div css={text.styles.textNormal}>
            The following options will allow you to update the billing pricing
            versions for the current account.
          </div>

          <div className="grui mt-10">
            <div css={fieldStyles.labelBox} className="grui mb-5">
              Inbox
            </div>
            <Dropdown
              overlay={<DropdownMenu data={inboxBillingVerrsionOptions} />}
              onSelect={setInboxBillingVersion}
              selectedKey={inboxBillingVersion}
              strategy="fixed"
            >
              <Dropdown.Button css={styles.dropdownBtn}>
                {inboxBillingVersion}
              </Dropdown.Button>
            </Dropdown>
          </div>
          <div className="grui mt-10">
            <div css={fieldStyles.labelBox} className="grui mb-5">
              Kb
            </div>
            <Dropdown
              overlay={<DropdownMenu data={kbBillingVerrsionOptions} />}
              onSelect={setKbBillingVersion}
              selectedKey={kbBillingVersion}
              strategy="fixed"
            >
              <Dropdown.Button css={styles.dropdownBtn}>
                {kbBillingVersion}
              </Dropdown.Button>
            </Dropdown>
          </div>
          <div className="grui mt-10">
            <div css={fieldStyles.labelBox} className="grui mb-5">
              Addon
            </div>
            <Dropdown
              overlay={<DropdownMenu data={addonBillingVerrsionOptions} />}
              onSelect={setAddonBillingVersion}
              selectedKey={addonBillingVersion}
              strategy="fixed"
            >
              <Dropdown.Button css={styles.dropdownBtn}>
                {addonBillingVersion}
              </Dropdown.Button>
            </Dropdown>
          </div>
        </div>
      </ImpersonateAccessDrawer>
    </>
  )
}

export default BillingChangeVersions
