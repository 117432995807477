// eslint-disable func-names
import md5 from 'util/md5'
import fastMemoize from 'fast-memoize'
import config from 'config'

export const cacheRegistry = {
  caches: [],
  invalidateInterval: 30000, // 30 seconds
  expirationAge: 5 * 60000, // 5 minutes
  storedInterval: null,
  invalidateAll: () => {
    if (cacheRegistry.marked) {
      if (config.isDevelopment) {
        console.debug('Running cacheRegistry.invalidateAll') // eslint-disable-line no-console
      }
      cacheRegistry.caches.forEach(cache =>
        cache.invalidate(cacheRegistry.expirationAge)
      )
      cacheRegistry.marked = false
    }
  },
  markForInvalidation: () => {
    cacheRegistry.marked = true
  },
  invalidateAllOlderThan: expirationAge => {
    cacheRegistry.caches.forEach(cache => cache.invalidate(expirationAge))
  },
  startAutomaticInvalidation: () => {
    cacheRegistry.storedInterval = setInterval(
      cacheRegistry.markForInvalidation,
      cacheRegistry.invalidateInterval
    )
  },
  stopAutomaticInvalidation: () => {
    clearInterval(cacheRegistry.storedInterval)
  },
  middleware: () => next => action => {
    const returnedValue = next(action)
    if (cacheRegistry.marked) cacheRegistry.invalidateAll()
    return returnedValue
  },
}

function CacheWithInvalidation() {
  this.cache = Object.create(null)
  this.lastAccess = Object.create(null)
  cacheRegistry.caches.push(this)
}

// eslint-disable-next-line func-names
CacheWithInvalidation.prototype.has = function(key) {
  return key in this.cache
}

// eslint-disable-next-line func-names
CacheWithInvalidation.prototype.get = function(key) {
  // if it is present and has been accessed, bump it
  // if (this.cache[key]) this.lastAccess[key] = new Date().getTime()
  return this.cache[key]
}

// eslint-disable-next-line func-names
CacheWithInvalidation.prototype.set = function(key, value) {
  const time = new Date().getTime()
  this.lastAccess[key] = time
  this.cache[key] = value
}

// eslint-disable-next-line func-names
CacheWithInvalidation.prototype.invalidate = function(expirationAge) {
  const now = new Date().getTime()

  Object.keys(this.lastAccess).forEach(key => {
    const age = now - this.lastAccess[key]
    if (age > expirationAge) {
      delete this.lastAccess[key]
      delete this.cache[key]
    }
  })
}

export const cacheWithInvalidation = {
  create: function create() {
    return new CacheWithInvalidation()
  },
}

export function memoize(fn, options) {
  /* eslint-disable no-param-reassign */
  options = options || {}
  options.cache = options.cache || cacheWithInvalidation
  if (options.strategy === 'variadic')
    options.strategy = fastMemoize.strategies.variadic
  /* eslint-enable no-param-reassign */
  return fastMemoize(fn, options)
}

export const objectHashSerializer = args => {
  const str = JSON.stringify(args)
  return md5(str)
}

// https://github.com/caiogondim/fast-memoize.js#function-arguments
// RTFM re: spread args too !
// https://github.com/caiogondim/fast-memoize.js#spread-arguments
let functionId = 0

function memoizedId(x) {
  functionId += 1
  /* eslint-disable no-underscore-dangle */
  // eslint-disable-next-line no-param-reassign
  if (!x.__memoizedId) x.__memoizedId = functionId
  return { __memoizedId: x.__memoizedId }
  /* eslint-enable no-underscore-dangle */
}

export const functionSerializer = args => {
  const argumentsWithFuncIds = Array.from(args).map(x => {
    if (typeof x === 'function') return memoizedId(x)
    return x
  })
  return JSON.stringify(argumentsWithFuncIds)
}
