/* eslint-disable camelcase */
import { connect as socketClusterConnect, SCSocket } from 'socketcluster-client'
import config from 'config'

import {
  doRealtimeConnected,
  doRealtimeDisconnected,
  doRealtimeBacklogDesync,
} from 'actions/realtime/status'
import debug, { logError } from 'util/debug'
import GA from 'util/googleAnalytics'
import WindowVisibility from 'util/window_visibility'
import { v4 as uuidV4 } from 'uuid'
import { getTabId } from 'util/tabId'
import { REDUX_ACTION_LOGGING_ADD_REALTIME_LOG } from 'constants/performance_sim/event_action_keys'

let socket
function getSocket() {
  return socket
}
// eslint-disable-next-line no-underscore-dangle
let _isConnected = false
function isConnected() {
  return _isConnected
}
function getState() {
  return socket.state
}
let connectedOnce = false
let ledgerSize = 0
const ledger = {}
let ledgerTimestamp = null
let watchHandler
let queuedEmits = []
let lastReconnect = null
let latestIdTimer = null
let isWindowUnloading = false
const noWindow = typeof window === 'undefined'

if (!noWindow) {
  const existingHandler = window.onbeforeunload

  window.onbeforeunload = event => {
    isWindowUnloading = true
    if (existingHandler) existingHandler(event)
  }
}

const CONNECTION_OPTIONS = {
  hostname: config.realtime_url,
  secure: true,
}

const PREV_ID_INTERVAL = 15000

const intervals = []
const runIntervalWhenConnected = (func, interval, name) => {
  debug('adding realtime interval', { name, interval })
  const obj = { func, interval, num: null }
  obj.num = setInterval(obj.func, obj.interval)
  // if an interval with a specific name already exists
  // remove it so it can be overwritten by a new one
  if (name) {
    const index = intervals.findIndex(o => o.name === name)
    if (index > -1) {
      const prev = intervals[index]
      clearInterval(prev.num)
      intervals.splice(index, 1)
    }
  }
  intervals.push(obj)
}
const clearIntervals = () => {
  debug('clearing realtime intervals')
  let count = 0
  intervals.forEach(obj => {
    count += 1
    if (obj.num) {
      clearInterval(obj.num)
      // eslint-disable-next-line no-param-reassign
      obj.num = null
    }
  })
  debug('cleared realtime intervals', { count })
}
const reinstallIntervals = () => {
  let count = 0
  intervals.forEach(obj => {
    count += 1
    if (obj.num) clearInterval(obj.num)
    // eslint-disable-next-line no-param-reassign
    obj.num = setInterval(obj.func, obj.interval)
  })
  debug('reinstalled realtime intervals', { count })
}

function connect(token, defaultWatchHandler, onReconnect) {
  watchHandler = defaultWatchHandler

  return new Promise((resolve, reject) => {
    const options = Object.assign({}, CONNECTION_OPTIONS, {
      query: { token, tabId: getTabId(), collisionVersion: 'V2' },
    })
    socket = socketClusterConnect(options)

    socket.on('error', err => {
      debug('socket error', err)
      app.store.dispatch(doRealtimeDisconnected())
      // TODO (jscheel): I'm thinking we should reject here too, but I need to
      // look at how SC handles this.
    })

    socket.on('authStateChange', data => {
      const { newState, authToken } = data
      if (newState === SCSocket.AUTHENTICATED && authToken) {
        ledgerSize = authToken.backlogSize
        resolve(authToken.channels)
      }
    })

    socket.on('authTokenChange', handleAuthTokenChange)

    socket.on('connect', status => {
      _isConnected = true
      debug('realtime connected', { connectedOnce, status })
      app.store.dispatch(doRealtimeConnected())
      reinstallIntervals()

      if (!connectedOnce) {
        handleFirstConnect()
      } else {
        handleReconnect(status)
          .then(onReconnect)
          .catch(e => {
            debug('realtime reconnect failure')
            reject(e)
          })
      }
    })

    socket.on('disconnect', () => {
      _isConnected = false
      debug('realtime disconnected', { connectedOnce, isWindowUnloading })
      // dont flash this message as you navigate away.
      if (!isWindowUnloading) app.store.dispatch(doRealtimeDisconnected())
      clearIntervals()
      cancelMonitorLatestId()
    })

    app.socket = socket
  })
}

function handleFirstConnect() {
  connectedOnce = true
  timestampLedger()
  attachPageVisibilityListener()
  scheduleMonitorLatestId()
  flushQueuedEmits()
}

function attachPageVisibilityListener() {
  WindowVisibility.addEventListener('focus', () => {
    if (socket.state === SCSocket.CLOSED && !socket.pendingReconnect) {
      app.socket.reconnect()
    }
  })
}

function monitorLatestId() {
  if (
    socket.state === SCSocket.OPEN &&
    socket.authState === SCSocket.AUTHENTICATED
  ) {
    socket.authToken.channels.forEach(channel => {
      const channelName = channel.path

      socket.emit('getLatestId', channelName, (err, latestId) => {
        if (!err && latestId && !checkLedger(channelName, latestId)) {
          fetchBacklog([channelName], {
            timestampLedger: false,
            reason: 'latest_id_missing',
          })
        }
      })
    })
  }
  scheduleMonitorLatestId()
}

function scheduleMonitorLatestId() {
  cancelMonitorLatestId()
  latestIdTimer = setTimeout(monitorLatestId, PREV_ID_INTERVAL)
  // NOTE (jscheel): Disabling for now, because not sure this actually makes sense.
  //  setTimeout(() => {
  //   if (window.requestIdleCallback) {
  //     window.requestIdleCallback(monitorLatestId, {
  //       timeout: PREV_ID_INTERVAL / 2,
  //     })
  //   } else {
  //     setTimeout(monitorLatestId, 1)
  //   }
  // }, PREV_ID_INTERVAL)
}

function cancelMonitorLatestId() {
  clearTimeout(latestIdTimer)
}

function handleReconnect(status) {
  lastReconnect = Date.now()

  return new Promise(resolve => {
    if (status.isAuthenticated) {
      resolve()
    } else {
      const handleAuthChange = data => {
        const { newState, authToken } = data

        if (newState === SCSocket.AUTHENTICATED && authToken) {
          socket.off('authStateChange', handleAuthChange)
          resolve()
        }
      }

      socket.on('authStateChange', handleAuthChange)
    }
  }).then(() => {
    fetchBacklog(null, { reason: 'reconnect' })
    scheduleMonitorLatestId()
    flushQueuedEmits()
  })
}

function timestampLedger() {
  ledgerTimestamp = Date.now()
}

function handleBacklogDisconnect({ reason }) {
  trackRefreshEvent({ reason })
  // Warn the user that we are de-synchronized
  app.store.dispatch(doRealtimeBacklogDesync())
}

function trackRefreshEvent(properties) {
  return GA.track('realtime', 'refresh-on-next-page', properties.reason)
}

function fetchBacklog(channels = [], options = {}) {
  if (
    socket.state !== SCSocket.OPEN ||
    socket.authState !== SCSocket.AUTHENTICATED
  ) {
    return Promise.resolve()
  }

  // eslint-disable-next-line no-param-reassign
  options = Object.assign(
    {
      timestampLedger: true,
      since: undefined,
      reason: '',
    },
    options
  )

  if (!channels || channels.length === 0) {
    // eslint-disable-next-line no-param-reassign
    channels = socket.authToken.channels.map(channel => channel.path)
  }

  return new Promise(resolve => {
    const backlogArgs = {
      since: options.since === undefined ? ledgerTimestamp : options.since,
      channels,
      reason: options.reason,
      crid: uuidV4(),
      crts: Date.now(),
    }
    const socketState = {
      authState: socket.authState,
      pendingReconnect: socket.pendingReconnect,
      connectAttempts: socket.connectAttempts,
      state: socket.state,
      lastReconnect,
    }

    // eslint-disable-next-line consistent-return
    socket.emit('backlog', backlogArgs, (err, backlogs) => {
      if (err) {
        debug('realtime: fetchBacklog: backlog socket error', {
          err,
          backlogArgs,
          backlogData: backlogs,
          socket: socketState,
        })

        return handleBacklogDisconnect({ reason: 'error' })
      }

      // eslint-disable-next-line consistent-return
      Object.keys(backlogs).forEach(channelName => {
        const backlog = backlogs[channelName]
        const oldest = backlog[backlog.length - 1]

        // NOTE (jscheel): If ledger doesn't have record of oldest backlogged message...
        if (
          oldest &&
          ledger[channelName] &&
          ledger[channelName].indexOf(oldest.id) === -1 &&
          ledger.length > 0
        ) {
          return handleBacklogDisconnect({ reason: 'stale ledger' })
        }

        while (backlog.length) {
          const message = backlog.pop()
          if (
            ledger[channelName] &&
            ledger[channelName].indexOf(message.id) === -1
          ) {
            if (message.meta) {
              message.meta.fromBacklog = true
            }
            // eslint-disable-next-line no-underscore-dangle
            socket._channelEmitter.emit(channelName, message)
          }
        }
      })

      if (options.timestampLedger) {
        timestampLedger()
      }
      resolve()
    })
  })
}

function flushQueuedEmits() {
  if (socket && socket.authToken) {
    queuedEmits.map(args => emit(...args))
    queuedEmits = []
  }
}

function watch(channel, action = watchHandler) {
  return new Promise((resolve, reject) => {
    const sub = socket.subscribe(channel.path, { waitForAuth: true })

    sub.on('subscribe', channelName => {
      debug('realtime channel subscribed', channelName)

      if (!ledger[channelName]) {
        ledger[channelName] = []
      }

      fetchBacklog([channelName], {
        timestampLedger: false,
        reason: 'subscribe',
      }).then(() => {
        socket.emit('getLatestId', channelName, (err, latestId) => {
          if (latestId) {
            recordLedger(channelName, latestId)
          }
        })
      })

      sub.off('subscribe')

      resolve()
    })

    sub.on('subscribeFail', channelName => {
      debug('realtime channel subscription failed', channelName)
      sub.off('subscribeFail')
      reject(new Error(`realtime channel subscription failed: ${channelName}`))
    })

    sub.watch(message => {
      if (message.meta && message.meta && message.meta.action) {
        postMessageActionLog(message.meta.action)
      }

      if (app.simulateOffline) {
        return
      }

      const { id, prevId } = message

      // HACK (jscheel): Realtime is a mess with camelCase and snake_case.
      /* eslint-disable no-lonely-if */
      if (
        (message.meta && message.meta.ignoreBacklog) ||
        (message.meta && message.meta.ignore_backlog)
      ) {
        if (message.meta && message.meta.ignore === true) {
          // (jscheel): Do nothing, this message needs to be ignored as it is a stub for the ledger.
        } else {
          action(message)
        }
      } else {
        if (
          !(message.meta && message.meta.fromBacklog) &&
          prevId &&
          !checkLedger(channel.path, prevId)
        ) {
          fetchBacklog(null, { reason: 'prev_id_missing' })
        } else {
          recordLedger(channel.path, id)
          if (message.meta && message.meta.ignore === true) {
            // (jscheel): Do nothing, this message needs to be ignored as it is a stub for the ledger.
          } else {
            action(message)
          }
        }
      }

      if (message.meta && message.meta && message.meta.type === 'changeset') {
        const { meta } = message
        socket.emit('ackMessage', {
          id,
          changesetId: meta.changeset_id,
          requestId: meta.request_id,
          tabId: getTabId(),
        })
      }
      /* eslint-enable no-lonely-if */
    })
  })
}

function checkLedger(channelName, messageId) {
  const currentLedger = ledger[channelName]
  if (!currentLedger) return false
  return currentLedger.indexOf(messageId) >= 0
}

function recordLedger(channelName, messageId) {
  if (checkLedger(channelName, messageId)) return
  const currentLedger = ledger[channelName]
  currentLedger.unshift(messageId)
  if (currentLedger.length > ledgerSize) currentLedger.pop()
}

function handleAuthTokenChange() {
  const { authToken } = socket
  if (!authToken || !connectedOnce) return
  const { channels } = authToken
  const existingChannels = socket.subscriptions(true)
  const newChannels = channels.filter(
    c => existingChannels.indexOf(c.path) === -1
  )
  const channelNames = channels.map(c => c.path)
  const staleChannels = existingChannels.filter(
    c => channelNames.indexOf(c) === -1
  )

  // HACK (jscheel): Let everything settle and messages finish.
  setTimeout(() => {
    if (newChannels.length > 0) {
      newChannels.forEach(c => watch(c))
    }

    staleChannels.forEach(c => {
      if (app.env !== 'production') {
        debug('realtime channel unsubscribed', c)
      }
      try {
        socket.destroyChannel(c)
      } catch (e) {
        debug('realtime unable to destroy channel', e)
      }
    })
  }, 300)
}

// eslint-disable-next-line consistent-return
function emit(...args) {
  if (args) {
    postMessageActionLog(args[0])
  }
  if (socket && socket.authToken) {
    return socket.emit(...args)
  }
  queuedEmits.push(args)
}

// NOTE (jscheel): This function ensures that we transform a ticket message
// appropriately so that it mimics the what would be coming back from gql. It's
// not ideal.
function buildTicketFromMessage(message, currentUser) {
  const {
    meta: { type, filter_diffs },
  } = message
  let data

  if (type === 'ticket') {
    data = message.data
  } else if (type === 'changeset') {
    data = message.meta.ticket
  } else {
    throw new Error('Invalid message type')
  }

  const ticket = { ...data }

  if (data.id) {
    ticket.id = data.id.toString()
  }

  if (data.mailbox_id) {
    ticket.mailboxId = data.mailbox_id
    // TODO (jscheel): Verify that we don't expect this anywhere before deleting
    // delete ticket.mailbox_id
  }

  if (data.last_message_conversation_type) {
    ticket.bodyType = data.last_message_conversation_type
    delete ticket.last_message_conversation_type
  }

  if (data.tag_ids) {
    ticket.labels = data.tag_ids.map((id, idx) => {
      return {
        // HACK (jscheel): Ids should be strings but this still uses the internal
        // id, which is serialized as an integer. This causes all sorts of wonkiness.
        id: id.toString(),
        name: data.tags[idx],
      }
    })
    delete ticket.tag_ids
    delete ticket.tags
  }

  if (ticket.assignee) {
    ticket.assignee.name = [
      ticket.assignee.first_name,
      ticket.assignee.last_name,
    ]
      .join(' ')
      .trim()
    delete ticket.assignee.first_name
    delete ticket.assignee.last_name
  }

  if (data.last_message_author) {
    ticket.bodyAuthor = data.last_message_author
    delete ticket.last_message_author
  }

  if (data.snoozed_until) {
    ticket.snoozedUntil = data.snoozed_until
    delete ticket.snoozed_until
  }

  // NOTE (jscheel): Realtime only returns filter diff for your agent id, but we
  // are keeping it hashed by agent id for now for backwards-compatibility

  // This may also be a candidate for removal since the unified_inbox pref is
  // coming across in the message now.
  const diff = filter_diffs[currentUser.id]
  if (diff) {
    ticket.diff = diff
  }

  return ticket
}

function postMessageActionLog(data) {
  try {
    window.postMessage(
      {
        performanceSim: {
          action: REDUX_ACTION_LOGGING_ADD_REALTIME_LOG,
          data,
        },
      },
      '*'
    ) // not sensitive info
  } catch (e) {
    logError(e)
  }
}

module.exports = {
  connect,
  watch,
  emit,
  buildTicketFromMessage,
  runIntervalWhenConnected,
  isConnected,
  getSocket,
  getState,
}
