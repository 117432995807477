import { sanitize } from 'util/sanitize'
import { browserLanguage } from 'util/locale'
import deburr from './deburr'

const overFirstLetter = f => string => {
  if (typeof string === 'number') return string.toString()
  if (!string || string.length === 0) return ''
  return f(string[0]) + string.slice(1)
}

export const isCapitalized = string => {
  if (!string) return false
  return string[0] === string[0].toUpperCase()
}

export const isEmpty = string => {
  return !string || string.length === 0
}

export const isBlank = string => {
  return !string || /^\s*$/.test(string)
}

export const capitalize = overFirstLetter(l => l.toUpperCase())
export const uncapitalize = overFirstLetter(l => l.toLowerCase())

export const camelize = (str, { lowercase = true } = {}) => {
  if (!str) return null
  const lowercaseStr = lowercase ? str.toLowerCase() : str
  return lowercaseStr.trim().replace(/(-|_|\s)+(.)?/g, (mathc, sep, c) => {
    return c ? c.toUpperCase() : ''
  })
}

export function humanize(str, fromCamelCase = false) {
  if (fromCamelCase) {
    return capitalize(str.replace(/([A-Z])/g, ' $1'))
  }
  return capitalize(str.replace(/(-|_)+/g, ' '))
}

export const capitalizeEachWord = str =>
  str.replace(/(^\w|\s\w)/g, m => m.toUpperCase())

export const words = string => string.split(/\s+/)

// Lifted from https://stackoverflow.com/a/20732091
export const humanFileSize = (bytes, precision = 0) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / 1024 ** i).toFixed(precision) * 1} ${
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  }`
}

export const stripHTML = (str = '') => {
  const sanitizer = document.createElement('div')
  sanitizer.innerHTML = sanitize(str)
  return sanitizer.textContent.trim()
}

// Dodgy regexp. Should be using sthg like `striptags` instead
export const unsafeStripTags = str => {
  if (!str) return ''
  return str.replace(/<[^>]+>/g, '')
}

// This is the same regexp we use in `dumbLinkify`, except we don't care about
// whitespace and tags preceding email address
// eslint-disable-next-line no-useless-escape
const emailValidator = /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/
export const isValidEmail = string => emailValidator.test(string)

const domainValidator = /^((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isValidDomain = string => domainValidator.test(string)

export const isHtmlEmpty = str => {
  if (!str || str.length === 0) {
    return true
  }
  return stripHTML(str).length === 0
}

export const capitalizeUnlessEmail = string => {
  if (!string || typeof string !== 'string') return ''
  if (isValidEmail(string)) return string

  return capitalize(string)
}

export const withoutUnprintable = str => str.replace(/[^\x20-\x7E]+/g, '')

export const pluralize = (n, word, suffix = 's') => {
  if (typeof n === 'number') return n === 1 ? word : word + suffix

  if (['unlimited', Infinity].includes(n)) return word + suffix

  return word
}

export const chooseIndefiniteArticle = s => (/^[aeiou]/i.test(s) ? 'an' : 'a')

// Indefinite Article
export const indefinitize = str => `${chooseIndefiniteArticle(str)} ${str}`

export const dumbLinkify = (text, options = {}) => {
  if (!text) return ''
  let newText = text

  // eslint-disable-next-line no-useless-escape
  const urlPattern = /(^|[\s\n]|\&nbsp;|<[A-Za-z]*\/?>|\s\"|\s\')((?:https?|ftp):\/\/[\-A-Z0-9+\u0026\u2019@#\/%?=()~_|!:,.;]*[\-A-Z0-9+\u0026@#\/%=~()_|])/gi
  newText = newText.replace(urlPattern, (match, p1, p2) => {
    if (options.showFullUrl) {
      return `${p1}<a href='${p2}'>${p2}</a>`
    }

    return `${p1}<a href='${p2}'>${ellipsify(p2, 10, true)}</a>`
  })

  newText = newText.replace(/<a\s/g, '<a target="_blank" ')

  // eslint-disable-next-line no-useless-escape
  const emailPattern = /(^|[\s\n]|\&nbsp;|<[A-Za-z]*\/?>)((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))/g
  newText = newText.replace(
    emailPattern,
    (match, leadingSpace, emailAddress) =>
      `${leadingSpace}<a href="mailto:${emailAddress}">${emailAddress}</a>`
  )

  return newText
}

export function ellipsify(str, len, isUrl = false) {
  if (str && isUrl) {
    const lenWithSlash = len + 1 // NOTE (jscheel): Leading / is added when parsing below, so accounting for it in length
    const parser = document.createElement('a')
    parser.href = str
    if (parser.pathname.length <= lenWithSlash) {
      return str
    }
    parser.pathname = `${parser.pathname.slice(0, lenWithSlash)}&hellip;`
    return parser.href
  }
  if (!str || str.length <= len) {
    return str
  }
  return `${str.slice(0, len)}&hellip;`
}

export const trimTrailingWhitespaceElements = string => {
  if (!string) {
    return string
  }

  return string
    .replace(/(&nbsp;\s*)+$/gi, '')
    .replace(/(<br \/>\s*)+$/gi, '')
    .replace(/(<br>\s*)+$/gi, '')
}

export const formatNumber = number => {
  return String(number).replace(/(.)(?=(\d{3})+$)/g, '$1,')
}

export const compareCaseInsensitive = (a, b) => {
  const nameA = a.toLowerCase()
  const nameB = b.toLowerCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

export const equalsCaseInsensitive = (a, b) => {
  return (a || '').toLowerCase() === (b || '').toLowerCase()
}

export const possesive = label =>
  label.toLowerCase() === 'you' ? 'your' : `${label}’s`

export const arrayBufferToString = buf =>
  String.fromCharCode.apply(null, new Uint16Array(buf))

export const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const removeInitialTwitterMention = string => {
  if (!string) return null
  return string.replace(/^(@[\w]+)\s*/, '')
}

export const replaceSpaces = string => {
  if (!string) return ''
  return string.replace(/(&nbsp;)|(\s{2,})|(\u00a0)/g, ' ')
}

export function caselessMatch(term, str) {
  if (!term || !str) return null
  return escapeRegExp(deburr(str))
    .toLowerCase()
    .match(escapeRegExp(deburr(term)).toLowerCase())
}

// https://stackoverflow.com/a/21350614
export const splice = (str, index, count, add) => {
  // We cannot pass negative indexes dirrectly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index // eslint-disable-line no-param-reassign
    if (index < 0) {
      index = 0 // eslint-disable-line no-param-reassign
    }
  }

  return str.slice(0, index) + (add || '') + str.slice(index + count)
}

export function escapeRegExp(str) {
  if (!str) return null
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
}

// NOTE (jscheel): Sauce: https://stackoverflow.com/a/11958496
export function levenshteinDistance(s, t) {
  /* eslint-disable */
  const d = [] // 2d matrix

  // Step 1
  const n = s.length
  const m = t.length

  if (n == 0) return m
  if (m == 0) return n

  // Create an array of arrays in javascript (a descending loop is quicker)
  for (let i = n; i >= 0; i--) d[i] = []

  // Step 2
  for (let i = n; i >= 0; i--) d[i][0] = i
  for (let j = m; j >= 0; j--) d[0][j] = j

  // Step 3
  for (let i = 1; i <= n; i++) {
    const s_i = s.charAt(i - 1)

    // Step 4
    for (let j = 1; j <= m; j++) {
      // Check the jagged ld total so far
      if (i == j && d[i][j] > 4) return n

      const t_j = t.charAt(j - 1)
      const cost = s_i == t_j ? 0 : 1 // Step 5

      // Calculate the minimum
      let mi = d[i - 1][j] + 1
      const b = d[i][j - 1] + 1
      const c = d[i - 1][j - 1] + cost

      if (b < mi) mi = b
      if (c < mi) mi = c

      d[i][j] = mi // Step 6

      // Damerau transposition
      if (i > 1 && j > 1 && s_i == t.charAt(j - 2) && s.charAt(i - 2) == t_j) {
        d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost)
      }
    }
  }

  // Step 7
  return d[n][m]
  /* eslint-enable */
}

export function isString(str) {
  return typeof str === 'string' || str instanceof String
}

// Safer than `mailbox.name.toLowerCase()`
export function downcase(str) {
  if (!str) return ''
  if (!isString(str)) return str
  return str.toLowerCase()
}

export const titleize = str => {
  if (str === undefined) return str

  return capitalize(downcase(str))
}

export const isChar = /[\S]/i

export const isNotChar = /[.]/i

export const walkChars = (text, startIndex, direction) => {
  const chars = []
  let index = startIndex
  let work = true
  let chr
  let count = 0
  while (work) {
    index += direction
    chr = text.charAt(index)
    if (!isNotChar.test(chr) && isChar.test(chr)) {
      if (direction > 0) {
        chars.push(chr)
      } else {
        chars.unshift(chr)
      }
      count += 1
    } else {
      index -= direction
      work = false
    }
  }
  return { count, chars, index }
}

export function splitWordsIntoSizedParts(text, maxSize) {
  if (!text) return []
  const matches = text.match(
    new RegExp(`.{0,${maxSize}}(?=\\s|$)|[^\\s]+`, 'g')
  )
  if (!matches) return text.length > maxSize ? text.split(' ') : text
  return matches.map(x => x.trim()).filter(x => !!x)
}

export const extractTwitterHandles = text => {
  if (!text) return []
  const matches = text.match(/(^|[^@\w])@(\w{1,15})\b/g)
  if (matches === null) return []
  return matches.map(x => x.trim())
}

export function countLines(str) {
  if (!str) return 0
  if (typeof str !== 'string' && !(str instanceof String)) return 0
  return str.split(/\r\n|\r|\n/).length
}

export function rfc3986EncodeURIComponent(str) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    c => `%${c.charCodeAt(0).toString(16)}`
  )
}

export function rfc3986DecodeURIComponent(str) {
  return decodeURIComponent(
    str
      .replace('%21', '!')
      .replace('%27', "'")
      .replace('%28', '(')
      .replace('%29', ')')
      .replace('%2a', '*')
  )
}

export const convertWhitespacesToHTMLEntities = text =>
  text.replace(/ /g, '&nbsp;').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')

export function template(templateRegex, templateString, templateVars) {
  return templateString.replace(templateRegex, (_, key) => {
    switch (typeof templateVars[key]) {
      case 'object':
        return JSON.stringify(templateVars[key])
      case 'function':
        return templateVars[key]()
      case 'undefined':
        return ''
      default:
        return templateVars[key]
    }
  })
}

export function endsWith(str, suffix) {
  return str.match(`${suffix}$`) === suffix
}

export function sizeInBytes(str) {
  if (!str) return 0

  return new Blob([str]).size
}

export function detectValueDelimiter(
  data,
  delimiters = [',', '\n', '\t', ' ', '\\|']
) {
  const arr = data.split('{||||||}')

  const strCount = (needle, haystack) =>
    (haystack.match(new RegExp(needle, 'g')) || []).length

  const testChar = c => {
    const first = strCount(c, arr[0])
    if (first === 0) return false
    for (let i = 1; i < arr.length; i += 1) {
      const count = strCount(c, arr[i])
      if (count !== first) return false
    }
    return true
  }

  const chars = delimiters
  for (let i = 0; i < chars.length; i += 1) {
    if (testChar(chars[i])) return chars[i]
  }
  return ','
}

// amount needs to be a fraction e.g. 0.015 for 1.5%
export function localePercentageString(value) {
  const locale = browserLanguage()

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  }).format(value)
}

export function guessNameFromEmail(email) {
  if (!isValidEmail(email)) return email
  // Split the email address at the '@' symbol
  const localPart = email.split('@')[0]

  // Remove anything after a '+' symbol in the local part
  const localPartWithoutExtras = localPart.split('+')[0]

  // Split the local part of the email by any character that is not a letter (like ., _, -, etc.)
  const nameParts = localPartWithoutExtras.split(/[^a-zA-Z]/)

  // Capitalize the first letter of each part of the name
  const capitalizedParts = nameParts.map(
    part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  )

  // Join the parts into a full name
  return capitalizedParts.join(' ')
}
