/* eslint-disable no-underscore-dangle */
import {
  CONVERSATION_TYPE_FACEBOOK,
  CONVERSATION_TYPE_TWITTER,
  CONVERSATION_TYPE_WIDGET,
} from 'ducks/tickets/constants'
import { extractTwitterHandles } from 'util/strings'

export const RECIPIENT_TYPE_GROUPS = ['to', 'cc', 'bcc']

export const isTwitter = ticket =>
  ticket && ticket.__typename === CONVERSATION_TYPE_TWITTER
export const isWidget = ticket =>
  ticket && ticket.__typename === CONVERSATION_TYPE_WIDGET
export const isFacebook = ticket =>
  ticket && ticket.__typename === CONVERSATION_TYPE_FACEBOOK

export function getAccountTwitterUsername(ticket) {
  if (!ticket) return ''
  const { subject } = ticket
  if (!subject) return ''

  const usernames = extractTwitterHandles(subject)
  return usernames.length > 0 ? usernames[0] : ''
}
