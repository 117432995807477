import { connect } from 'react-redux'
import PagingButtonsView from 'components/ConversationHeader/Buttons/PagingButtons'
import { doOpenNextTicketPage } from 'ducks/tickets/actions/doOpenNextTicketPage'
import { doOpenPreviousTicketPage } from 'ducks/tickets/actions/doOpenPreviousTicketPage'
import {
  selectEntityCountByQueryId,
  selectSearchEntityIndexByQueryIdAndEntityId,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

const select = state => {
  const queryId = selectCurrentQueryId(state)
  const ticketId = selectCurrentTicketId(state)

  return {
    currentConversationIndex: selectSearchEntityIndexByQueryIdAndEntityId(
      state,
      queryId,
      ticketId
    ),
    totalConversations: selectEntityCountByQueryId(state, queryId),
  }
}

const perform = {
  openNextConversation: doOpenNextTicketPage,
  openPreviousConversation: doOpenPreviousTicketPage,
}

export default connect(select, perform)(PagingButtonsView)
