import React, { useEffect, useRef, useState } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import Dot from '@groovehq/internal-design-system/lib/components/Dot/Dot'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { styles } from '../MultiSelectMenu/styles'

export default function Option({
  id,
  name,
  color,
  conversationCount,
  onChange,
  checked,
  indeterminate = false,
}) {
  const [isItemTextOverflowing, setIsItemTextOverflowing] = useState(null)
  const itemTextRef = useRef(null)

  useEffect(() => {
    if (itemTextRef.current) {
      setIsItemTextOverflowing(
        itemTextRef.current.clientWidth < itemTextRef.current.scrollWidth
      )
    }
  }, [])

  const content = (
    <div className="grui truncate" ref={itemTextRef}>
      {color && <Dot color={color} size="small" className="grui mr-3 mb-2" />}
      {`${name}${
        isDefined(conversationCount) ? ` (${conversationCount})` : ''
      }`}
    </div>
  )

  return (
    <Checkbox
      css={styles.checkbox}
      id={id}
      onChange={onChange}
      checked={checked}
      indeterminate={indeterminate}
      truncate
    >
      {isItemTextOverflowing ? (
        <Tooltip title={name} strategy="fixed" portal="#overlays">
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </Checkbox>
  )
}
