export const MAILBOX_SEARCH_QUERY = 'inbox:'
export const DATE_SEARCH_QUERY_TYPE = 'date'
export const RANGE_SEPARATOR = '&'
export const RECENT_TICKETS_SEARCH_QUERIES_STORAGE_KEY =
  'recentTicketsSearchQueriesV2'

export const suggestionList = [
  {
    suggestion: 'from:',
    hint: 'email or contact',
    searchQuery: 'from:',
  },
  { suggestion: 'tag:', hint: 'tag name', searchQuery: 'tag:' },
  {
    suggestion: 'mentions:',
    hint: 'username or me',
    searchQuery: 'mentions:',
  },
  { suggestion: 'my:', hint: 'state or drafts', searchQuery: 'my:' },
  {
    suggestion: 'is:',
    hint: 'state, unassigned or assigned',
    searchQuery: 'is:',
  },
  {
    suggestion: 'assignee:',
    hint: 'username, me or unassigned',
    searchQuery: 'assignee:',
  },
  {
    suggestion: 'rating:',
    hint: 'awesome, ok, bad',
    searchQuery: 'rating:',
  },
  {
    suggestion: 'group:',
    hint: `${app.t('group')} name or unassigned`,
    searchQuery: 'group:',
  },
  {
    suggestion: 'drafts:',
    hint: 'username or me',
    searchQuery: 'drafts:',
  },
  {
    suggestion: 'folder:',
    hint: 'folder name',
    searchQuery: 'folder:',
  },
  {
    suggestion: 'inbox:',
    hint: `${app.t('mailbox')} name`,
    searchQuery: MAILBOX_SEARCH_QUERY,
  },
  {
    suggestion: 'after:',
    hint: 'yyyy-mm-dd',
    searchQuery: 'after:',
    type: DATE_SEARCH_QUERY_TYPE,
    apiQueryKey: 'after_created_at',
  },
  {
    suggestion: 'before:',
    hint: 'yyyy-mm-dd',
    searchQuery: 'before:',
    type: DATE_SEARCH_QUERY_TYPE,
    apiQueryKey: 'before_created_at',
  },
  {
    suggestion: 'between:',
    hint: 'yyyy-mm-dd&yyyy-mm-dd',
    searchQuery: 'between:',
    type: DATE_SEARCH_QUERY_TYPE,
  },
]

export const VALID_OPERATORS = {
  assignee: '*',
  assigned: '*',
  state: ['closed', 'opened', 'unread', 'snoozed', 'spam'],
  rating: ['any', 'awesome', 'ok', 'bad'],
  assigned_group: '*',
  draft: '*',
  draft_type: '*',
  starred: ['all'],
  deleted: ['all'],
  label: '*',
  mailbox: '*',
  mentions: '*',
  keywords: '*',
  folder: '*',
  customer: '*',
  from: '*',
  before: '*',
  after: '*',
  between: '*',
}
