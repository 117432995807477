import React, { useCallback } from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDispatch } from 'react-redux'
import EntityDelete from 'subapps/settings/components/drawers/EntityDelete'
import { doDeleteNote } from 'ducks/tickets/actions/doDeleteNote'

const CommentDeleteDrawer = ({ open, onClose, onExit, drawerResourceId }) => {
  const [commentId, ticketId] = drawerResourceId.split('-')
  const dispatch = useDispatch()

  const handleDelete = useCallback(
    () => {
      dispatch(doDeleteNote(ticketId, commentId))
    },
    [dispatch, ticketId, commentId]
  )

  return (
    <div>
      <EntityDelete
        onRemove={handleDelete}
        onClose={onClose}
        onExit={onExit}
        onCancel={onClose}
        deleteBtn
        tertiaryBtnText="Cancel"
        title="Delete note"
        open={open}
        skipProceedQuestion
        confirmationHidden
        adminAccess={false}
      >
        <p css={text.styles.textNormal}>
          Are you sure you want to delete this note?
          <br />
          This cannot be undone.
        </p>
      </EntityDelete>
    </div>
  )
}

export default CommentDeleteDrawer
