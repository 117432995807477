import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'

import {
  doSaveChannelDraft,
  doClearChannelDraft,
  doSyncChannelDraft,
  doAddGmailChannel,
} from 'ducks/channels/actions'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'
import { oAuthErrorToErrorCode } from 'subapps/settings/components/drawers/Channels/ChannelDetails/oAuthError'
import ChannelDetailsForm from 'subapps/settings/components/shared/forms/ChannelDetails'
import {
  useRebuildLeftNavMenu,
  useGoNextStepAfterMailboxDetails,
  useExitMailboxCreationIfFeatureIsDisabled,
} from 'ducks/channels/hooks'
import { selectIsInInbox } from 'selectors/location'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import { CHANNEL_TYPES } from '../../Channels.data'
import { styles } from '../styles'
import { useAdminAccess } from '../../../NoAccess/hooks'
import ActionsWithMessage from '../ActionsWithMessage'

// shares some functionality with drawers/Channels/AuthenticateChannel
const OAuthModal = ({
  onClose,
  onExit,
  drawerChannelType: channelType = CHANNEL_TYPE.FORWARDING,
  drawerResourceId: channelId, // new
  drawerId,
  firstStep,
}) => {
  const isInInbox = useSelector(selectIsInInbox)
  const dispatch = useDispatch()
  const [errorCode, setErrorCode] = useState(null)
  const [isInstalling, setIsInstalling] = useState(false)
  const [newChannelId, setNewChannelId] = useState(null)
  const [isOAuthSccessful, setIsOAuthSccessful] = useState()
  const creationStepCount = useSelector(state =>
    selectChannelCreationStepCount(state, CHANNEL_TYPES[channelType])
  )
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const timeoutRef = useRef()

  const formId = `${drawerId}-form`

  useAdminAccess(onClose)

  useExitMailboxCreationIfFeatureIsDisabled({
    newChannelId,
    channelType,
    onExit,
  })

  useGoNextStepAfterMailboxDetails({
    newChannelId,
    channelType,
    onExit,
  })

  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({
    channelId: newChannelId, // channel id after mailbox is created
    onExit,
  })

  const handleOnClose = useConfirmHoldsCallback(formId, onClose, [onClose])

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      if (!newChannelId) {
        dispatch(doClearChannelDraft(channelId))
        onExit()
        return
      }
      dispatch(doClearChannelDraft(newChannelId))
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      rebuildMenuAndExit()
    },
    [dispatch, onExit, channelId, isInInbox, newChannelId, currentMailboxId]
  )

  const handleError = useCallback(
    error => {
      setErrorCode(oAuthErrorToErrorCode(error, channelType))
      // eslint-disable-next-line no-console
      console.error(error)
    },
    [channelType]
  )

  const handleGmailChannel = useCallback(
    async data => {
      const res = await dispatch(doAddGmailChannel(data))
      dispatch(doBuildInboxMenuFromMailboxes())
      return res
    },
    [dispatch]
  )

  const handleChannelOAuth = useCallback(
    data => {
      switch (channelType) {
        case 'google':
          return handleGmailChannel(data)
        default:
          return null
      }
    },
    [channelType, handleGmailChannel]
  )

  const onSubmit = useCallback(
    async data => {
      try {
        setIsInstalling(true)
        setErrorCode(null)

        const {
          entities: {
            pending: { update: { channel: channelById } = {} } = {},
          } = {},
        } = dispatch(doSaveChannelDraft(channelId, data))

        const { id } = await handleChannelOAuth(channelById[channelId])

        // clear 'new' draft
        dispatch(doClearChannelDraft('new'))
        // copy over created channel from current to pending store
        dispatch(doSyncChannelDraft(id))

        setIsOAuthSccessful(true)
        timeoutRef.current = setTimeout(() => {
          setNewChannelId(id)
          timeoutRef.current = null
        }, 1000)
      } catch (error) {
        setIsInstalling(false)
        handleError(error)
      }
    },
    [dispatch, channelId, handleChannelOAuth, handleError]
  )

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const actionsComponentProps = useMemo(
    () => {
      return {
        formId,
        onClose: handleOnClose,
        channelType,
        isInstalling,
        errorCode,
        firstStep,
        isOAuthSccessful,
        channelId: newChannelId,
        onExit,
      }
    },
    [
      formId,
      handleOnClose,
      channelType,
      isInstalling,
      errorCode,
      firstStep,
      isOAuthSccessful,
      newChannelId,
      onExit,
    ]
  )

  const footerRef = useRef(null)

  return (
    <Modal
      onClose={handleOnExit}
      dataTestId="channels-details-oauth"
      portal="#drawer-root"
      open
    >
      <ProgressDots
        count={creationStepCount}
        now={2}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>Connect your {app.t('Mailbox')}</Modal.Title>
      <Modal.Description css={styles.description}>
        Follow these steps to automatically start receiving emails in your
        Groove account
      </Modal.Description>
      <div css={styles.content} className="grui pt-5 pb-6 text-center">
        <ChannelDetailsForm
          formId={formId}
          channelId={channelId}
          channelType={channelType}
          onSubmit={onSubmit}
          fullWidth
          actionsPortalRef={footerRef}
          actionsComponent={ActionsWithMessage}
          actionsComponentAdditionalProps={actionsComponentProps}
        />
        <div ref={footerRef} className="grui mt-20" />
      </div>
    </Modal>
  )
}

export default OAuthModal
