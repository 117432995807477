export const isNullOrUndefined = value => [null, undefined].includes(value)
export const isDefined = value => !isNullOrUndefined(value)
export const isBlank = value => {
  if (isNullOrUndefined(value)) return true

  if (typeof value === 'boolean') return false

  if (typeof value === 'number') return false

  if (typeof value === 'string') return !value.trim()

  return false
}
