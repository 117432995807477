import { connect } from 'react-redux'
import {
  doUpdateTicketSearchQuery,
  doToggleListSearchBoxFocused,
  doSubmitTicketsSearch,
} from 'actions/search'
import { selectCurrentCommittedTicketSearchQueryString } from 'selectors/search/base'

import SearchView from 'components/ConversationList/Header/Search'
import { selectCurrentSubmittedTicketSearchFilterMatches } from 'selectors/search/searchFilters'
import { doFetchRecipients } from './actions'

const select = state => {
  return {
    committedSearchQuery: selectCurrentCommittedTicketSearchQueryString(state),
    currentSubmittedTicketSearchFilterMatches: selectCurrentSubmittedTicketSearchFilterMatches(
      state
    ),
  }
}

const perform = {
  doToggleListSearchBoxFocused,
  doUpdateTicketSearchQuery,
  doSubmitTicketsSearch,
  doFetchRecipients,
}

export default connect(select, perform)(SearchView)
