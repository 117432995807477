import {
  strftime,
  formatTime,
  isToday,
  isTomorrow,
  isWithinWeek,
  adjustNextDayOfWeek,
  isFromDifferentYear,
  toNearestHalfHour,
} from 'util/date'

export const SNOOZED_INDEFINITELY = 'SNOOZED_INDEFINITELY'
export const SNOOZED_INDEFINITELY_TIMESTAMP = 4102444800000 // 2100-01-01 00:00:00

export function snoozeLabel(snoozedUntil, includeWeekLabel = true) {
  if (
    [null, SNOOZED_INDEFINITELY, SNOOZED_INDEFINITELY_TIMESTAMP].includes(
      snoozedUntil
    )
  )
    return 'Indefinitely'
  const time = formatTime(snoozedUntil)

  let label

  if (isToday(snoozedUntil)) {
    label = `Today, ${time}`
  } else if (isTomorrow(snoozedUntil)) {
    label = `Tomorrow, ${time}`
  } else if (includeWeekLabel && isWithinWeek(snoozedUntil)) {
    const day = new Date(snoozedUntil).toLocaleDateString('en-US', {
      weekday: 'long',
    })
    label = `${day}, ${time}`
  } else if (isFromDifferentYear(snoozedUntil)) {
    const date = strftime('%b %Do %Y', snoozedUntil)
    label = `${date}, ${time}`
  } else {
    const date = strftime('%b %Do', snoozedUntil)
    label = `${date}, ${time}`
  }

  return label
}

const setHours = hours => timestamp => {
  const date = new Date(timestamp)
  date.setHours(hours, 0, 0, 0)
  return date.getTime()
}

const adjustDays = daysDiff => timestamp => {
  const date = new Date(timestamp)
  date.setDate(date.getDate() + daysDiff)
  return date.getTime()
}

const nextDay = adjustDays(1)

// null default is redundant but shows lastSnoozedDate is optional
export const snoozeOptions = (
  lastSnoozedDate = null,
  showHiddenSnoozeOptions = false
) => {
  return [
    {
      value: 'later-today',
      text: 'Later Today',
      format: timestamp => `${strftime('%l:%M%p', timestamp)}`,
      timestamp: () => toNearestHalfHour(Date.now() + 3 * 60 * 60 * 1000),
      showOption: () => new Date().getHours() < 21, // Only before 9PM
    },
    {
      value: 'later-week',
      text: 'Later this Week',
      format: timestamp => `${strftime('%A @ %l%p', timestamp)}`,
      timestamp: () => adjustDays(2)(setHours(8)(Date.now())),
      showOption: () => [1, 2, 3].includes(new Date().getDay()), // Only Mon,Tue,Wed
    },
    {
      value: 'next-saturday',
      text: 'This Weekend',
      format: timestamp => `${strftime('%A @ %l%p', timestamp)}`,
      timestamp: () => adjustNextDayOfWeek(6)(setHours(8)(Date.now())),
      showOption: () => new Date().getDay() === 4, // Only Thurs
    },
    {
      value: 'tomorrow-morning',
      text: 'Tomorrow Morning',
      format: timestamp => `${strftime('%A @ %l%p', timestamp)}`,
      timestamp: () => setHours(8)(nextDay(Date.now())),
      showOption: () => new Date().getDay() !== 0, // don't show on Sunday because same snooze time as next-monday
    },
    {
      value: 'next-monday',
      text: 'Next Week',
      format: timestamp => `${strftime('%A @ %l%p', timestamp)}`,
      timestamp: () => adjustNextDayOfWeek(1)(setHours(8)(Date.now())),
      showOption: () => true,
    },
    {
      value: '2-weeks',
      text: '2 Weeks',
      format: timestamp => `${strftime('%A %Do @ %l%p', timestamp)}`,
      timestamp: () => adjustDays(14)(setHours(8)(Date.now())),
      showOption: () => !!showHiddenSnoozeOptions,
    },
    {
      value: '30-days',
      text: '1 Month',
      format: timestamp =>
        isFromDifferentYear(new Date(timestamp))
          ? `${strftime('%b %Do %Y @ %l%p', timestamp)}`
          : `${strftime('%b %Do @ %l%p', timestamp)}`,
      timestamp: () => adjustDays(30)(setHours(8)(Date.now())),
      showOption: () => !!showHiddenSnoozeOptions,
    },
    {
      value: SNOOZED_INDEFINITELY,
      text: 'Indefinitely',
      format: () => 'until unsnoozed',
      timestamp: () => SNOOZED_INDEFINITELY,
      indefinite: true,
      showOption: () => true,
      divider: true,
    },
    {
      value: 'last-snoozed',
      text: 'Last',
      format: timestamp => snoozeLabel(new Date(timestamp)),
      timestamp: () => lastSnoozedDate,
      showOption: () => !!lastSnoozedDate,
      svgIcon: 'redo',
    },
  ]
}
