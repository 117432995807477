import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { func, shape, string, number } from 'prop-types'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { Trash } from '@groovehq/internal-design-system/lib/assets/icons'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { selectCurrentPaidActiveAgentsWithCurrentUserSort } from 'ducks/agents/selectors'
import { selectGroupsSortedByName } from 'selectors/app/groups'
import { selectMailboxesIncludingInaccessible } from 'ducks/mailboxes/selectors/selectMailboxesIncludingInaccessible'
import { useWatch } from 'react-hook-form'
import usePrevious from 'util/hooks/usePrevious'
import { omit } from 'util/objects'

import RuleValue from '../RuleValue'
import DropdownMenu from '../DropdownMenu'
import { styles } from './Condition.styles'
import { buildPossibleValueOptions, selectValue } from './util'

const Condition = ({
  variables,
  onRemoveCondition,
  conditionErrorMessage,
  onBlur,
  index,
  onConditionChange,
  decodeIds = false,
  totalConditions,
  control,
  name,
  placeholder,
  conditionMatchTypeName,
  itemKey,
  tooltipForAdvancedFeature,
}) => {
  const condition = useWatch({
    control,
    name,
  })

  const conditionMatchType = useWatch({
    control,
    name: conditionMatchTypeName,
  })
  const isAnyCondition = String(conditionMatchType).toLowerCase() === 'any'

  const {
    param: conditionParam,
    operator: conditionOperator,
    value: conditionValue,
  } = condition

  const {
    parameters: allParameters,
    param_operators: paramOperators,
    values,
  } = variables
  const paramOperator = useMemo(() => paramOperators[conditionParam] || [], [
    paramOperators,
    conditionParam,
  ])
  const agents = useSelector(selectCurrentPaidActiveAgentsWithCurrentUserSort)
  const groups = useSelector(selectGroupsSortedByName)
  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)
  const dataType = values[conditionParam]?.dataType || 'AUTO'
  const paramType = values[conditionParam]?.paramType || null
  const allowSelectCurrentUser =
    values[conditionParam]?.allowSelectCurrentUser || false

  const possibleOptionsArray = buildPossibleValueOptions(
    values,
    conditionParam,
    agents,
    groups,
    mailboxes,
    { decodeIds, operator: conditionOperator }
  )
  const [value, setValue] = useState(possibleOptionsArray)
  const [initialConditionParam] = useState(conditionParam)

  const parameters = useMemo(
    () => {
      return allParameters
        .filter(p => !p.deprecated || p.value === initialConditionParam)
        .map(p => omit(['deprecated'], p))
    },
    [allParameters, initialConditionParam]
  )

  const selectedParameter = useMemo(
    () => selectValue(parameters, conditionParam),
    [conditionParam, parameters]
  )

  const selectedOperator = useMemo(
    () => selectValue(paramOperator, conditionOperator),
    [paramOperator, conditionOperator]
  )

  const selectedValue = useMemo(
    () => {
      return Array.isArray(value) ? selectValue(value, conditionValue) : null
    },
    [value, conditionValue]
  )
  // Handle select parameter
  const handleSelectParameter = useCallback(
    key => {
      const newParamOperator = paramOperators[key] || []
      const newValue = values[key] || ''
      let lastValue = newValue[0] ? newValue[0].value : ''

      const newPossibleOptionsArray = buildPossibleValueOptions(
        values,
        key,
        agents,
        groups,
        mailboxes,
        { decodeIds, operator: conditionOperator }
      )
      setValue(newPossibleOptionsArray)
      if (Array.isArray(newPossibleOptionsArray)) {
        lastValue = newPossibleOptionsArray[0].value
      }

      // Pass select values to condition

      onConditionChange(
        {
          ...condition,
          param: key,
          operator: newParamOperator[0] && newParamOperator[0].value,
          value: lastValue,
        },
        index,
        {
          validate: false,
        }
      )
    },
    [
      onConditionChange,
      paramOperators,
      agents,
      groups,
      mailboxes,
      values,
      index,
      decodeIds,
      condition,
      conditionOperator,
    ]
  )

  const previousConditionParam = usePrevious(conditionParam)

  useEffect(
    () => {
      if (previousConditionParam && conditionParam !== previousConditionParam) {
        handleSelectParameter(conditionParam)
      }
    },
    // Adding handleSelectParameter will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conditionParam, previousConditionParam]
  )

  // Handle select param operator
  const handleSelectParamOperatorKey = useCallback(
    key => {
      const newPossibleOptionsArray = buildPossibleValueOptions(
        values,
        conditionParam,
        agents,
        groups,
        mailboxes,
        { decodeIds, operator: key }
      )
      setValue(newPossibleOptionsArray)

      onConditionChange(
        {
          ...condition,
          operator: key,
        },
        index
      )
    },
    [
      onConditionChange,
      agents,
      groups,
      mailboxes,
      values,
      index,
      decodeIds,
      condition,
      conditionParam,
    ]
  )

  // Handle select value
  const handleSelectValueKey = useCallback(
    key => {
      onConditionChange(
        {
          ...condition,
          value: key,
        },
        index
      )
      // Trigger validation
      onBlur(
        {
          ...condition,
          value: key,
        },
        index
      )
    },
    [onConditionChange, index, condition, onBlur]
  )

  const handleRemove = useCallback(
    () => {
      onRemoveCondition(condition, index)
    },
    [onRemoveCondition, index, condition]
  )

  const handleInput = useCallback(
    e => {
      const newValue = e.target.value
      onConditionChange(
        {
          ...condition,
          value: newValue,
        },
        index
      )
    },
    [onConditionChange, index, condition]
  )

  const handleBlur = useCallback(
    e => {
      const newValue = e.target.value
      onBlur(
        {
          ...condition,
          value: newValue,
        },
        index
      )
    },
    [onBlur, index, condition]
  )

  return (
    <div css={styles.base}>
      <div css={styles.dropdowns}>
        <div css={styles.dropdownContainer} title={selectedParameter}>
          <Dropdown
            menuItemIconHidden
            overlay={
              <DropdownMenu
                data={parameters}
                checkProductFeatureLimit
                tooltipForAdvancedFeature={tooltipForAdvancedFeature}
              />
            }
            onSelect={handleSelectParameter}
            selectedKey={conditionParam}
          >
            <Dropdown.Button size="small" css={styles.dropdownBtn}>
              {selectedParameter || placeholder || 'Select a filter...'}
            </Dropdown.Button>
          </Dropdown>
        </div>
        {conditionParam && (
          <div css={styles.dropdownContainer}>
            <Dropdown
              overlay={<DropdownMenu data={paramOperator} />}
              onSelect={handleSelectParamOperatorKey}
              selectedKey={conditionOperator}
            >
              <Dropdown.Button size="small" css={styles.dropdownBtn}>
                {selectedOperator || <>&nbsp;</>}
              </Dropdown.Button>
            </Dropdown>
          </div>
        )}
        {conditionOperator && (
          <RuleValue
            value={value}
            onDropdownSelect={handleSelectValueKey}
            selectedValue={selectedValue}
            onInputBlur={handleBlur}
            onInputChange={handleInput}
            valueKey={conditionValue}
            dataType={dataType}
            paramType={paramType}
            errorMessage={conditionErrorMessage}
            itemKey={itemKey}
            // Remount this component if conditionParam is changed
            // then autoFocus will be working for new inputs
            key={conditionParam}
            allowSelectCurrentUser={allowSelectCurrentUser}
          />
        )}
        <div>
          {totalConditions > 1 && (
            <Button
              type="tertiary"
              size="small"
              customIcon={<Trash />}
              css={text.styles.textMediumDark}
              onClick={handleRemove}
            />
          )}
        </div>
      </div>
      <div className="operator">{isAnyCondition ? 'OR' : 'AND'}</div>
    </div>
  )
}

Condition.propTypes = {
  variables: shape({}),
  onRemoveCondition: func,
  onBlur: func,
  onConditionChange: func,
  conditionErrorMessage: string,
  totalConditions: number,
  conditionMatchTypeName: string,
  tooltipForAdvancedFeature: string,
}

Condition.defaultProps = {
  variables: {},
  onRemoveCondition() {},
  onBlur() {},
  onConditionChange() {},
  conditionErrorMessage: null,
  totalConditions: 0,
  conditionMatchTypeName: 'matchType',
  tooltipForAdvancedFeature: undefined,
}

export default React.memo(Condition)
