import { connect } from 'react-redux'

import { doSelectAllTickets } from 'actions/app'

import { selectAccountCanSeePinnedSearches } from 'selectors/app'
import { selectIsTicketSelectionMode } from 'selectors/ticket_list'
import { selectIsPinnableByCurrentQueryId } from 'ducks/pinnedSearch/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import {
  doDeleteSelectedTickets,
  doBulkSpamSelectedTickets,
  doBulkUnreadSelectedTickets,
  doUnmarkBulkSelectionMode,
} from 'actions/tickets'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'

import TitleBarView from 'components/ConversationList/Header/TitleBar'
import SortMenu from './SortMenu'
import BulkActionBar from './BulkActionBar'

const select = state => ({
  canSeePinnedSearches: selectAccountCanSeePinnedSearches(state),
  isSearchPinnable: selectIsPinnableByCurrentQueryId(state),
  isSelectionMode: selectIsTicketSelectionMode(state),
  queryId: selectCurrentQueryId(state),
  isAdminOrOwnerOrAgent: selectCurrentUserIsAdminOrOwnerOrAgent(state),
  SortMenuComponent: SortMenu,
  BulkActionBarComponent: BulkActionBar,
})

const perform = {
  onSelectAllClick: doSelectAllTickets,
  onUnselectAllClick: doUnmarkBulkSelectionMode,
  onDeleteClick: doDeleteSelectedTickets,
  onMarkAsSpamClick: doBulkSpamSelectedTickets,
  onMarkAsUnreadClick: doBulkUnreadSelectedTickets,
}

export default connect(select, perform)(TitleBarView)
