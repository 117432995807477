import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import { redirect } from 'redux-first-router'
import { useDispatch, useSelector } from 'react-redux'
import { useFormState } from 'react-hook-form'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  doSaveChannelDraft,
  doClearChannelDraft,
  doSyncChannelDraft,
  doAddMessengerChannel,
  doAddInstagramChannel,
  doFetchChatChannels,
} from 'ducks/channels/actions'
import {
  doCreateFacebookWidget,
  doCreateInstagramWidget,
} from 'ducks/widgets/operations'
import { convertHexToRGBA } from 'util/colors'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'
import { oAuthErrorToErrorCode } from 'subapps/settings/components/drawers/Channels/ChannelDetails/oAuthError'
import { DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE } from 'ducks/drawers/types'
import ChannelDetailsForm from 'subapps/settings/components/shared/forms/ChannelDetails'
import { doFetchFacebookPages } from 'ducks/facebook/actions'
import { doBuildMenuFromWidgets } from 'ducks/folders/operations/collections'
import { selectCurrentFacebookPages } from 'ducks/facebook/selectors'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import {
  useRedirectToChannel,
  useChannelDetailsConfig,
  useRebuildLeftNavMenu,
} from 'ducks/channels/hooks'
import { SETTINGS_CHANNELS_PAGE } from 'subapps/settings/types'
import { selectIsInInbox } from 'selectors/location'
import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import { CHANNEL_TYPES } from '../../Channels.data'
import { styles } from '../styles'

const Actions = ({
  formId,
  channelType,
  onClose,
  isInstalling,
  errorCode,
  control,
  totalFacebookPages,
  firstStep,
}) => {
  const { name, icon } = CHANNEL_TYPES[channelType]
  const { oAuthErrors } = useChannelDetailsConfig(channelType)

  const { isValid } = useFormState({ control })
  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const oauthErrorMessage = useMemo(
    () => {
      if (!errorCode) return null

      return oAuthErrors[errorCode] || oAuthErrors.DEFAULT
    },
    [errorCode, oAuthErrors]
  )

  const isAuthenticate =
    !isBridgeChannelType(channelType) || totalFacebookPages === 0

  const saveBtnText = (
    <>
      {isAuthenticate && (
        <>
          <span css={styles.btnIcon} className="grui mr-4">
            {icon}
          </span>
          Authenticate with {name}
        </>
      )}
      {!isAuthenticate && 'Next step'}
    </>
  )

  return (
    <>
      {oauthErrorMessage && (
        <MessageCard
          type="negative"
          closeable
          className="grui mx-12 my-10"
          css={styles.messageCard}
          isIconHidden
        >
          <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
            Authentication error
          </p>
          <p css={paragraph.styles.preflight} className="grui mt-4">
            {oauthErrorMessage}
          </p>
        </MessageCard>
      )}
      <ModalBtns
        saveBtnText={saveBtnText}
        saveBtnHtmlType="submit"
        saveBtnTestId={`authenticate-${channelType}-button`}
        saveBtnDisabled={!isValid || isInstalling}
        saveBtnForm={formId}
        tertiaryBtnText={firstStep ? undefined : 'Back'}
        onClickTertiaryBtn={handleBack}
      />
    </>
  )
}

// shares some functionality with drawers/Channels/AuthenticateChannel
const ChannelDetailsOAuth = ({
  onClose,
  onExit,
  drawerChannelType: channelType = CHANNEL_TYPE.FORWARDING,
  drawerResourceId: channelId,
  drawerId,
  firstStep,
}) => {
  const dispatch = useDispatch()
  const [errorCode, setErrorCode] = useState(null)
  const [isInstalling, setIsInstalling] = useState(false)
  const [nextDrawerResourceId, setNextDrawerResourceId] = useState(null)
  const {
    drawer: configNextDrawer,
    drawerTitle,
    canUseFeature,
  } = useChannelDetailsConfig(channelType)
  const [nextDrawer, setNextDrawer] = useState(configNextDrawer)
  const [additionalDrawerQuery, setAdditionalDrawerQuery] = useState({})
  const facebookPages = useSelector(selectCurrentFacebookPages)
  const isInInbox = useSelector(selectIsInInbox)

  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    nextDrawer
  )

  const formId = `${drawerId}-form`

  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId: nextDrawerResourceId, // channel id after mailbox is created
    onExit,
  })

  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({
    channelId: nextDrawerResourceId, // channel id after mailbox is created
    onExit,
  })

  const handleOnClose = useConfirmHoldsCallback(formId, onClose, [onClose])

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      dispatch(doClearChannelDraft(channelId))

      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [dispatch, onExit, channelId]
  )

  const handleError = useCallback(
    error => {
      setErrorCode(oAuthErrorToErrorCode(error, channelType))
      // eslint-disable-next-line no-console
      console.error(error)
    },
    [channelType]
  )

  const loadFacebookPages = useCallback(
    async () => {
      const { facebookPages: loadedFacebookPages } = await dispatch(
        doFetchFacebookPages({ skipLoaded: false })
      )

      return loadedFacebookPages
    },
    [dispatch]
  )

  const handleCreateWidget = useCallback(
    async data => {
      const doCreateChannel =
        channelType === 'instagram'
          ? doCreateInstagramWidget
          : doCreateFacebookWidget
      const widget = await dispatch(
        doCreateChannel(data?.name, convertHexToRGBA(data?.color))
      )
      dispatch(doFetchChatChannels({ skipLoaded: false }))
      dispatch(doBuildMenuFromWidgets({ autoRedirect: false }))
      return widget
    },
    [dispatch, channelType]
  )

  const handleMessengerOrInstagram = useCallback(
    async data => {
      let loadedFacebookPages = await loadFacebookPages()
      const showReconnect = loadedFacebookPages.some(page => {
        return page.available_bridge_types.includes(channelType)
      })

      if (showReconnect) {
        setAdditionalDrawerQuery({
          drawerAction: 'reconnect',
          drawerSource: 'create',
        })
        setNextDrawer(DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE)
        return handleCreateWidget(data)
      }
      let widget = null
      if (channelType === 'instagram') {
        widget = await dispatch(doAddInstagramChannel(data))
      } else {
        widget = await dispatch(doAddMessengerChannel(data))
      }
      dispatch(doFetchChatChannels({ skipLoaded: false }))

      // Did the user connect more than one page?
      loadedFacebookPages = await loadFacebookPages()
      // TODO (jscheel): Check for connected_instagram_account
      if (loadedFacebookPages.length > 1) {
        setAdditionalDrawerQuery({
          drawerAction: 'connect',
          drawerSource: 'create',
        })
        setNextDrawer(DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE)
      }
      return widget
    },
    [dispatch, loadFacebookPages, channelType, handleCreateWidget]
  )

  const handleChannelOAuth = useCallback(
    data => {
      switch (channelType) {
        case 'facebook':
        case 'instagram':
          return handleMessengerOrInstagram(data)
        default:
          return null
      }
    },
    [channelType, handleMessengerOrInstagram]
  )

  const onSubmit = useCallback(
    async data => {
      try {
        setIsInstalling(true)
        setErrorCode(null)

        const {
          entities: {
            pending: { update: { channel: channelById } = {} } = {},
          } = {},
        } = dispatch(doSaveChannelDraft(channelId, data))

        const { id } = await handleChannelOAuth(channelById[channelId])
        // clear 'new' draft
        dispatch(doClearChannelDraft('new'))
        // copy over created channel from current to pending store
        dispatch(doSyncChannelDraft(id))

        setNextDrawerResourceId(id)
      } catch (error) {
        setIsInstalling(false)
        handleError(error)
      }
    },
    [dispatch, channelId, handleChannelOAuth, handleError]
  )

  useEffect(
    () => {
      if (canUseFeature) return

      onExit()
      dispatch(
        redirect({
          type: SETTINGS_CHANNELS_PAGE,
        })
      )
    },
    [onExit, dispatch, canUseFeature, channelType]
  )

  useEffect(
    () => {
      if (!nextDrawerResourceId) return

      if (!nextDrawer) {
        rebuildExitAndRedirect()
        return
      }

      openNextDrawer(nextDrawerResourceId, {
        query: {
          ...buildDrawerQueryParam(
            nextDrawerId,
            'drawerChannelType',
            channelType
          ),
          ...Object.keys(additionalDrawerQuery).reduce(
            (accumulator, key) => ({
              ...accumulator,
              ...buildDrawerQueryParam(
                nextDrawerId,
                key,
                additionalDrawerQuery[key]
              ),
            }),
            {}
          ),
        },
      })
    },
    [
      nextDrawerResourceId,
      nextDrawerId,
      channelType,
      openNextDrawer,
      additionalDrawerQuery,
      rebuildExitAndRedirect,
      nextDrawer,
    ]
  )

  const actionsComponentProps = useMemo(
    () => {
      return {
        formId,
        onClose: handleOnClose,
        channelType,
        isInstalling,
        errorCode,
        totalFacebookPages: facebookPages.length,
        firstStep,
      }
    },
    [
      formId,
      handleOnClose,
      channelType,
      isInstalling,
      errorCode,
      facebookPages.length,
      firstStep,
    ]
  )

  const footerRef = useRef(null)

  return (
    <AdminAccessDrawer
      title={drawerTitle}
      footer={<div ref={footerRef} />}
      onClose={handleOnExit}
      data-test-id="channels-details-oauth"
    >
      <ChannelDetailsForm
        formId={formId}
        channelId={channelId}
        channelType={channelType}
        onSubmit={onSubmit}
        fullWidth
        actionsPortalRef={footerRef}
        actionsComponent={Actions}
        actionsComponentAdditionalProps={actionsComponentProps}
      />
    </AdminAccessDrawer>
  )
}

export default ChannelDetailsOAuth
