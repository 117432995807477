import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  doUpdateMergeTicket,
  doChangeMergeDirection,
} from 'ducks/merging/operations'
import { selectMergingMergeTicketId } from 'ducks/merging/selectors'
import { styles as menuWithSearchStyles } from 'subapps/settings/components/MenuWithSearch'

import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import AutomationItem from 'subapps/settings/components/AutomationItem'

import SelectedTicket from '../SelectedTicket'
import SelectTickets from '../SelectTickets'
import { styles } from './styles'

const TicketSelector = () => {
  const dispatch = useDispatch()
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const mergeTicketId = useSelector(selectMergingMergeTicketId)
  const onSelect = useCallback(() => setDropdownVisible(false), [])

  const onDelete = useCallback(
    () => {
      setDropdownVisible(false)
      dispatch(doUpdateMergeTicket(null))
      dispatch(doChangeMergeDirection('normal'))
    },
    [dispatch]
  )

  return (
    <div css={styles.dropdownWrapper}>
      <Dropdown
        css={[menuWithSearchStyles.dropdownContainer, styles.dropdownContainer]}
        overlay={<SelectTickets onSelect={onSelect} />}
        onVisibleChange={setDropdownVisible}
        visible={dropdownVisible}
        autoHeight
        isNavByArrowsDisabled
        position="bottom"
      >
        <div>
          {mergeTicketId ? (
            <SelectedTicket ticketId={mergeTicketId} onDelete={onDelete} />
          ) : (
            <AutomationItem.Button css={styles.triggerButton}>
              Select conversation
            </AutomationItem.Button>
          )}
        </div>
      </Dropdown>
    </div>
  )
}

export default TicketSelector
