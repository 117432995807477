export const getLocalStorageStats = () => {
  const localStorageStats = {}
  try {
    for (let i = 0; i < localStorage.length; i += 1) {
      const key = localStorage.key(i)
      const value = localStorage.getItem(key)
      localStorageStats[key] = value.length
    }
  } catch (e) {
    localStorageStats.failed = 1
  }
  return localStorageStats
}

export const displayErrorMessage = e => {
  // Kevin R: 2022-02-07
  // I could not find a single instance of this prop getting set, so I dont think its
  // currently possible for the error page to get displayed AT ALL
  if (e.fatal) {
    const error = document.getElementById('error')
    const app = document.getElementById('app')

    app.style.display = 'none'
    error.style.display = 'block'
  }
}

const errorCodes = error => {
  const errors = error ? error.errors : undefined
  const statuses = []
  if (errors) {
    errors.forEach(e => {
      if (e) {
        try {
          const message = JSON.parse(e.message)
          statuses.push(message.status)
        } catch (ex) {
          // jank. Assumes its not a json error.
          statuses.push(e.status)
        }
      }
    })
  }

  return statuses
}

export const isUnauthorized = error => {
  return errorCodes(error).indexOf(401) !== -1
}

function isServiceWorkerRegistrationError(_, originalError = {}) {
  const { code, name, message } = originalError.reason || {}
  // Jank. The offline-plugin's `install()` will reject, but not allow you to
  // catch it. Go figure.
  return (
    code === 20 &&
    name === 'AbortError' &&
    message ===
      'Failed to register a ServiceWorker: ServiceWorker cannot be started'
  )
}

// Jank. The offline-plugin's `install()` will reject, but not allow you to
// catch it. Go figure.
function isServiceWorkerSecurityError(_, originalError = {}) {
  const { code, name, message } = originalError.reason || {}
  return (
    code === 18 &&
    name === 'SecurityError' &&
    message ===
      'Failed to register a ServiceWorker: The script resource is behind a redirect, which is disallowed.'
  )
}

// https://app.bugsnag.com/groove/frontend/errors/5c5d92401089d7001a087705
function isServiceWorkerUpdateError(bugsnagError) {
  return (
    bugsnagError.errorClass === 'TypeError' &&
    bugsnagError.errorMessage.includes(
      'Failed to update a ServiceWorker for scope'
    )
  )
}

// Kevin R: 2022-02-03
// Honest, I have no idea why this error has suddenly started happening after our upgrade to webpack5
// I've also reviewed the code of socketcluster-client/lib/sctransport.js, and I cannot see a single code
// path that would cause this error. I also havent been able to reproduce it. There is also no reports of
// this error online. We already have code automatically fixing the realtime connection when it goes bad
// so this error shouldnt have any impact on the customer.
// https://app.bugsnag.com/groove/frontend/errors/61efd8d743c4930009eca0a0
function isWebsocketAbortAllPendingEventsDueToBadConnectionError(bugsnagError) {
  return (
    bugsnagError.errorClass === 'TypeError' &&
    bugsnagError.stacktrace.some(
      e =>
        !!e.method?.includes('abortAllPendingEventsDueToBadConnection') ||
        !!e.file?.includes('abortAllPendingEventsDueToBadConnection')
    )
  )
}

// Kevin R: Prior to webpack5, it appears that this error wasnt getting reported. After the upgrade
// we're getting it hundreds of times daily. This appears to be an upstream issue and there doesnt
// appear to be any negative effect for the customer, so we'll just suppress the error
// https://github.com/tinymce/tinymce/issues/7406
// https://app.bugsnag.com/groove/frontend/errors/61efe15b1df76f00078a3453
function isTinymceDomError(bugsnagError) {
  return (
    bugsnagError.errorClass === 'IndexSizeError' &&
    bugsnagError.stacktrace.some(st => st.method?.includes('nodeChanged')) &&
    bugsnagError.stacktrace.some(st => st.method?.includes('fire')) &&
    // // Failed to execute 'setStart' on 'Range': There is no child at offset 2.
    bugsnagError.errorMessage.includes('setStart') &&
    bugsnagError.errorMessage.includes('Range')
  )
}

// https://app.bugsnag.com/groove/frontend/errors/676010a6ccd03a19f65f5401?filters[error.status]=open&filters[event.since]=1h&filters[app.release_stage]=alpha-auto&event_id=67640d8c010debbcf03f0000
function isTinymceChildNodesError(bugsnagError) {
  return (
    bugsnagError.errorClass === 'TypeError' &&
    bugsnagError.stacktrace.some(st => st.file?.includes('tinymce')) &&
    bugsnagError.errorMessage.includes('null') &&
    bugsnagError.errorMessage.includes('childNodes')
  )
}

// https://app.bugsnag.com/groove/frontend/errors/676010a6ccd03a19f65f5401?filters[error.status]=open&filters[event.since]=1h&filters[app.release_stage]=alpha-auto&event_id=67640d8c010debbcf03f0000
function isChatCorsError(bugsnagError) {
  return (
    bugsnagError.errorClass === 'ConnectionError' &&
    bugsnagError.stacktrace.some(st => st.method?.includes('XMLHttpReques')) &&
    // // Failed to execute 'setStart' on 'Range': There is no child at offset 2.
    bugsnagError.errorMessage.includes('CORS request rejected') &&
    bugsnagError.errorMessage.includes(
      'chat-server-cluster.production.groovehq.com'
    )
  )
}

// Indicates that the error was already handled and reported by our graphql integration. No need
// to report it again
function isDoNotReport(_, originalError) {
  return originalError.doNotReport
}

// We dont really care if local network errors occures. Our app needs to gracefully handle it, but
// we definitrely dont want these errors reported to bugsnag
function isNetworkRequestFailed(bugsnagError) {
  return bugsnagError.errorMessage.match('Network request failed')
}

// Not a critical failure and can be safely ignored
// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
// https://stackoverflow.com/questions/64238740/how-to-ignore-the-resizeobserver-loop-limit-exceeded-in-testcafe
function isResizeObserverLimitError(bugsnagError) {
  return [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ].includes(bugsnagError.errorMessage)
}

// If instead of a descriptive error message we get a general
// 'Script error.', it means error occurred in a script with
// different origin (e.g. in an iframe created by an editor),
// i.e. it's not a problem with our code.
//
// In such cases it's better to assume that that code is able to
// continue to work in presence of errors rather than kill the
// whole app — we don't have enough information to effectively
// debug the problem anyway.
//
// More info:
//   https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#Notes
function isScriptError(bugsnagError) {
  return bugsnagError.errorMessage.match('Script error.')
}

export function isIgnoredError(bugsnagError, originalError) {
  // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#errors
  return [
    isScriptError,
    isNetworkRequestFailed,
    isDoNotReport,
    isServiceWorkerSecurityError,
    isServiceWorkerRegistrationError,
    isServiceWorkerUpdateError,
    isTinymceDomError,
    isTinymceChildNodesError,
    isWebsocketAbortAllPendingEventsDueToBadConnectionError,
    isResizeObserverLimitError,
    isChatCorsError,
  ].some(isError => isError(bugsnagError, originalError))
}

window.testUnhandledRejectionBroken = () => {
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject({})
}

window.testUnhandledRejection = () => {
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject('test error')
}

window.testRegularError = () => {
  throw 'test error' // eslint-disable-line no-throw-literal
}

window.testCustomError = () => {
  const error = new Error('custom')
  error.doNotReport = true
  throw error
}

window.createTestError = function createTestError(depth) {
  function generateStack(level) {
    if (level <= 1) {
      throw new Error(`Synthetic error at depth ${depth}`)
    }
    return generateStack(level - 1)
  }

  generateStack(depth)
}

window.testUnhandledDoNotReportRejection = () => {
  const error = new Error('custom')
  error.doNotReport = true
  Promise.reject(error)
}

export function addToJsonToError() {
  if (!('toJSON' in Error.prototype))
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Error.prototype, 'toJSON', {
      value() {
        const alt = {}

        Object.getOwnPropertyNames(this).forEach(key => {
          alt[key] = this[key]
        }, this)

        return alt
      },
      configurable: true,
      writable: true,
    })
}

export function GraphQlValidationError(fields) {
  Object.assign(this, fields)
  this.fields = fields
  this.message = `Field validation failed for [${Object.keys(fields)
    .filter(k => k !== '_mapped')
    .join(',')}]`
}
GraphQlValidationError.prototype = new Error()
