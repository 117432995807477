import { useSelector } from 'react-redux'
import {
  selectCurrentMergeSearch,
  selectHasPendingSearch,
  selectIsCurrentMergeSearchQueryValid,
  selectCurrentMergeSearchResultTickets,
} from 'ducks/merging/selectors/search'
import SearchErrorMessage from 'components/App/Pages/SearchPage/SearchErrorMessage'
import InvalidSearchMessage from 'components/App/Pages/SearchPage/InvalidSearchMessage'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
import { emptyArr, getLength } from 'util/arrays'
import { selectSendingTicketIds } from 'ducks/undoSend/selectors'
import { emptyObj } from 'util/objects'

import Options from './Options'
import NoResults from './NoResults'

import { styles } from '../styles'

const List = ({ ignoreTabEnter, onDown, onSelect, onUp, onLoadMoreSearch }) => {
  const searchResults =
    useSelector(state => selectCurrentMergeSearchResultTickets(state)) ||
    emptyArr
  const delayedTicketIds = useSelector(selectSendingTicketIds)
  const isQueryStringValid = useSelector(selectIsCurrentMergeSearchQueryValid)
  const hasPendingSearch = useSelector(selectHasPendingSearch)
  const currentMergeSearch = useSelector(selectCurrentMergeSearch) || emptyObj
  const {
    loading: searchLoading = true,
    loaded: searchLoaded = false,
    errored: hasSearchErrored,
    hasAllPages = false,
  } = currentMergeSearch

  if (hasSearchErrored)
    return (
      <div css={styles.errorContainer}>
        <SearchErrorMessage />
      </div>
    )

  if (!isQueryStringValid) {
    return (
      <div className="grui mb-8" css={styles.errorContainer}>
        <InvalidSearchMessage />
      </div>
    )
  }

  if (hasPendingSearch || (searchLoading && getLength(searchResults) === 0)) {
    return <Spinner className="grui mt-14 mb-14" />
  }

  if (getLength(searchResults) <= 0) return <NoResults />

  return (
    <Options
      hasMore={!hasAllPages}
      delayedTicketIds={delayedTicketIds}
      ignoreTabEnter={ignoreTabEnter}
      isLoadingMore={searchLoading && searchLoaded}
      items={searchResults}
      loadMore={onLoadMoreSearch}
      onDown={onDown}
      onSelect={onSelect}
      onUp={onUp}
    />
  )
}

export default List
