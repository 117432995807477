import {
  CONTACT_CREATE_PAGE,
  CONTACT_EDIT_PAGE,
  CONTACTS_PAGE,
} from 'ducks/crm/pages'

export const doOpenCreateContactPage = () => {
  return {
    type: CONTACT_CREATE_PAGE,
  }
}

export const doOpenEditContactPage = (id, { tab = 'profile' } = {}) => {
  return {
    type: CONTACT_EDIT_PAGE,
    payload: {
      id,
      tab,
    },
  }
}

export const doOpenContactsPage = () => {
  return {
    type: CONTACTS_PAGE,
  }
}
