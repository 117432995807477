import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const fieldWidth = css`
  width: ${rem(300)};
`

const field = css`
  & .form-control {
    pointer-events: auto;
  }

  ${fieldWidth};
`

const midArrows = css`
  display: block;
  margin-left: auto;
`

export const styles = {
  field,
  fieldWidth,
  midArrows,
}
