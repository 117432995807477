import React from 'react'
import { string } from 'prop-types'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useCustomField } from 'ducks/crm/customFields/hooks'
import { useChannel } from 'ducks/channels/hooks'

const DeleteCustomFieldContent = ({ channelId, customFieldKey }) => {
  const { customField, isLoading } = useCustomField(customFieldKey, {
    mode: 'key',
  })
  const { isLoading: channelIsLoading } = useChannel(channelId, {
    useCachedIfAvailable: true,
  })

  if (!customField || isLoading || channelIsLoading) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  return (
    <>
      <p css={text.styles.textNormal} className="grui mt-0">
        You are about to delete the custom field <br />&quot;{customField.name}&quot;
        and <b>all associated data</b>.
      </p>
      <p css={text.styles.textNormal}>
        This action is permanent and cannot be undone.
      </p>
    </>
  )
}

DeleteCustomFieldContent.propTypes = {
  customFieldKey: string.isRequired,
}

DeleteCustomFieldContent.defaultProps = {}

export default DeleteCustomFieldContent
