import { useCallback, useMemo, useState } from 'react'
import { emptyArr } from 'util/arrays'

export const DROPDOWN_MODE_STANDARD = 'STANDARD'
export const DROPDOWN_MODE_INDETERMINATE = 'INDETERMINATE'

const useMultiSelect = (
  options = emptyArr,
  onChange,
  checkedList = emptyArr,
  mode = DROPDOWN_MODE_STANDARD
) => {
  const [initialCheckedList] = useState(checkedList)
  // If check all items
  const allChecked = useMemo(() => checkedList.length === options.length, [
    checkedList,
    options,
  ])

  const allIndeterminate = useMemo(
    () => {
      // Should check indeterminate when options are changed
      return checkedList.length < options.length && checkedList.length > 0
    },
    [checkedList.length, options.length]
  )

  // Handle check one item
  const handleChange = useCallback(
    e => {
      let list = null
      const { checked, id } = e.target

      if (mode === DROPDOWN_MODE_STANDARD) {
        if (checked) {
          list = [...new Set(checkedList).add(id)]
        } else {
          list = checkedList.filter(itemId => itemId !== id)
        }
        // Keep the order as in the options
        list.sort((a, b) => {
          return (
            options.findIndex(item => item.id === a) -
            options.findIndex(item => item.id === b)
          )
        })
      } else if (mode === DROPDOWN_MODE_INDETERMINATE) {
        let currentItem = checkedList.find(item => item.id === id)
        const { state: initialState } = initialCheckedList.find(
          item => item.id === id
        ) || { id, state: false }
        if (!currentItem) {
          currentItem = {
            id,
            state: false,
          }
          list = [...checkedList, currentItem]
        } else {
          list = [...checkedList]
        }
        const index = list.indexOf(currentItem)
        const { state } = currentItem
        if (state === true) {
          list[index] = { id, state: false }
        } else if (state === null) {
          list[index] = { id, state: true }
        } else if (state === false && initialState === null) {
          list[index] = { id, state: null }
        } else {
          list[index] = { id, state: true }
        }
        list.sort((a, b) => {
          return (
            options.findIndex(item => item.id === a.id) -
            options.findIndex(item => item.id === b.id)
          )
        })
      }

      onChange(list || checkedList)
    },
    [checkedList, onChange, options, mode, initialCheckedList]
  )

  // Handle select all
  const handleCheckAllChange = useCallback(
    e => {
      if (mode === DROPDOWN_MODE_STANDARD) {
        onChange(e.target.checked ? options.map(o => o.id) : [])
      } else {
        onChange(options.map(o => ({ id: o.id, state: e.target.checked })))
      }
    },
    [options, onChange, mode]
  )

  // Handle select an item
  const handleToggleItem = useCallback(
    id => {
      let checked = false
      if (mode === DROPDOWN_MODE_INDETERMINATE) {
        const currentItem = checkedList.find(item => item.id === id)
        checked = !currentItem?.state
      } else {
        checked = !checkedList.includes(id)
      }
      handleChange({
        target: { checked, id },
      })
    },
    [handleChange, checkedList, mode]
  )

  return {
    indeterminate: allIndeterminate,
    allChecked,
    handleCheckAllChange,
    handleChange,
    handleToggleItem,
    checkedList,
    initialCheckedList,
  }
}

export default useMultiSelect
