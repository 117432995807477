import { connect } from 'react-redux'
import { doStopEditingTicketTitle } from 'actions/app'
import { emptyObj } from 'util/objects'

import { doTrash } from 'ducks/tickets/actions/doTrash'
import { doDelete } from 'ducks/tickets/actions/doDelete'
import { doSpam } from 'ducks/tickets/actions/doSpam'
import { doOpenNextTicketPage } from 'ducks/tickets/actions/doOpenNextTicketPage'
import { doOpenPreviousTicketPage } from 'ducks/tickets/actions/doOpenPreviousTicketPage'
import { doOpenTicketMergeDrawer } from 'actions/tickets'
import {
  doOpenChangeInboxMenu,
  doOpenMoreTicketOptionsMenu,
} from 'actions/menus'
import { doStar } from 'ducks/tickets/actions/doStar'
import { doUnstar } from 'ducks/tickets/actions/doUnstar'
import {
  selectIsEditingTicketTitle,
  selectIsRightSidebarCollapsed,
} from 'selectors/app'
import { selectCurrentTicketAssignedGroupId } from 'ducks/tickets/selectors/selectCurrentTicketAssignedGroupId'
import { selectMergingIsMerging } from 'ducks/merging/selectors/base'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'
import { selectIsTagsAssignOpen } from 'selectors/modals_base'
import { doShowTagsAssignModal, doHideTagsAssignModal } from 'actions/modals'
import ConversationHeader from 'components/ConversationHeader'
import StateButton from 'components/ConversationHeader/Buttons/StateButton'
import TagsButton from 'components/ConversationHeader/Buttons/TagsButton'
import SnoozeButton from 'components/ConversationHeader/Buttons/SnoozeButton'
import StarButton from 'components/ConversationHeader/Buttons/StarButton'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { doSnooze } from 'ducks/tickets/actions/doSnooze'
import { doUnsnooze } from 'ducks/tickets/actions/doUnsnooze'
import { doRestore } from 'ducks/tickets/actions/doRestore'
import {
  isCloseable,
  isDeleted,
  isSnoozed,
  isSpam,
  isStarred,
} from 'ducks/tickets/utils/state'
import { doOpen } from 'ducks/tickets/actions/doOpen'
import { doClose } from 'ducks/tickets/actions/doClose'
import { doToggleTicketState } from 'ducks/tickets/actions/doToggleTicketState'
import { selectCurrentTicketAssignmentLabel } from 'ducks/tickets/selectors/selectCurrentTicketAssignmentLabel'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import AssignmentButton from './Buttons/AssignmentButton'
import MoreButton from './Buttons/MoreButton'
import PagingButtons from './Buttons/PagingButtons'
import BackButton from './Buttons/BackButton'

const select = state => {
  const ticketId = selectCurrentTicketId(state)
  const ticket = selectCurrentConversation(state) || emptyObj
  const assignmentAgentName = selectCurrentTicketAssignmentLabel(state)
  const assignedGroupId = selectCurrentTicketAssignedGroupId(state)
  const isAssigned = !!assignmentAgentName
  const isAdminOrOwnerOrAgent = selectCurrentUserIsAdminOrOwnerOrAgent(state)
  return {
    conversationId: ticketId,
    isAssigned,
    assignmentAgentName,
    assignedGroupId,
    isEditingTitle: selectIsEditingTicketTitle(state),
    isRightSidebarCollapsed: selectIsRightSidebarCollapsed(state),
    isStarred: isStarred(ticket),
    is3ColumnView: selectPrefersClassicView(state),
    isMergeOpen: selectMergingIsMerging(state),
    isSnoozed: isSnoozed(ticket),
    isTagsAssignModalOpen: selectIsTagsAssignOpen(state),
    stateLabel: ticket.state,
    snoozedUntil: ticket.snoozed?.until,
    isSpam: isSpam(ticket),
    isDeleted: isDeleted(ticket),
    isCloseable: isCloseable(ticket.state),
    StateButton,
    TagsButton,
    SnoozeButton,
    StarButton,
    AssignmentButton,
    BackButton,
    MoreButton,
    PagingButtons,
    disableMerge: !isAdminOrOwnerOrAgent,
    disableStar: !isAdminOrOwnerOrAgent,
    disableTags: !isAdminOrOwnerOrAgent,
    disableSpam: !isAdminOrOwnerOrAgent,
    disableDelete: !isAdminOrOwnerOrAgent,
    disableMore: !isAdminOrOwnerOrAgent,
    disableSnooze: !isAdminOrOwnerOrAgent,
    disableState: !isAdminOrOwnerOrAgent,
    disableAssignment: !isAdminOrOwnerOrAgent,
    disableHardDelete: !isAdminOrOwnerOrAgent,
    disableRestore: !isAdminOrOwnerOrAgent,
  }
}

const perform = {
  onStopEditingTitle: doStopEditingTicketTitle,
  onToggleState: doToggleTicketState,
  onClose: doClose,
  onSpam: doSpam,
  onOpen: doOpen,
  onStar: doStar,
  onUnstarred: doUnstar,
  onOpenChangeInboxMenu: doOpenChangeInboxMenu,
  onOpenConversationOptionsMenu: doOpenMoreTicketOptionsMenu,
  onSnooze: doSnooze,
  onOpenMerge: doOpenTicketMergeDrawer,
  onUnsnooze: doUnsnooze,
  onSoftDelete: doTrash,
  onHardDelete: doDelete,
  onRestoreDeleted: doRestore,
  onRestoreSpam: doRestore,
  onOpenNextConversation: doOpenNextTicketPage,
  onOpenPreviousConversation: doOpenPreviousTicketPage,
  onShowTagsAssignModal: doShowTagsAssignModal,
  onHideTagsAssignModal: doHideTagsAssignModal,
}

export default connect(select, perform)(ConversationHeader)
