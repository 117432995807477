import {
  RESTRICTION_TYPE_EVERYONE,
  RESTRICTION_TYPE_GROUP,
  RESTRICTION_TYPE_USERS,
  RESTRICTION_TYPE_ME,
} from 'ducks/teams/constants'

export const USERS_RANGES = {
  EVERYONE: RESTRICTION_TYPE_EVERYONE,
  AGENTS: RESTRICTION_TYPE_USERS,
  ME: RESTRICTION_TYPE_ME,
  // For AddAgents in Channels
  TEAMS: RESTRICTION_TYPE_GROUP,
}

export const userRanges = [
  {
    id: USERS_RANGES.AGENTS,
    name: `Select ${app.t('agents')}`,
  },
  {
    id: USERS_RANGES.TEAMS,
    name: `Select ${app.t('teams')}`,
  },
  {
    id: USERS_RANGES.EVERYONE,
    name: 'Everyone',
  },
  {
    id: USERS_RANGES.ME,
    name: 'Only me',
  },
]
