import React, { useMemo } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { propFunc } from 'util/functions'
import { RULE_STATE } from 'ducks/rules/constants'
import CellWithAgentAvatar from '../CellWithAgentAvatar'
import { styles } from './styles'

const { CellWithButton, RowControls } = Table

const useColumns = ({ compact, disableReorder } = {}) => {
  const columns = useMemo(
    () => {
      const formatName = ({
        row: { original },
        value,
        onEdit,
        selectedFlatRows,
      }) => {
        const contentWithTooltip = (
          <Tooltip
            title="This is an Advanced Rule, making use of our most powerful automation features"
            strategy="fixed"
            className="grui py-8"
            css={[text.styles.fontMedium, styles.limitedTooltip]}
          >
            <span>
              {value}
              {original.limited && (
                <span
                  className="grui pl-3 align-middle"
                  css={text.styles.textNormal}
                >
                  ⚡
                </span>
              )}
            </span>
          </Tooltip>
        )
        return (
          <CellWithButton
            css={
              original.state === RULE_STATE.INACTIVE &&
              styles.hiddenRuleNameCell
            }
            onClick={propFunc(onEdit, original.id)}
            disabled={selectedFlatRows.length > 0}
          >
            {original.limited ? contentWithTooltip : value}
          </CellWithButton>
        )
      }

      const formatCreator = ({ value: agent }) => {
        return <CellWithAgentAvatar agentId={agent?.id} />
      }

      const formatDate = ({
        row: { original },
        value,
        deleteData,
        onEdit,
        selectedFlatRows,
        dragHandle,
        sortDisableRef,
        page,
      }) => {
        let component = null
        const controlsDisabled = selectedFlatRows.length > 0
        if (!value) {
          component = (
            <div css={[text.styles.textMediumDark, text.styles.italic]}>
              Never used
            </div>
          )
        } else {
          component = (
            <time dateTime={moment(value).format('MM-DD-YYYY')}>
              {moment(value).format('DD MMM YYYY')}
            </time>
          )
        }

        return (
          <>
            {compact && (
              <RowControls
                className="controls"
                value={original.id}
                deleteData={deleteData}
                onEdit={onEdit}
                disabled={controlsDisabled}
                sortable={!disableReorder && page.length > 1}
                dragHandle={dragHandle}
                disableReorder={sortDisableRef.current}
              />
            )}
            <div
              className={cn('components', {
                'controls-disabled': controlsDisabled,
              })}
            >
              {component}
            </div>
          </>
        )
      }

      const formatRunCount = ({ value }) => {
        if (!value)
          return (
            <div css={[text.styles.textMediumDark, text.styles.italic]}>
              Never run
            </div>
          )
        return <div>{Number(value).toLocaleString()}</div>
      }

      const createRowControls = ({
        value,
        deleteData,
        onEdit,
        selectedFlatRows,
        dragHandle,
        sortDisableRef,
        page,
      }) => {
        return (
          <RowControls
            name="rules"
            value={value}
            deleteData={deleteData}
            onEdit={onEdit}
            disabled={selectedFlatRows.length > 0}
            sortable={!disableReorder && page.length > 1}
            disableReorder={sortDisableRef.current}
            dragHandle={dragHandle}
          />
        )
      }

      return [
        {
          Header: 'NAME',
          accessor: 'name',
          Cell: formatName,
          width: 300,
          manualSortBy: true,
          disableSortBy: true,
        },
        {
          Header: 'COUNT',
          accessor: 'totalExecutions',
          Cell: formatRunCount,
          width: '14%',
          manualSortBy: true,
          disableSortBy: true,
        },
        {
          Header: 'CREATOR',
          accessor: 'creator',
          Cell: formatCreator,
          id: 'creator',
          width: '22%',
          manualSortBy: true,
          disableSortBy: true,
        },
        {
          Header: 'CREATED',
          accessor: 'createdAt',
          Cell: formatDate,
          id: 'created_at',
          width: '15%',
          manualSortBy: true,
          className: 'row-controls',
          minWidth: compact ? 120 : undefined,
          disableSortBy: true,
        },
        !compact && {
          Header: '',
          accessor: 'id',
          Cell: createRowControls,
          disableSortBy: true,
        },
      ].filter(Boolean)
    },
    [compact, disableReorder]
  )

  return columns
}

export default useColumns
