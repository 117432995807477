import {
  ACCEPT_INVITE_PAGE,
  ADD_TEAM_MEMBERS_PAGE,
  CUSTOMER_INFORMATION_PAGE,
  SIGNUP_PAGE,
  FACEBOOK_PAGE_SELECTION_PAGE,
} from './pages'

import { doFetchFacebookPages } from '../ducks/facebook'

const V1_ROUTES_CONFIG = {
  [SIGNUP_PAGE]: {
    route: {
      path: '/signup/f1',
    },
    handler: () => ({
      componentName: 'SignupPage',
      subapp: 'onboarding',
    }),
  },

  [CUSTOMER_INFORMATION_PAGE]: {
    route: {
      path: '/setup/f1/your-business',
    },
    handler: () => ({
      componentName: 'CustomerInformationPage',
      subapp: 'onboarding',
    }),
  },

  [ADD_TEAM_MEMBERS_PAGE]: {
    route: {
      path: '/setup/add-team',
    },
    handler: () => ({
      componentName: 'AddTeamMembersPage',
      subapp: 'onboarding',
    }),
  },

  [ACCEPT_INVITE_PAGE]: {
    route: {
      path: '/users/invitation/accept',
      fromPath: segment => `${segment}`,
    },
    handler: () => ({
      componentName: 'AcceptInvitePage',
      subapp: 'onboarding',
    }),
  },

  [FACEBOOK_PAGE_SELECTION_PAGE]: {
    route: {
      path: '/facebook-pages',
      thunk: doFetchFacebookPages(),
    },
    handler: () => ({
      componentName: 'FacebookPageSelectionPage',
      subapp: 'onboarding',
    }),
  },
}

export default V1_ROUTES_CONFIG
