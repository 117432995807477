import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { string, bool, oneOf } from 'prop-types'
import {
  doDeleteFolders,
  doFetchFolderByIdsV2,
  doFetchFolders,
} from 'ducks/folders/operations/folders'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { DRAWER_TYPE_FOLDERS_OVERVIEW } from 'ducks/drawers/types'
import { selectIsInInbox } from 'selectors/location'
import EntityDeleteDrawer from '../EntityDelete'
import FolderDeleteContent from './Content'

const FolderDeleteDrawer = props => {
  const {
    title,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    previousDrawer,
    onClose,
    onExit,
    onRemove,
  } = props

  const dispatch = useDispatch()
  const isInInbox = useSelector(selectIsInInbox)

  const ids = useMemo(
    () => {
      return drawerResourceId.split(',')
    },
    [drawerResourceId]
  )
  const handleOnRemove = useCallback(
    async () => {
      const response = await dispatch(doDeleteFolders(ids))
      // Fetch folders and rebuild inbox menu if it's in the inbox page
      // and this drawer was opened directly without through the overview drawer
      if (
        isInInbox &&
        previousDrawer?.drawer !== DRAWER_TYPE_FOLDERS_OVERVIEW
      ) {
        await dispatch(doFetchFolders())
        dispatch(doBuildInboxMenuFromMailboxes())
      }
      if (onRemove) onRemove()
      return response
    },
    [dispatch, ids, onRemove, previousDrawer?.drawer, isInInbox]
  )

  useEffect(
    () => {
      if (isInInbox && !previousDrawer?.drawerId) {
        // We need to add folder ids to the entities.current state here
        // if we open this drawer without through folders table
        // otherwise FolderDeleteContent will get empty entitiesForIds
        dispatch(doFetchFolderByIdsV2(ids, { targetStore: 'current' }))
      }
    },
    [isInInbox, dispatch, ids, previousDrawer?.drawerId]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={onClose}
      onExit={onExit}
      onCancel={onClose}
      title={title}
      deleteBtnText={deleteBtnText}
      deleteBtn
      tertiaryBtnText={'Cancel'}
      open={open}
      skipProceedQuestion
      hardDelete
    >
      <FolderDeleteContent ids={ids} deleteMode={drawerDeleteMode} />
    </EntityDeleteDrawer>
  )
}

FolderDeleteDrawer.propTypes = {
  title: string,
  deleteBtnText: string,
  open: bool.isRequired,
  drawerDeleteMode: oneOf(['ids', 'query']),
  drawerResourceId: string,
}

FolderDeleteDrawer.defaultProps = {
  title: `Delete ${app.t('folder')}`,
  deleteBtnText: 'Delete',
  drawerDeleteMode: 'ids',
  drawerResourceId: '',
  queryId: null,
}

export default FolderDeleteDrawer
