import { OPERATOR_TYPE_EQ } from 'util/operators'
import { equalsCaseInsensitive } from 'util/strings'

export const hasTicketStateTypeCondition = (conditions, ticketStateType) => {
  return (conditions || []).some(
    c =>
      equalsCaseInsensitive(c.param, 'status') &&
      equalsCaseInsensitive(c.operator, OPERATOR_TYPE_EQ) &&
      equalsCaseInsensitive(c.value, ticketStateType)
  )
}
