import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentTicketIsDelayed } from 'ducks/undoSend/selectors'

import { doUpdateMergeTicket } from 'ducks/merging/operations'

import SearchableList from './SearchableList'

const SelectTickets = ({ onSelect }) => {
  const isDelayed = useSelector(selectCurrentTicketIsDelayed)

  const dispatch = useDispatch()

  const handleSelect = useCallback(
    ticket => {
      onSelect()
      dispatch(
        doUpdateMergeTicket({
          id: ticket.id,
          title: ticket.subject,
        })
      )
    },
    [dispatch, onSelect]
  )

  return (
    <div data-test-id="ticket-merge-selector">
      {isDelayed && (
        <div className="grui my-12 text-center">
          {`Please wait until ${app.t('ticket')} is sent...`}
        </div>
      )}
      {!isDelayed && (
        <SearchableList onSelect={handleSelect} placeholder="Search..." />
      )}
    </div>
  )
}

export default SelectTickets
