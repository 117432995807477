import {
  SETTINGS_CRM_PAGE,
  SETTINGS_CHANNELS_PAGE,
  SETTINGS_INBOX_MANAGEMENT_PAGE,
  SETTINGS_INTEGRATIONS_ADD_NEW_PAGE,
  SETTINGS_INTEGRATIONS_EDIT_PAGE,
  SETTINGS_PROFILE_PAGE,
  SETTINGS_CHAT_MANAGEMENT_PAGE,
  SETTINGS_WIDGET_MANAGEMENT_PAGE,
  SETTINGS_WIDGET_CREATE_NEW_PAGE,
} from 'subapps/settings/types'
import { getRawId } from 'util/globalId'
import { isChatChannelType } from 'ducks/channels/channelTypes'

export function doRedirectToCustomFieldsContactTab() {
  return dispatch => {
    return dispatch({
      type: SETTINGS_CRM_PAGE,
      payload: { tab: 'contact-properties' },
    })
  }
}

export const doOpenEditChannelPage = (
  id,
  { tab = 'general', channelType } = {}
) => {
  return dispatch => {
    dispatch({
      type: isChatChannelType(channelType)
        ? SETTINGS_CHAT_MANAGEMENT_PAGE
        : SETTINGS_INBOX_MANAGEMENT_PAGE,
      payload: {
        id,
        tab,
      },
    })
  }
}

export const doOpenChannelsPage = () => {
  return dispatch => {
    dispatch({
      type: SETTINGS_CHANNELS_PAGE,
      payload: {
        tab: 'inboxes',
      },
    })
  }
}

export const doOpenNewIntegrationPage = ({ provider }) => ({
  type: SETTINGS_INTEGRATIONS_ADD_NEW_PAGE,
  payload: {
    provider,
  },
})

export const doOpenEditIntegrationPage = ({ provider, id }) => ({
  type: SETTINGS_INTEGRATIONS_EDIT_PAGE,
  payload: {
    id,
    provider,
  },
})

export const toInboxesPage = {
  type: SETTINGS_CHANNELS_PAGE,
  payload: {
    tab: 'inboxes',
  },
}
export const toSettingsProfilePage = { type: SETTINGS_PROFILE_PAGE }

export const doOpenProfileBasicPage = () => {
  return dispatch => {
    dispatch({
      type: SETTINGS_PROFILE_PAGE,
      payload: {},
    })
  }
}

export const doOpenEditWidgetPage = widgetId => ({
  type: SETTINGS_WIDGET_MANAGEMENT_PAGE,
  payload: {
    id: getRawId(widgetId),
  },
})

export const doOpenNewWidgetPage = () => ({
  type: SETTINGS_WIDGET_CREATE_NEW_PAGE,
})

export const to2faPage = {
  type: SETTINGS_PROFILE_PAGE,
  payload: {
    tab: '2fa',
  },
}
