import React from 'react'
import { useSelector } from 'react-redux'
import Link from 'redux-first-router-link'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectInaccessibleMailbox } from 'ducks/mailboxes/selectors/selectInaccessibleMailbox'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import {
  SETTINGS_CHANNELS_PAGE,
  SETTINGS_INBOX_MANAGEMENT_PAGE,
} from 'subapps/settings/types'
import accessAgentImage from 'assets/chat/illustrations/access-agent@2x.png'
import accessAdminImage from 'assets/chat/illustrations/access-admin@2x.png'
import { styles } from './styles'
import AdminList from './AdminList'

const NoAccess = ({ mailboxId }) => {
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const mailbox = useSelector(state =>
    selectInaccessibleMailbox(state, mailboxId)
  )

  const mailboxName = mailbox?.name || ''

  const info = isManager
    ? `Please click the button below to go to your inbox settings and grant
    yourself access to the ${mailboxName} ${app.t('mailbox')}`
    : `You currently don't have permissions to view conversations in the ${mailboxName} ${app.t(
        'mailbox'
      )}. Please contact one of the admins listed below to grant you access`

  const inboxSettingLink = mailboxId ? (
    <Link
      to={{
        // Inbox access tab
        type: SETTINGS_INBOX_MANAGEMENT_PAGE,
        payload: {
          id: mailboxId,
          tab: 'access',
        },
      }}
    >
      <Button className="grui mt-8">Update access permission</Button>
    </Link>
  ) : (
    <Link
      to={{
        // Inboxes overview page
        type: SETTINGS_CHANNELS_PAGE,
        payload: {
          tab: 'inboxes',
        },
      }}
    >
      <Button className="grui mt-8">Update access permission</Button>
    </Link>
  )

  return (
    <div className="grui flex items-center justify-center h-100">
      <div css={styles.content}>
        <img
          src={isManager ? accessAdminImage : accessAgentImage}
          width="203"
          height="178"
          aria-hidden
          alt="No access"
        />
        <h1 css={heading.styles.h2}>
          Oh no, You don&#39;t have access to this {app.t('mailbox')}
        </h1>
        <p css={[text.styles.textNormal, text.styles.textDark]}>{info}</p>
        {isManager ? inboxSettingLink : <AdminList className="grui mt-15" />}
      </div>
    </div>
  )
}

export default NoAccess
