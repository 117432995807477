import { produce } from 'immer'

// Given an initial state and an object of reducer handlers, keyed by action
// type, this function will produce a new state for the respective action handler.
export default function createActionTypeReducer(
  inputHandlers,
  initialState = undefined
) {
  const handlers = Object.keys(inputHandlers).reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = Array.isArray(inputHandlers[key])
      ? inputHandlers[key]
      : [inputHandlers[key]]

    if (key !== '*' && inputHandlers['*']) {
      const starHandlers = Array.isArray(inputHandlers['*'])
        ? inputHandlers['*']
        : [inputHandlers['*']]

      starHandlers.forEach(handler => result[key].push(handler))
    }
    return result
  }, {})
  return produce((draftState, action, sharedState = {}) => {
    const subHandlers = handlers[action.type] || handlers['*']
    if (subHandlers) {
      let lastResponse = draftState
      subHandlers.forEach(subHandler => {
        lastResponse = subHandler(lastResponse, action, sharedState)
        if (lastResponse === undefined) {
          lastResponse = draftState
        }
      })
      return lastResponse
    }
    return draftState
  }, initialState)
}
