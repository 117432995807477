/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react'
import { func, oneOf } from 'prop-types'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import DropdownMenu from '../DropdownMenu'
import { userRanges, USERS_RANGES } from './data'

const UserRestrictionTypeDropdown = ({ selectedId, onKeyChange }) => {
  const selectedType = useMemo(
    () => {
      const selected = userRanges.find(item => item.id === selectedId) || {}
      return selected.name
    },
    [selectedId]
  )
  return (
    <Dropdown
      overlay={<DropdownMenu data={userRanges} />}
      onSelect={onKeyChange}
      selectedKey={selectedId}
    >
      <Dropdown.Button>{selectedType}</Dropdown.Button>
    </Dropdown>
  )
}

UserRestrictionTypeDropdown.propTypes = {
  selectedId: oneOf(Object.values(USERS_RANGES)),
  onKeyChange: func,
}

UserRestrictionTypeDropdown.defaultProps = {
  selectedId: userRanges[0].id,
  onKeyChange() {},
}

export { userRanges, USERS_RANGES } from './data'
export default UserRestrictionTypeDropdown
