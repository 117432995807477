// Dropdown Switcher that emulates an in-place 'sub-menu'
import React, { useMemo } from 'react'

import ChangeInboxDropdown from 'components/App/DesktopView/shared/ChangeInboxDropdown'
import MainDropdown from './MainDropdown'
import Trigger from './shared/Trigger'

const Switcher = ({
  doCloseChangeInboxMenu,
  doCloseMoreTicketOptionsMenu,
  doOpenChangeInboxMenu,
  doOpenMoreTicketOptionsMenu,
  isChangeMenuOpen,
  isMoreTicketOptionsOpen,
  ticketId,
  trigger,
}) => {
  let triggerLink = null
  const ticketIds = useMemo(() => [ticketId], [ticketId])
  if (trigger) {
    triggerLink = (
      <a className="menu-button" onClick={doOpenMoreTicketOptionsMenu}>
        {trigger}
      </a>
    )
  }
  if (!isMoreTicketOptionsOpen && !isChangeMenuOpen) {
    if (triggerLink) return triggerLink
    return (
      <div onClick={doOpenMoreTicketOptionsMenu}>
        <Trigger />
      </div>
    )
  }

  if (isChangeMenuOpen) {
    return (
      <ChangeInboxDropdown
        key={ticketId}
        onBlur={doCloseChangeInboxMenu}
        onClose={doCloseChangeInboxMenu}
        onOpen={doOpenChangeInboxMenu}
        open
        ticketIds={ticketIds}
        trigger={triggerLink || <Trigger />}
      />
    )
  }

  return (
    <MainDropdown
      doOpenChangeInboxMenu={doOpenChangeInboxMenu}
      onBlur={doCloseMoreTicketOptionsMenu}
      onClose={doCloseMoreTicketOptionsMenu}
      onOpen={doOpenMoreTicketOptionsMenu}
      open={isMoreTicketOptionsOpen}
      ticketId={ticketId}
      trigger={triggerLink || <Trigger />}
    />
  )
}

export default Switcher
