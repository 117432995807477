import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import {
  ArrowUpDown,
  ArrowLeft,
} from '@groovehq/internal-design-system/lib/assets/icons'
import { AgentAdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { MAX_MERGE_CONTACTS_SIZE } from 'ducks/crm/contacts/constants'
import { FETCH_CONTACT_CUSTOM_FIELD_VALUES } from 'ducks/crm/contacts/types'
import useFetchEntityById from 'ducks/entities/hooks/useFetchEntityById'
import { doFetchContactCustomFieldValuesForId } from 'ducks/crm/contacts/operations/fetching/doFetchContactCustomFieldValuesForId'
import { useConfirmHoldsCallback, useRhfDirtyHold } from 'util/dirtyHolds'
import { useLoadCustomFieldsForType } from 'ducks/crm/customFields/hooks'
import {
  CUSTOM_FIELD_CATEGORY_DISPLAY_EXCLUDE_KEYS,
  CUSTOM_FIELD_CATEGORY_TYPE_CONTACT,
} from 'ducks/crm/customFieldCategories/constants'
import { doMergeContactFromForm } from 'ducks/crm/contacts'
import { doOpenEditContactPage } from 'subapps/crm/actions/redirect'
import debug from 'util/debug'
import ContactCard from './ContactCard'
import FieldSelection from './FieldSelection'
import { styles } from './styles'

const REQUEST_TARGET_STORE = 'pending'

const FORM_KEY_SOURCE_ID = 'sourceId'
const FORM_KEY_TARGET_ID = 'targetId'
const FORM_KEY_KEEP_FIELDS = 'keepFields'

const FORM_SCHEMA = yup.object().shape({
  [FORM_KEY_SOURCE_ID]: yup.string().required(),
  [FORM_KEY_TARGET_ID]: yup.string().required(),
  // keeping it as a generic key-value object
  // custom_field_id: [true/false]
  // will automatically get entered when the radio button is registered and changed
  [FORM_KEY_KEEP_FIELDS]: yup.object(),
})

const ContactMergeDrawer = ({
  drawerId,
  drawerResourceId,
  open,
  onClose,
  onExit,
}) => {
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const ids = useMemo(
    () => {
      const resourceIds = drawerResourceId ? drawerResourceId.split(',') : []

      if (resourceIds.length > MAX_MERGE_CONTACTS_SIZE) {
        return resourceIds.slice(0, MAX_MERGE_CONTACTS_SIZE)
      }

      return resourceIds
    },
    [drawerResourceId]
  )

  const contact1Id = ids[0]
  const contact2Id = ids[1]

  const contact1RequestKey = `${FETCH_CONTACT_CUSTOM_FIELD_VALUES}_${contact1Id}`
  const contact2RequestKey = `${FETCH_CONTACT_CUSTOM_FIELD_VALUES}_${contact2Id}`

  const handleDoFetchContact1 = useCallback(
    ({ id }) =>
      doFetchContactCustomFieldValuesForId(id, {
        targetStore: REQUEST_TARGET_STORE,
        requestKey: contact1RequestKey,
      }),
    [contact1RequestKey]
  )

  const handleDoFetchContact2 = useCallback(
    ({ id }) =>
      doFetchContactCustomFieldValuesForId(id, {
        targetStore: REQUEST_TARGET_STORE,
        requestKey: contact2RequestKey,
      }),
    [contact2RequestKey]
  )

  const {
    entity: contact1,
    requestState: {
      loading: isLoadingContact1,
      loaded: hasLoadedContact1,
      error: hasErrorContact1,
    } = {},
  } = useFetchEntityById(
    contact1Id,
    'contacts',
    handleDoFetchContact1,
    contact1RequestKey,
    REQUEST_TARGET_STORE
  )

  const {
    entity: contact2,
    requestState: {
      loading: isLoadingContact2,
      loaded: hasLoadedContact2,
      error: hasErrorContact2,
    } = {},
  } = useFetchEntityById(
    contact2Id,
    'contacts',
    handleDoFetchContact2,
    contact2RequestKey,
    REQUEST_TARGET_STORE
  )

  const {
    isLoading: isLoadingCustomFields,
    hasError: hasErrorCustomFields,
    isLoaded: hasLoadedCustomFields,
    customFields,
  } = useLoadCustomFieldsForType(CUSTOM_FIELD_CATEGORY_TYPE_CONTACT, {
    excludeCategoryKeys: CUSTOM_FIELD_CATEGORY_DISPLAY_EXCLUDE_KEYS,
    forDisplay: true,
  })

  const isLoading =
    isLoadingContact1 || isLoadingContact2 || isLoadingCustomFields
  const hasError = hasErrorContact1 || hasErrorContact2 || hasErrorCustomFields
  const hasLoaded =
    hasLoadedContact1 && hasLoadedContact2 && hasLoadedCustomFields
  const isMergingToSelf = contact1Id === contact2Id
  const isNoResultFound =
    !isLoading && hasLoaded && (!contact1 || !contact2 || !customFields?.length)

  const useFormMethods = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      [FORM_KEY_SOURCE_ID]: null,
      [FORM_KEY_TARGET_ID]: null,
      [FORM_KEY_KEEP_FIELDS]: {},
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    control,
    setValue,
  } = useFormMethods

  const sourceId = useWatch({ control, name: FORM_KEY_SOURCE_ID })
  const targetId = useWatch({ control, name: FORM_KEY_TARGET_ID })

  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)

  const handleOnClose = useConfirmHoldsCallback(holdKey, onClose, [onClose])

  const onChangeDirectionClick = useCallback(
    () => {
      setValue(FORM_KEY_SOURCE_ID, targetId)
      setValue(FORM_KEY_TARGET_ID, sourceId)
    },
    [setValue, sourceId, targetId]
  )

  const onSubmit = useCallback(
    async formData => {
      try {
        const {
          [FORM_KEY_SOURCE_ID]: formSourceId,
          [FORM_KEY_TARGET_ID]: formTargetId,
          [FORM_KEY_KEEP_FIELDS]: keepFields,
        } = formData

        setIsSaving(true)
        let keepFieldIds = []
        const keepNone = Object.values(keepFields).every(keep => !keep)

        if (!keepNone) {
          keepFieldIds = Object.entries(keepFields)
            .filter(([, keep]) => keep)
            .map(([id]) => id)
        }

        await dispatch(
          doMergeContactFromForm(formSourceId, formTargetId, keepFieldIds)
        )
        releaseHold()
        setIsSaving(false)
        onClose()
        dispatch(doOpenEditContactPage(formTargetId))
      } catch (e) {
        debug('Failed to save folder', { error: e, formData })
        setIsSaving(false)
      }
    },
    [onClose, releaseHold, dispatch]
  )

  const defaultKeepFields = useMemo(
    () => {
      if (!customFields) return {}

      return customFields.reduce((acc, customField) => {
        const { id } = customField || {}
        // eslint-disable-next-line no-param-reassign
        acc[id] = false
        return acc
      }, {})
    },
    [customFields]
  )

  useEffect(
    () => {
      if (!isNoResultFound) {
        reset(
          {
            [FORM_KEY_SOURCE_ID]: contact1?.id,
            [FORM_KEY_TARGET_ID]: contact2?.id,
            [FORM_KEY_KEEP_FIELDS]: defaultKeepFields,
          },
          { keepDefaultValues: true }
        )
      }
    },
    [reset, isNoResultFound, contact1, contact2, defaultKeepFields]
  )

  const formId = `${drawerId}-${drawerResourceId}-form`

  return (
    <AgentAdminAccessDrawer
      title="Merge Customers"
      open={open}
      onClose={onExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isNoResultFound}
      size="wide"
      footer={
        <ModalBtns
          saveBtnDisabled={
            isLoading ||
            hasError ||
            !isValid ||
            !isDirty ||
            isSaving ||
            isMergingToSelf
          }
          saveBtnText={
            <>
              {isSaving && (
                <span>
                  Merging<AnimatedEllipsis />
                </span>
              )}
              {!isSaving && 'Merge'}
            </>
          }
          saveBtnForm={formId}
          saveBtnHtmlType="submit"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
        />
      }
    >
      <div className="grui flex flex-col h-100 pt-10">
        {isMergingToSelf && (
          <MessageCard type="negative">
            You cannot merge a customer record with itself.
            <br />
            Please select a different customer record to proceed with the merge
            operation.
          </MessageCard>
        )}
        {!isMergingToSelf && (
          <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <p css={[paragraph.styles.preflight, text.styles.textSm]}>
              Merging combines the information from your two selected customers.
              New and existing conversations will be assigned automatically to
              the merged customer.
            </p>

            <div className="grui mt-10">
              <div css={text.styles.fontSemibold}>Merge:</div>
              <ContactCard contactId={sourceId} />
              <div
                css={styles.label}
                className="grui flex justify-center items-center mt-6 mb-6"
              >
                <div css={styles.arrowDown}>
                  <ArrowLeft
                    className="grui transform-rotate270"
                    width="2em"
                    height="2em"
                  />
                </div>
                <Tooltip
                  title="Change direction"
                  position="left"
                  strategy="fixed"
                >
                  <Button
                    type="icon"
                    size="small"
                    css={styles.switchButton}
                    onClick={onChangeDirectionClick}
                  >
                    <ArrowUpDown size="small" />
                  </Button>
                </Tooltip>
              </div>
              <ContactCard contactId={targetId} />
            </div>
            <MessageCard type="warning" className="grui mt-10">
              You cannot undo this action. Merging customers may take a few
              seconds.
            </MessageCard>
            <div className="grui mt-10">
              <div css={text.styles.fontSemibold}>
                Select which fields you want the merged customer to have:
              </div>
              <div className="grui mt-10">
                {customFields.map(customField => (
                  <FieldSelection
                    key={customField.id}
                    sourceId={sourceId}
                    targetId={targetId}
                    customField={customField}
                    control={control}
                  />
                ))}
              </div>
            </div>
          </form>
        )}
      </div>
    </AgentAdminAccessDrawer>
  )
}

export default ContactMergeDrawer
