import React, { useMemo } from 'react'
import { Question } from '@groovehq/internal-design-system/lib/assets/icons'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'
import RadioWithBoolValue from 'components/RadioWithBoolValue'
import FormLabel from 'subapps/crm/components/contact/Profile/FormLabel'
import { styles as profileFormStyles } from 'subapps/crm/components/contact/Profile/styles'
import {
  ADDRESS,
  DROPDOWN,
  FILE_TYPE,
  LINK,
  MULTI_LINE,
  MULTI_SELECT,
} from 'ducks/crm/customFields'

import { styles } from './styles'

const COMPONENT_TYPE_TEXTAREA = 'TEXTAREA'
const COMPONENT_TYPE_RADIO = 'AVATAR'
const COMPONENT_TYPE_FIELD = 'FIELD'

export const getCfvValue = (contact, customField, key) => {
  const { type, options = [], isArray } = customField || {}

  if (!contact?.customFieldValues?.[key]) return ''

  const cfv = contact.customFieldValues[key] || {}
  const content = cfv.value?.content

  if (type === ADDRESS) {
    const value = cfv.value || {}
    return [
      value?.street,
      value?.street2,
      value?.city,
      value?.state,
      value?.postalCode,
      value?.country,
    ]
      .filter(Boolean)
      .join(' \n')
  }

  if (type === DROPDOWN) {
    return (
      (options || []).find(option => option?.value === content)?.label || ''
    )
  }

  if (type === FILE_TYPE) {
    return cfv?.value?.url || ''
  }

  if (type === LINK) {
    const value = cfv.value || {}
    return [value?.text, value?.link].filter(Boolean).join(' \n')
  }

  if (type === MULTI_SELECT) {
    const values = (cfv.value || []).map(v => v.content).filter(Boolean)

    return (options || [])
      .filter(option => values.includes(option?.value))
      .map(option => option?.label)
      .filter(Boolean)
      .join(' \n')
  }

  if (isArray) {
    return (cfv.value || [])
      .map(v => v?.content)
      .filter(Boolean)
      .join(' \n')
  }

  return content || ''
}

const Selection = ({ contact, customField, control, keep }) => {
  const { key, id, icon, name, type, description, isArray } = customField || {}

  const cfvValue = useMemo(() => getCfvValue(contact, customField, key), [
    contact,
    customField,
    key,
  ])

  const fieldComponentType = useMemo(
    () => {
      if ([ADDRESS, MULTI_LINE, LINK].includes(type))
        return COMPONENT_TYPE_TEXTAREA
      if (isArray) return COMPONENT_TYPE_TEXTAREA
      if (type === FILE_TYPE && key?.endsWith('avatar'))
        return COMPONENT_TYPE_RADIO

      return COMPONENT_TYPE_FIELD
    },
    [type, isArray, key]
  )

  if (!contact) return null

  const fieldId = `${contact.id}_${key}`
  const fieldName = `keepFields.${id}`

  return (
    <>
      <div css={fieldStyles.labelBox}>
        <label htmlFor={fieldId}>
          <FormLabel icon={icon} name={name} type={type} />
        </label>
        {description && (
          <Tooltip title={description} strategy="fixed">
            <Question tabIndex={-1} />
          </Tooltip>
        )}
      </div>
      <div className="grui flex items-center">
        {fieldComponentType === COMPONENT_TYPE_FIELD && (
          <Field
            tagContent={
              <RadioWithBoolValue
                name={fieldName}
                id={fieldId}
                value={keep}
                control={control}
              />
            }
            css={[profileFormStyles.label, styles.field]}
            tooltipTitle={description}
            readOnly
            value={cfvValue}
          />
        )}
        {fieldComponentType === COMPONENT_TYPE_RADIO && (
          <RadioWithBoolValue
            name={fieldName}
            id={fieldId}
            value={keep}
            control={control}
            css={styles.fieldWidth}
          >
            {name}
          </RadioWithBoolValue>
        )}
        {fieldComponentType === COMPONENT_TYPE_TEXTAREA && (
          <Textarea
            name={fieldName}
            tagContent={
              <RadioWithBoolValue
                name={fieldName}
                id={fieldId}
                value={keep}
                control={control}
              />
            }
            css={[profileFormStyles.label, styles.field]}
            tooltipTitle={description}
            readOnly
            value={cfvValue}
          />
        )}
      </div>
    </>
  )
}

export default Selection
