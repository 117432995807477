import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  queryStringToQueryId,
  queryIdToQuery,
} from 'ducks/searches/utils/query'
import {
  selectSearchEntitiesDenormalizedByQueryId,
  selectSearchIsLoadedByQueryId,
  selectSearchIsLoadingByQueryId,
} from 'ducks/searches/selectors'
import { doFetchTagsV2 } from 'ducks/tags/actions'
import { debounce } from 'util/functions'
import { stringToColor } from 'util/colors'
import { SEARCH_TAGS_DEFAULT_QUERY } from 'ducks/tags/constants'
import { selectCurrentTagsById } from 'ducks/tags/selectors'

export const buildSearchQuery = search =>
  queryStringToQueryId(
    {
      ...queryIdToQuery(SEARCH_TAGS_DEFAULT_QUERY),
      search,
      orderBy: 'NAME_ASC',
    },
    { encodeUriKeys: ['search'] }
  )

export default function useSearchTags({
  searchDelayMs = 200,
  field = 'ids',
  prependNames = [],
  disabled = false,
  includeSelected = false,
  selectedIds,
}) {
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)
  const [queryId, setQueryId] = useState(SEARCH_TAGS_DEFAULT_QUERY)
  const { search = '' } =
    queryIdToQuery(queryId, { decodeUriKeys: ['search'] }) || {}
  const rawFilteredTags = useSelector(state =>
    selectSearchEntitiesDenormalizedByQueryId(state, queryId)
  )
  const tagsById = useSelector(selectCurrentTagsById)
  const loading = useSelector(state =>
    selectSearchIsLoadingByQueryId(state, queryId)
  )

  const loaded = useSelector(state =>
    selectSearchIsLoadedByQueryId(state, queryId)
  )

  const doFetchTagsV2After = useMemo(
    () =>
      debounce(options => {
        dispatch(doFetchTagsV2(options))
      }, searchDelayMs),
    [dispatch, searchDelayMs]
  )

  useEffect(
    () => {
      async function fetchData() {
        if (!disabled) {
          await doFetchTagsV2After({ queryId, skipLoaded: true })
          if (!isReady) setIsReady(true)
        }
      }
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, queryId, doFetchTagsV2After, disabled]
  )

  const reload = useCallback(
    () => {
      return dispatch(doFetchTagsV2({ queryId, skipLoaded: false }))
    },
    [dispatch, queryId]
  )

  const handleChangeSearch = useCallback(({ target }) => {
    setQueryId(buildSearchQuery(target.value))
  }, [])

  const filteredTags = useMemo(
    () => {
      const prependTags = prependNames.map(name => ({
        id: name,
        name,
        color: stringToColor(name),
      }))

      let tags = rawFilteredTags

      if (field === 'names') {
        tags = rawFilteredTags.map(t => ({ ...t, id: t.name }))
      }

      if (includeSelected && tags.length > 0) {
        const missingTagsIds = selectedIds.filter(
          tid => !tags.some(t => t.id === tid)
        )
        if (missingTagsIds.length === 0) return tags

        tags = [...tags]
        missingTagsIds.forEach(tid => {
          const tag = tagsById[tid]
          if (!tag) return

          tags.push(tag)
        })

        return tags.sort((a, b) => a.name.localeCompare(b.name))
      }

      if (prependTags.length) return [...prependTags, ...tags]

      return tags
    },
    [
      rawFilteredTags,
      field,
      prependNames,
      tagsById,
      includeSelected,
      selectedIds,
    ]
  )

  return {
    reload,
    search,
    handleChangeSearch,
    filteredTags,
    isReady,
    loading: loading || !loaded,
    isEmpty: !filteredTags.length && !loading && loaded,
  }
}
