import React, { useMemo } from 'react'
import CellWithAvatar from '@groovehq/internal-design-system/lib/components/Table/CellWithAvatar/CellWithAvatar'
import { ROLE_TO_NAME } from 'ducks/agents/constants'
import UsersDropdown from './UsersDropdown'

const useColumns = ({ scope, currentUser }) => {
  const columns = useMemo(
    () => {
      const formatName = ({ row: { original } }) => {
        const trimmedName = original.name.trim()
        const data = {
          email: trimmedName ? original.email : '',
          value: trimmedName || original.email,
          avatar: original.avatar_url,
          avatarSize: 'xl',
          userName: original.username,
        }
        return <CellWithAvatar data={data} />
      }

      const createDropdown = ({ row, onEdit, deleteData }) => {
        const onSelect = value => {
          onEdit({ id: row.id, type: value })
        }
        const onDelete = () => {
          deleteData(row.id)
        }
        const { original } = row

        return (
          <UsersDropdown
            currentUserRole={currentUser.role}
            rowItemRole={original.role}
            currentAndRowMatch={original.id === currentUser.id}
            currentScope={scope}
            status2fa={original.status2fa}
            onClickFooterBtn={onDelete}
            onSelect={onSelect}
          />
        )
      }

      const formatRole = ({ value }) => {
        return value ? ROLE_TO_NAME[value.toUpperCase()] : null
      }

      const formatAuth = ({ value }) => {
        if (value === 'locked') return '2FA Locked'
        if (value === 'confirmed') return '2FA Enabled'
        if (value === 'enabled') return '2FA Enabled (unconfirmed)'
        if (value === 'disabled') return '2FA Disabled'
        return 'Unknown'
      }

      return [
        {
          Header: app.t('agents'),
          accessor: 'name',
          width: '75%',
          manualSortBy: true,
          Cell: formatName,
        },
        {
          Header: 'ROLE',
          accessor: 'role',
          Cell: formatRole,
          width: '10%',
          manualSortBy: true,
        },
        {
          Header: 'AUTHENTICATION',
          accessor: 'status2fa',
          Cell: formatAuth,
          width: '10%',
          disableSortBy: true,
        },
        {
          Header: '',
          accessor: 'id',
          Cell: createDropdown,
          disableSortBy: true,
          width: '5%',
        },
      ]
    },
    [scope, currentUser.id, currentUser.role]
  )

  return columns
}

export default useColumns
