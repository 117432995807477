import styled from '@emotion/styled'
import { Header } from 'shared/components/ui/Typography'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const StyledNoConvos = styled(Header)`
  text-align: center;
  margin-left: ${props => props.theme.spacing['10']};
  margin-right: ${props => props.theme.spacing['10']};
`

export default function EmptyStateDesktop() {
  return (
    <Container>
      <StyledNoConvos size="medium" color="dust" as="div">
        No conversations
      </StyledNoConvos>
    </Container>
  )
}
