import { useSelector } from 'react-redux'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  ArrowLeft,
  Close,
  FileText,
} from '@groovehq/internal-design-system/lib/assets/icons'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectContactFullNameOrYouByContactId } from 'ducks/crm/contacts/selectors/contactFields'
import { getType } from 'util/globalId'
import { selectAuthorById } from 'ducks/tickets/selectors/selectAuthorById'
import { emptyObj } from 'util/objects'
import { timeInHuman } from 'util/date'

import { styles } from './styles'

const SelectedTicket = ({ ticketId, onDelete }) => {
  const ticket = useSelector(state =>
    selectCurrentConversationById(state, ticketId, null)
  )
  const fullNameOrYou = useSelector(state =>
    selectContactFullNameOrYouByContactId(state, ticket?.contact)
  )

  const { id: authorId, name: authorName } =
    useSelector(state =>
      selectAuthorById(state, ticket?.summaryMessage?.author?.id)
    ) || emptyObj

  if (!ticket) return null

  const {
    subject,
    summaryMessage: { isNote, bodyPlainText },
    updatedAt,
    counts: { messages: messageCount },
  } = ticket

  const bodyHasAgentAuthor = authorId && getType(authorId) === 'Agent'

  return (
    <div
      className="grui grid justify-between"
      css={[styles.wrapper, onDelete && styles.clickableWrapper]}
    >
      <div className="grui flex flex-col w-100">
        <span className="grui mb-4" css={styles.customer}>
          {fullNameOrYou}
        </span>

        <span className="grui truncate">
          {subject || 'There is no subject for this message'}
        </span>

        <div
          className="grui flex items-center mt-4"
          css={[styles.bottomContent, isNote && styles.bottomNote]}
        >
          {isNote && <FileText className="grui mr-2" />}

          {bodyHasAgentAuthor && (
            <span className="grui flex items-center">
              {authorName}
              {isNote ? ':' : <ArrowLeft size="large" css={styles.arrow} />}
            </span>
          )}

          <SanitizedHTML
            className="grui truncate"
            html={bodyPlainText}
            css={isNote && styles.note}
          />
        </div>
      </div>

      <div
        className="grui flex flex-col justify-between whitespace-nowrap ml-auto text-right"
        css={styles.rightSide}
      >
        <span css={styles.date}>{timeInHuman(updatedAt)}</span>
        <span
          className="grui flex justify-center ml-auto py-3"
          css={styles.messageCount}
        >
          {messageCount}
        </span>
        <span css={styles.ticketNumber}>#{ticketId}</span>
      </div>

      {onDelete && (
        <Button
          type="icon"
          size="small"
          className="grui ml-4"
          css={styles.deleteButton}
          onClick={onDelete}
        >
          <Close />
        </Button>
      )}
    </div>
  )
}

export default SelectedTicket
